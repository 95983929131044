;(function() {
  'use strict'

  Controller.$inject = ["glUtils", "$state", "breadcrumbsService", "subscriberPromotionService", "glToast"];
  angular.module('app.core').controller('Promotions', Controller)

  /* @ngInject */
  function Controller(
    glUtils,
    $state,
    breadcrumbsService,
    subscriberPromotionService,
    glToast
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.isVisible = isVisible
    ctrl.updateFilterParams = updateFilterParams
    ctrl.orderBy = orderBy

    var Statuses = {
      ANY: null,
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE',
    }
    ctrl.statusOptions = [
      { label: 'Any', value: Statuses.ANY },
      { label: 'Active', value: Statuses.ACTIVE },
      { label: 'Inactive', value: Statuses.INACTIVE },
    ]

    function onInit() {
      var query = $state.params.query
      var status = $state.params.status
      ctrl.Statuses = Statuses
      ctrl.filter = {
        query: query || '',
        status: status || Statuses.ANY,
      }
      breadcrumbsService.addBreadcrumb('Promotions', 'home')
      ctrl.loading = true
      subscriberPromotionService
        .list()
        .then(function(promotions) {
          ctrl.promotions = promotions
          ctrl.loading = false
        })
        .catch(function() {
          glToast.show('Could not load promotions.')
          ctrl.loading = false
        })
    }

    function updateFilterParams() {
      glUtils.changeParams({
        query: ctrl.filter.query,
        status: ctrl.filter.status,
      })
    }

    function isVisible(promotion) {
      var query = ctrl.filter.query
      var queryLower = query.toLowerCase()
      var status = ctrl.filter.status

      var queryMatch = query
        ? promotion.id === query ||
          promotion.name.toLowerCase().includes(queryLower) ||
          promotion.description.toLowerCase().includes(queryLower) ||
          promotion.code.toLowerCase().includes(queryLower)
        : true

      var statusMatch = true
      if (status === Statuses.ACTIVE && !promotion.isActive) {
        statusMatch = false
      }
      if (status === Statuses.INACTIVE && promotion.isActive) {
        statusMatch = false
      }

      return queryMatch && statusMatch
    }

    function orderBy(promotion) {
      return promotion.createdAt.unix()
    }
  }
})()
