;(function() {
  'use strict'

  AppController.$inject = ["$scope", "$state", "$rootScope", "user", "userService", "configService"];
  angular.module('app.app').controller('AppController', AppController)

  function AppController(
    $scope,
    $state,
    $rootScope,
    user,
    userService,
    configService
  ) {
    // expose state to $scope
    $scope.$state = $state

    $scope.user = user
    $scope.logo = getLogo()

    // methods
    $scope.logout = function() {
      userService.logout()
    }

    function getLogo() {
      switch (configService.getBuildEnv()) {
        case 'Staging':
          return 'logo-orange-staging.png'
        case 'Development':
          return 'logo-orange-dev.png'
        default:
          return 'logo-orange.png'
      }
    }

    /**
     * Sidenav
     */
    $scope.toggleShowNav = function() {
      $rootScope.$broadcast('sidenav:toggleOpen')
    }

    $scope.toggleMinNav = function() {
      $rootScope.$broadcast('sidenav:toggleMin')
    }

    $scope.closeSidenav = function() {
      $rootScope.$broadcast('sidenav:close')
    }
  }
})()
