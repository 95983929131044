/* eslint-disable no-unused-vars, no-redeclare */
;(function() {
  'use strict'

  d3Service.$inject = ["$window"];
  configCloud.$inject = ["$provide"];
  Directive.$inject = ["d3Service"];
  angular
    .module('app.hellocloud')
    .service('d3Service', d3Service)
    .config(configCloud)
    .directive('hellocloud', Directive)

  /* @ngInject */
  function d3Service($window) {
    return $window.d3
  }

  /* @ngInject */
  function configCloud($provide) {
    var d3WorldCloudDecorator

    /* @ngInject */
    d3WorldCloudDecorator = function($delegate) {
      // Word cloud layout by Jason Davies, http://www.jasondavies.com/word-cloud/
      // Algorithm due to Jonathan Feinberg, http://static.mrfeinberg.com/bv_ch03.pdf
      var d3 = $delegate
      ;(function(exports) {
        function cloud() {
          var size = [256, 256],
            text = cloudText,
            font = cloudFont,
            fontSize = cloudFontSize,
            fontStyle = cloudFontNormal,
            fontWeight = cloudFontNormal,
            rotate = cloudRotate,
            padding = cloudPadding,
            spiral = archimedeanSpiral,
            words = [],
            timeInterval = Infinity,
            event = d3.dispatch('word', 'end'),
            timer = null,
            cloud = {}

          cloud.start = function() {
            var board = zeroArray((size[0] >> 5) * size[1]),
              bounds = null,
              n = words.length,
              i = -1,
              tags = [],
              data = words
                .map(function(d, i) {
                  d.text = text.call(this, d, i)
                  d.font = font.call(this, d, i)
                  d.style = fontStyle.call(this, d, i)
                  d.weight = fontWeight.call(this, d, i)
                  d.rotate = rotate.call(this, d, i)
                  d.size = ~~fontSize.call(this, d, i)
                  d.padding = cloudPadding.call(this, d, i)
                  return d
                })
                .sort(function(a, b) {
                  return b.size - a.size
                })

            if (timer) clearInterval(timer)
            timer = setInterval(step, 0)
            step()

            return cloud

            function step() {
              var start = +new Date(),
                d
              while (+new Date() - start < timeInterval && ++i < n && timer) {
                d = data[i]
                d.x = (size[0] * (Math.random() + 0.5)) >> 1
                d.y = (size[1] * (Math.random() + 0.5)) >> 1
                cloudSprite(d, data, i)
                if (place(board, d, bounds)) {
                  tags.push(d)
                  event.word(d)
                  if (bounds) cloudBounds(bounds, d)
                  else
                    bounds = [
                      { x: d.x + d.x0, y: d.y + d.y0 },
                      { x: d.x + d.x1, y: d.y + d.y1 },
                    ]
                  // Temporary hack
                  d.x -= size[0] >> 1
                  d.y -= size[1] >> 1
                }
              }
              if (i >= n) {
                cloud.stop()
                event.end(tags, bounds)
              }
            }
          }

          cloud.stop = function() {
            if (timer) {
              clearInterval(timer)
              timer = null
            }
            return cloud
          }

          cloud.timeInterval = function(x) {
            if (!arguments.length) return timeInterval
            timeInterval = x == null ? Infinity : x
            return cloud
          }

          function place(board, tag, bounds) {
            var perimeter = [{ x: 0, y: 0 }, { x: size[0], y: size[1] }],
              startX = tag.x,
              startY = tag.y,
              maxDelta = Math.sqrt(size[0] * size[0] + size[1] * size[1]),
              s = spiral(size),
              dt = Math.random() < 0.5 ? 1 : -1,
              t = -dt,
              dxdy,
              dx,
              dy

            while ((dxdy = s((t += dt)))) {
              dx = ~~dxdy[0]
              dy = ~~dxdy[1]

              if (Math.min(dx, dy) > maxDelta) break

              tag.x = startX + dx
              tag.y = startY + dy

              if (
                tag.x + tag.x0 < 0 ||
                tag.y + tag.y0 < 0 ||
                tag.x + tag.x1 > size[0] ||
                tag.y + tag.y1 > size[1]
              )
                continue
              // TODO only check for collisions within current bounds.
              if (!bounds || !cloudCollide(tag, board, size[0])) {
                if (!bounds || collideRects(tag, bounds)) {
                  var sprite = tag.sprite,
                    w = tag.width >> 5,
                    sw = size[0] >> 5,
                    lx = tag.x - (w << 4),
                    sx = lx & 0x7f,
                    msx = 32 - sx,
                    h = tag.y1 - tag.y0,
                    x = (tag.y + tag.y0) * sw + (lx >> 5),
                    last
                  for (var j = 0; j < h; j++) {
                    last = 0
                    for (var i = 0; i <= w; i++) {
                      board[x + i] |=
                        (last << msx) |
                        (i < w ? (last = sprite[j * w + i]) >>> sx : 0)
                    }
                    x += sw
                  }
                  delete tag.sprite
                  return true
                }
              }
            }
            return false
          }

          cloud.words = function(x) {
            if (!arguments.length) return words
            words = x
            return cloud
          }

          cloud.size = function(x) {
            if (!arguments.length) return size
            size = [+x[0], +x[1]]
            return cloud
          }

          cloud.font = function(x) {
            if (!arguments.length) return font
            font = d3.functor(x)
            return cloud
          }

          cloud.fontStyle = function(x) {
            if (!arguments.length) return fontStyle
            fontStyle = d3.functor(x)
            return cloud
          }

          cloud.fontWeight = function(x) {
            if (!arguments.length) return fontWeight
            fontWeight = d3.functor(x)
            return cloud
          }

          cloud.rotate = function(x) {
            if (!arguments.length) return rotate
            rotate = d3.functor(x)
            return cloud
          }

          cloud.text = function(x) {
            if (!arguments.length) return text
            text = d3.functor(x)
            return cloud
          }

          cloud.spiral = function(x) {
            if (!arguments.length) return spiral
            spiral = spirals[x + ''] || x
            return cloud
          }

          cloud.fontSize = function(x) {
            if (!arguments.length) return fontSize
            fontSize = d3.functor(x)
            return cloud
          }

          cloud.padding = function(x) {
            if (!arguments.length) return padding
            padding = d3.functor(x)
            return cloud
          }

          return d3.rebind(cloud, event, 'on')
        }

        function cloudText(d) {
          return d.text
        }

        function cloudFont() {
          return 'serif'
        }

        function cloudFontNormal() {
          return 'normal'
        }

        function cloudFontSize(d) {
          return Math.sqrt(d.value)
        }

        function cloudRotate() {
          return (~~(Math.random() * 6) - 3) * 30
        }

        function cloudPadding() {
          return 1
        }

        // Fetches a monochrome sprite bitmap for the specified text.
        // Load in batches for speed.
        function cloudSprite(d, data, di) {
          if (d.sprite) return
          c.clearRect(0, 0, (cw << 5) / ratio, ch / ratio)
          var x = 0,
            y = 0,
            maxh = 0,
            n = data.length
          di--
          while (++di < n) {
            d = data[di]
            c.save()
            c.font =
              d.style +
              ' ' +
              d.weight +
              ' ' +
              ~~((d.size + 1) / ratio) +
              'px ' +
              d.font
            var w = c.measureText(d.text + 'm').width * ratio,
              h = d.size << 1
            if (d.rotate) {
              var sr = Math.sin(d.rotate * cloudRadians),
                cr = Math.cos(d.rotate * cloudRadians),
                wcr = w * cr,
                wsr = w * sr,
                hcr = h * cr,
                hsr = h * sr
              w =
                ((Math.max(Math.abs(wcr + hsr), Math.abs(wcr - hsr)) + 0x1f) >>
                  5) <<
                5
              h = ~~Math.max(Math.abs(wsr + hcr), Math.abs(wsr - hcr))
            } else {
              w = ((w + 0x1f) >> 5) << 5
            }
            if (h > maxh) maxh = h
            if (x + w >= cw << 5) {
              x = 0
              y += maxh
              maxh = 0
            }
            if (y + h >= ch) break
            c.translate((x + (w >> 1)) / ratio, (y + (h >> 1)) / ratio)
            if (d.rotate) c.rotate(d.rotate * cloudRadians)
            c.fillText(d.text, 0, 0)
            c.restore()
            d.width = w
            d.height = h
            d.xoff = x
            d.yoff = y
            d.x1 = w >> 1
            d.y1 = h >> 1
            d.x0 = -d.x1
            d.y0 = -d.y1
            x += w
          }
          var pixels = c.getImageData(0, 0, (cw << 5) / ratio, ch / ratio).data,
            sprite = []
          while (--di >= 0) {
            d = data[di]
            var w = d.width,
              w32 = w >> 5,
              h = d.y1 - d.y0,
              p = d.padding
            // Zero the buffer
            for (var i = 0; i < h * w32; i++) sprite[i] = 0
            x = d.xoff
            if (x == null) return
            y = d.yoff
            var seen = 0,
              seenRow = -1
            for (var j = 0; j < h; j++) {
              for (var i = 0; i < w; i++) {
                var k = w32 * j + (i >> 5),
                  m = pixels[((y + j) * (cw << 5) + (x + i)) << 2]
                    ? 1 << (31 - (i % 32))
                    : 0
                if (p) {
                  if (j) sprite[k - w32] |= m
                  if (j < w - 1) sprite[k + w32] |= m
                  m |= (m << 1) | (m >> 1)
                }
                sprite[k] |= m
                seen |= m
              }
              if (seen) seenRow = j
              else {
                d.y0++
                h--
                j--
                y++
              }
            }
            d.y1 = d.y0 + seenRow
            d.sprite = sprite.slice(0, (d.y1 - d.y0) * w32)
          }
        }

        // Use mask-based collision detection.
        function cloudCollide(tag, board, sw) {
          sw >>= 5
          var sprite = tag.sprite,
            w = tag.width >> 5,
            lx = tag.x - (w << 4),
            sx = lx & 0x7f,
            msx = 32 - sx,
            h = tag.y1 - tag.y0,
            x = (tag.y + tag.y0) * sw + (lx >> 5),
            last
          for (var j = 0; j < h; j++) {
            last = 0
            for (var i = 0; i <= w; i++) {
              if (
                ((last << msx) |
                  (i < w ? (last = sprite[j * w + i]) >>> sx : 0)) &
                board[x + i]
              )
                return true
            }
            x += sw
          }
          return false
        }

        function cloudBounds(bounds, d) {
          var b0 = bounds[0],
            b1 = bounds[1]
          if (d.x + d.x0 < b0.x) b0.x = d.x + d.x0
          if (d.y + d.y0 < b0.y) b0.y = d.y + d.y0
          if (d.x + d.x1 > b1.x) b1.x = d.x + d.x1
          if (d.y + d.y1 > b1.y) b1.y = d.y + d.y1
        }

        function collideRects(a, b) {
          return (
            a.x + a.x1 > b[0].x &&
            a.x + a.x0 < b[1].x &&
            a.y + a.y1 > b[0].y &&
            a.y + a.y0 < b[1].y
          )
        }

        function archimedeanSpiral(size) {
          var e = size[0] / size[1]
          return function(t) {
            return [e * (t *= 0.1) * Math.cos(t), t * Math.sin(t)]
          }
        }

        function rectangularSpiral(size) {
          var dy = 4,
            dx = (dy * size[0]) / size[1],
            x = 0,
            y = 0
          return function(t) {
            var sign = t < 0 ? -1 : 1
            // See triangular numbers: T_n = n * (n + 1) / 2.
            switch ((Math.sqrt(1 + 4 * sign * t) - sign) & 3) {
              case 0:
                x += dx
                break
              case 1:
                y += dy
                break
              case 2:
                x -= dx
                break
              default:
                y -= dy
                break
            }
            return [x, y]
          }
        }

        // TODO reuse arrays?
        function zeroArray(n) {
          var a = [],
            i = -1
          while (++i < n) a[i] = 0
          return a
        }

        var cloudRadians = Math.PI / 180,
          cw = (1 << 11) >> 5,
          ch = 1 << 11,
          canvas,
          ratio = 1

        if (typeof document !== 'undefined') {
          canvas = document.createElement('canvas')
          canvas.width = 1
          canvas.height = 1
          ratio = Math.sqrt(
            canvas.getContext('2d').getImageData(0, 0, 1, 1).data.length >> 2
          )
          canvas.width = (cw << 5) / ratio
          canvas.height = ch / ratio
        } else {
          // node-canvas support
          var Canvas = require('canvas')
          canvas = new Canvas(cw << 5, ch)
        }

        var c = canvas.getContext('2d'),
          spirals = {
            archimedean: archimedeanSpiral,
            rectangular: rectangularSpiral,
          }
        c.fillStyle = 'red'
        c.textAlign = 'center'

        exports.cloud = cloud
      })(
        typeof exports === 'undefined' ? d3.layout || (d3.layout = {}) : exports
      )

      return d3
    }
    d3WorldCloudDecorator.$inject = ["$delegate"];
    $provide.decorator('d3Service', d3WorldCloudDecorator)
  }

  /* @ngInject */
  function Directive(d3Service) {
    return {
      restrict: 'E',
      scope: {
        // attributes
        width: '@',
        height: '@',
        fontFamily: '@',
        fontSize: '@',

        // Bindings
        words: '=',

        // EventCallbacks
        onClick: '&',
        onHover: '&',
      },
      link: function postLink(scope, element, attrs) {
        // Default Values
        // var width             =   800;
        var width = element[0].parentNode.clientWidth
        var height = 600
        var fontFamily = 'Impact'
        var fontSize = 100
        var words

        // Check and set attributes, else keep then default values
        if (angular.isDefined(attrs.width)) {
          width = attrs.width
        }
        if (angular.isDefined(attrs.height)) {
          height = attrs.height
        }
        if (angular.isDefined(attrs.fontFamily)) {
          fontFamily = attrs.fontFamily
        }
        // !parseInt, detect wrong input
        if (angular.isDefined(attrs.fontSize)) {
          fontSize = attrs.fontSize * 1 || 0
        }

        // Check Scope
        if (angular.isDefined(scope.words)) {
          words = scope.words
        }

        // TODO: Refactor String to a service
        // Skip rendering when no corrent word param is parsed
        if (angular.isDefined(scope.words) && angular.isArray(words)) {
          words = scope.words
        } else if (angular.element(element).find('word').length > 0) {
          var subelements = angular.element(element).find('word')
          words = []
          angular.forEach(subelements, function(word) {
            words.push(angular.element(word).text())
            angular.element(word).remove()
          })
        } else if (element.text().length > 0) {
          words = element.text().split(',')
          element.text('')
        } else {
          element.text('wordcloud: Please define some words')
          return
        }

        // Font-Size Param wrong
        if (!angular.isNumber(fontSize) || fontSize <= 0) {
          element.text(
            'wordcloud: font-size attribute not valid. font-size ' +
              attrs.fontSize +
              ' -> ' +
              fontSize
          )
          return
        }

        // Font sizing
        var maxFrequency = _.maxBy(words, 'total').total
        var minFontSize = 24
        var maxFontSize = 100
        function calcFontSize(freq) {
          return Math.max(minFontSize, (maxFontSize * freq) / maxFrequency)
        }

        // colors
        var colors = ['#636363', '#969696', '#bdbdbd', '#d9d9d9']
        function fill() {
          return colors[Math.floor(Math.random() * 4)]
        }

        // CloudFactory - so high in the sky ;)
        // Keep the anonym functions here for readability
        var cloudFactory = function(words) {
          // TODO: Add fill Function Binding for own function
          // var fill = d3Service.scale.category20();

          d3Service.layout
            .cloud()
            .size([width, height])
            .words(
              words.map(function(d) {
                // return {text: d.word, size: Math.random() * fontSize};
                return { text: d.word, size: calcFontSize(d.total) }
              })
            )
            // .rotate(function() { return Math.floor(Math.random() * 2) * -90; })
            .rotate(function() {
              return 0
            })
            .font(fontFamily)
            .padding(5)
            .fontWeight('bold')
            .fontSize(function(d) {
              return d.size
            })
            .on('end', draw)
            .start()

          function draw(words) {
            // Center the drawing
            var heightTranslate = height / 2
            var widthTranslate = width / 2
            var rootElement = element[0]

            var selected

            d3Service
              .select(rootElement)
              .append('svg')
              .attr('width', width)
              .attr('height', height)
              .append('g')
              .attr(
                'transform',
                'translate(' + widthTranslate + ',' + heightTranslate + ')'
              ) // Translate to center
              .selectAll('text')
              .data(words)
              .enter()
              .append('text')
              .style('font-size', function(d) {
                return d.size + 'px'
              })
              // .style('font-size', function (d) { return d.size + 'em'; })
              // .style('font-family', fontFamily)
              // .style('font-weight', 'bold')
              .style('fill', function(d, i) {
                return fill(i)
              })
              .attr('text-anchor', 'middle')
              .attr('transform', function(d) {
                return 'translate(' + [d.x, d.y] + ') rotate(' + d.rotate + ')'
              })
              .text(function(d) {
                return d.text
              })
              .on('click', function(d) {
                d3Service.selectAll('text').classed('active', false)
                if (selected !== d.text) {
                  scope.onClick({ element: d })
                  d3Service.select(this).classed('active', true)
                  selected = d.text
                } else {
                  scope.onClick({ element: { text: null } })
                  selected = null
                }
              })
              .on('mouseover', function(d) {
                scope.onHover({ element: d })
              })
          }
        }
        // Execute
        cloudFactory(words)
      },
    }
  }
})()
