;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.question-template', []).config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('app.question-template', {
      url: '/question-templates/:id',
      params: {
        id: { dynamic: true },
        source: null, // used to initialise new templates with some data
      },
      templateUrl: 'question-template.template.html',
      controller: 'QuestionTemplate',
      controllerAs: '$ctrl',
    })
  }
})()
