;(function() {
  'use strict'

  ElementsController.$inject = ["$scope", "$stateParams", "breadcrumbsService", "glDialog", "elementService", "glToast", "listFilterService"];
  angular
    .module('app.elements')
    .controller('ElementsController', ElementsController)

  /* @ngInject */
  function ElementsController(
    $scope,
    $stateParams,
    breadcrumbsService,
    glDialog,
    elementService,
    glToast,
    listFilterService
  ) {
    $scope.loading = false

    $scope.data = {
      subscriberId: $stateParams.sid,
      elements: [],
    }

    // filters
    var uriFilters = listFilterService.initFilter($stateParams)
    $scope.filters = _.assign(
      {
        type: 'all',
        top: 50,
        page: 1,
        pageExhausted: false,
        orderby: 'title',
        order: 'asc',
        email: '',
        title: '',
        customerCareEmail: 'all',
        isSubscriberOwned: 'all',
      },
      uriFilters
    )

    $scope.getElements = getElements
    $scope.addElement = addElement

    activate()

    /**
     * initialise
     */
    function activate() {
      getElements()
      var parentState = $stateParams.sid ? 'app.subscriber' : 'home'
      breadcrumbsService.addBreadcrumb('Elements', parentState)
    }

    /**
     * Build search object from filters
     * @param  {Object} srcObj filter object to build upon
     * @return {Object}
     */
    function organiseSearchObj(srcObj) {
      var sendingObj = {}

      // top, page, orderby
      sendingObj.top = srcObj.top
      sendingObj.skip = srcObj.top * (srcObj.page - 1)
      sendingObj.orderby = srcObj.orderby + ' ' + srcObj.order

      if ($stateParams.sid) {
        sendingObj.subscriberId = $stateParams.sid
      }

      if (typeof sendingObj.filter !== 'string') {
        sendingObj.filter = ''
      }

      var email = srcObj.email
      if (email) {
        sendingObj.filter = appendFilterString(
          sendingObj,
          '(customerCareEmail regex /' + email + '/i)'
        )
      }

      var title = srcObj.title
      if (title) {
        sendingObj.filter = appendFilterString(
          sendingObj,
          '(title regex /' + title + '/i)'
        )
      }

      if (srcObj['type'] !== 'all') {
        sendingObj.filter = appendFilterString(
          sendingObj,
          '(about.type eq ' + srcObj['type'] + ')'
        )
      }

      if (srcObj['customerCareEmail'] !== 'all') {
        if (srcObj['customerCareEmail'] === 'true') {
          sendingObj.filter = appendFilterString(
            sendingObj,
            '(customerCareEmail exists true)'
          )
        } else {
          sendingObj.filter = appendFilterString(
            sendingObj,
            "(customerCareEmail exists false or customerCareEmail eq null or customerCareEmail eq '')"
          )
        }
      }

      if (srcObj['isSubscriberOwned'] !== 'all') {
        sendingObj.filter = appendFilterString(
          sendingObj,
          '(owner exists ' + srcObj['isSubscriberOwned'] + ')'
        )
      }

      if (sendingObj.filter.length === 0) {
        delete sendingObj.filter
      }

      return sendingObj

      function appendFilterString(sendingObj, stringToAppend) {
        var stringToReturn = sendingObj.filter
        if (stringToReturn.length !== 0) {
          stringToReturn += ' and '
        }
        return (stringToReturn += stringToAppend)
      }
    }

    /**
     * Get elements from server
     * @param  {Boolean} append if true then append to existing elements data set
     */
    function getElements(append) {
      $scope.loading = true

      if (!append && $scope.data.elements) {
        $scope.data.elements = []
        $scope.filters.page = 1
        $scope.filters.pageExhausted = false
        listFilterService.writeFilter($scope.filters)
      } else if (append) {
        $scope.filters.page++
      }

      var action = $stateParams.sid ? 'getBySubscriber' : 'getAll'

      elementService[action](organiseSearchObj($scope.filters)).then(
        getSuccess,
        getError
      )

      function getSuccess(data) {
        $scope.loading = false
        $scope.data.elements.push.apply($scope.data.elements, data)
        if (data.length < $scope.filters.top) {
          $scope.filters.pageExhausted = true
        }
      }

      function getError() {
        $scope.loading = false
        glToast.action('Error retrieving elements.', 'Retry', getElements, {
          target: '.main-content',
          timeout: 5000,
        })
      }
    }

    /**
     * add new element
     */
    function addElement($event) {
      glDialog.alert('', 'This has not been implemented yet', {
        parent: '.main-content',
        targetEvent: $event,
      })
    }
  }
})()
