;(function() {
  'use strict'

  SurveyConfig.$inject = ["$stateProvider"];
  angular.module('app.survey', []).config(SurveyConfig)

  /* @ngInject */
  function SurveyConfig($stateProvider) {
    $stateProvider.state('app.survey', {
      url: '/subscriber/:subscriberId/element/:elementId/survey/:surveyId',
      params: {
        subscriberId: '',
        elementId: '',
        surveyId: '',
      },
      templateUrl: 'survey.template.html',
      controller: 'SurveyController',
    })
  }
})()
