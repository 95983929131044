;(function() {
  'use strict'

  LoginController.$inject = ["$scope", "glDialog", "userService"];
  angular.module('app.login').controller('LoginController', LoginController)

  /* @ngInject */
  function LoginController($scope, glDialog, userService) {
    $scope.credentials = {}

    $scope.login = login

    /**
     * Try to log in user
     */
    function login() {
      // prevent multiple dialogs if pressing enter
      if ($scope.processing) {
        return
      }

      $scope.processing = true
      // process on next tick to allow the view to update to show processing
      // -- see GLOWSYSLIB-8 issue on Safari where synchronous call must be made
      $scope.$evalAsync(function() {
        userService.login($scope.credentials).then(
          function() {
            userService.redirectToRequestedUrl()
          },
          function(error) {
            $scope.processing = false
            glDialog.alert('Login failed.', error.message || error)
          }
        )
      })
    }
  }
})()
