;(function() {
  'use strict'

  LoginConfig.$inject = ["$stateProvider"];
  angular.module('app.login', []).config(LoginConfig)

  /* @ngInject */
  function LoginConfig($stateProvider) {
    $stateProvider.state('login', {
      url: '/',
      templateUrl: 'login.template.html',
      controller: 'LoginController',
    })
  }
})()
