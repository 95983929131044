;(function() {
  'use strict'

  CouponConfig.$inject = ["$stateProvider"];
  angular.module('app.coupon', []).config(CouponConfig)

  /* @ngInject */
  function CouponConfig($stateProvider) {
    $stateProvider.state('app.coupon', {
      url: '/coupon/:couponId/:subscriberId',
      params: {
        couponId: { dynamic: true },
        subscriberId: { dynamic: true },
      },
      templateUrl: 'coupon.template.html',
      controller: 'CouponController',
    })
  }
})()
