;(function() {
  'use strict'

  SurveyController.$inject = ["$log", "$scope", "$document", "$state", "$stateParams", "$timeout", "$q", "breadcrumbsService", "imagesService", "couponService", "glAuthService", "glToast", "glDialog", "glUtils", "configService", "surveyService", "surveyTemplatesResource", "imexportService", "linkService"];
  angular.module('app.survey').controller('SurveyController', SurveyController)

  /* @ngInject */
  function SurveyController(
    $log,
    $scope,
    $document,
    $state,
    $stateParams,
    $timeout,
    $q,
    breadcrumbsService,
    imagesService,
    couponService,
    glAuthService,
    glToast,
    glDialog,
    glUtils,
    configService,
    surveyService,
    surveyTemplatesResource,
    imexportService,
    linkService
  ) {
    $log = $log.create('SurveyController')

    // list of available survey question types
    $scope.questionTypes = [
      { label: 'Multiple Choice', value: 'multichoice' },
      { label: 'Multi Select', value: 'multiselect' },
      { label: 'Single-line Text', value: 'singletext' },
      { label: 'Multi-line Text', value: 'text' },
      { label: 'Barcode Scan', value: 'scan' },
      { label: 'Net Promoter Score', value: 'nps' },
      { label: 'Mood', value: 'mood' },
      { label: 'Places Near Me', value: 'placesnearme' },
      { label: 'Add an Image', value: 'image' },
    ]

    $scope.presentationTypes = [
      { label: 'Sequential', value: 'sequential' },
      { label: 'Inline', value: 'inline' },
    ]

    $scope.visibilityOptions = [
      { label: 'Standard', value: 'standard' },
      { label: 'Public', value: 'public' },
    ]

    $scope.data = {
      surveyId: $stateParams.surveyId,
      tab: 'details',
    }

    $scope.processing = false
    $scope.loading = false
    $scope.isFormValid = false

    // methods
    $scope.toggleFollowup = toggleFollowup
    $scope.toggleUserReport = toggleUserReport
    $scope.addQuestion = addQuestion
    $scope.duplicateQuestion = duplicateQuestion
    $scope.deleteQuestion = deleteQuestion
    $scope.moveQuestion = moveQuestion
    $scope.removeChoice = removeChoice
    $scope.prevTab = prevTab
    $scope.nextTab = nextTab
    $scope.save = save
    $scope.publish = publish
    $scope.hasShareUrl = hasShareUrl
    $scope.renew = renew
    $scope.pull = pull
    $scope.exportSurveyData = exportSurveyData
    $scope.exportSurveyDefinition = exportSurveyDefinition
    $scope.exportSurveyDefinitionJson = exportSurveyDefinitionJson
    $scope.importSurveyDefinition = importSurveyDefinition
    $scope.onQuestionTypeChange = onQuestionTypeChange
    $scope.shouldShowQR = shouldShowQR
    $scope.showNotes = showNotes
    $scope.toggleQuestionLock = toggleQuestionLock
    $scope.getSubscriberId = getSubscriberId
    $scope.setImage = setImage
    $scope.setListImage = setListImage
    $scope.setQuestionImage = setQuestionImage
    $scope.saveAsTemplate = saveAsTemplate

    var DEFAULT_FOLLOWUP_TITLE =
      'Would you like us to get back to you via Glow?'

    var Defaults = {
      tags: [],
      elementId: $stateParams.elementId,
      presentationType: 'sequential',
      showLandingPage: true,
      visibility: 'standard',
      startAt: moment().toISOString(),
      endAt: moment()
        .add(365, 'days')
        .toISOString(),
      followup: {
        id: null,
        allow: false,
        title: DEFAULT_FOLLOWUP_TITLE,
        isHidden: true,
      },
      data: {
        _blank: true,
      },
    }

    activate()

    /**
     * initialise
     */
    function activate() {
      breadcrumbsService.addBreadcrumb('Survey', 'app.surveys')
      $scope.loading = true

      getCoupons()
        .then(getSurvey)
        .catch(function(reason) {
          var msg = reason || 'Could not load survey'
          glToast.action(msg, 'Retry', activate, {
            target: '.main-content',
            timeout: 5000,
          })
        })
        .finally(function() {
          $scope.loading = false
        })

      $document.bind('keydown', onKeyDown)

      $scope.$on('$destroy', function() {
        $document.unbind('keydown', onKeyDown)
      })

      $scope.$watchGroup(
        ['survey.rewards.coupon', 'survey.endAt'],
        validateCouponDate
      )
      $scope.$watchGroup(['survey.visibility'], visibilityPromoteRelationship)
      $scope.$watch('survey.presentationType', displayTypeRelationship)
    }

    /**
     * get subscribers coupons from server
     */
    function getCoupons() {
      if (!$stateParams.subscriberId) {
        return $q.resolve()
      }

      return couponService
        .getBySubscriber($stateParams.subscriberId)
        .then(function(data) {
          $scope.data.coupons = data
        })
        .catch(function() {
          return $q.reject('Error fetching coupons')
        })
    }

    /**
     * get survey from server by surveyId state parameter
     */
    function getSurvey() {
      if (!$stateParams.surveyId) {
        return $q.reject('No survey ID specified in url')
      }

      if ($stateParams.surveyId === 'new') {
        // start with defaults
        var survey = _.cloneDeep(Defaults)

        // allow clone/template to override (if any)
        _.merge(survey, surveyService.getTemplate())

        $scope.survey = survey
        return $q.resolve()
      }

      // get survey
      return surveyService
        .get($stateParams.surveyId)
        .then(function(survey) {
          breadcrumbsService.updateCurrentTitle(survey.title)
          $scope.survey = fromServer(survey)
          setQR()
        })
        .catch(function() {
          return $q.reject('Error fetching survey')
        })
    }

    /**
     * check coupon is valid for length of survey
     */
    function validateCouponDate(newValues) {
      if (!$scope.data.coupons) {
        return
      }
      var couponId = newValues[0]
      var surveyEndAt = moment(newValues[1])

      if (
        'undefined' !== typeof couponId &&
        null !== couponId &&
        'undefined' !== typeof surveyEndAt
      ) {
        var coupon = _.find($scope.data.coupons, { id: couponId })
        var couponEndAt = moment(coupon.endAt)

        var diff = surveyEndAt.diff(couponEndAt, 'days')
        if (diff > 0) {
          glDialog.alert(
            'Coupon reset',
            'Survey cannot expire after the coupon attached. Try re-select a coupon.',
            {
              parent: '.main-content',
            }
          )
          $scope.survey.rewards.coupon = null
        }
      }
    }

    /**
     * make sure public survey must be promoted
     */
    function visibilityPromoteRelationship(newValues, oldValues) {
      if (typeof $scope.survey === 'undefined') {
        return
      }

      var visibility = newValues[0]
      var notify = $scope.survey.notify

      if (visibility === 'public' && !notify) {
        $scope.survey.promote = true
        $scope.lockDispatchOptions = true
      } else if (visibility === 'public' && notify) {
        var msg =
          'Survey is currenty set as Notify, setting it to public will disable Notify.'
        glDialog
          .confirm('Are you sure?', msg, {
            parent: '.main-content',
          })
          .then(
            function() {
              $scope.survey.promote = true
              $scope.survey.notify = false
              $scope.lockDispatchOptions = true
            },
            function() {
              $scope.survey.visibility = oldValues[0]
              glToast.show("Couldn't set this survey to public.", {
                target: '.main-content',
              })
            }
          )
      } else if (visibility === 'standard') {
        $scope.lockDispatchOptions = false
      }
    }

    /**
     * make sure inline survey does not show landing page
     */
    function displayTypeRelationship(newValue) {
      if (newValue === 'inline') {
        $scope.survey.showLandingPage = false
      }
    }

    function fromServer(survey) {
      // check for follow up question, save reference and remove from questions array
      var followupQuestion = _.remove(survey.questions, { type: 'followup' })[0]

      // add followup properties to survey
      if (followupQuestion) {
        survey.followup = {}
        survey.followup.id = followupQuestion.id
        survey.followup.allow = true
        survey.followup.title = followupQuestion.title
        survey.followup.isHidden = followupQuestion.isHidden
      } else {
        // default followup text
        survey.followup = {}
        survey.followup.allow = false
        survey.followup.isHidden = true
        survey.followup.title = DEFAULT_FOLLOWUP_TITLE
      }

      // cannot modify existing choices so need to keep count to set disabled flag
      // cannot edit question-templates with comparison keys
      _.forEach(survey.questions, function(question) {
        if (!_.isEmpty(question.choices[0])) {
          question.numInitChoices = question.choices.length
        }
        var isProfiling = question.profiling && question.profiling.key
        var isComparable = !!question.comparisonKey
        var isTemplated = !!question.questionTemplateId
        if ((isTemplated && isComparable) || isProfiling) {
          question.isAdminLocked = true
        }

        question.choices = _.sortBy(question.choices, 'order')
      })

      // order questions (backend doesnt use array order)
      survey.questions = _.sortBy(survey.questions, 'order')

      // HACK: backend turns empty objects into arrays
      // if the backend returns null/array, remove it so that defaults can apply
      if (survey.data === null || _.isArray(survey.data)) {
        delete survey.data
      }

      _.defaultsDeep(survey, Defaults)

      return survey
    }

    function toServer(survey) {
      survey = _.cloneDeep(survey)

      var data = {
        id: survey.id,
        data: survey.data,
        description: survey.description,
        tags: survey.tags,
        links: survey.links,
        elementId: survey.elementId,
        title: survey.title,
        reference: survey.reference,
        comparisonKey: survey.comparisonKey,
        startAt: survey.startAt,
        endAt: survey.endAt,
        couponId: survey.rewards[0],
        promote: survey.promote,
        notify: survey.notify,
        questions: survey.questions,
        presentationType: survey.presentationType,
        showLandingPage: survey.showLandingPage,
        visibility: survey.visibility,
        submitRoute: survey.submitRoute,
        imageUrl: survey.imageUrl,
        listImageUrl: survey.listImageUrl,
        responseLimit: survey.responseLimit || null,
        pullSurveyResponseLimit: survey.pullSurveyResponseLimit || null,
      }

      _.each(data.questions, function(question) {
        question.choices = question.choices.filter(function(choice) {
          return !_.isEmpty(choice.option)
        })
        _.each(question.choices, function(choice, index) {
          choice.order = index + 1
        })
      })

      // add follow up question if set
      if (survey.followup.allow) {
        data.questions.push({
          id: survey.followup.id,
          title: survey.followup.title,
          choices: [{ option: "Yes, that's fine" }],
          isCompulsory: false,
          isHidden: survey.followup.isHidden,
          nonCompulsoryText: 'Skip',
          promptMessage: null,
          order: survey.questions.length,
          imageUrl: '',
          type: 'followup',
        })
      }

      return data
    }

    function setQR() {
      $scope.survey.qrString = surveyService.getQRString($scope.survey.id)
    }

    /**
     * @name toggleFollowup
     * @description toggle followup question - allow/disallow or hide/show if already attached to survey
     */
    function toggleFollowup() {
      var followup = $scope.survey.followup

      if (!followup.id) {
        // not set yet
        followup.allow = !followup.allow
        followup.isHidden = !followup.isHidden
      } else {
        // has been set so need to toggle show/hide
        followup.isHidden = !followup.isHidden
      }
    }

    /**
     * @name toggleUserReport
     * @description set/unset the visibleToGlowUsers keys on survey custom data to enable report for Glow users
     * @return {[type]} [description]
     */
    function toggleUserReport() {
      var data = $scope.survey.data
      data.visibleToGlowUsers = !data.visibleToGlowUsers
    }

    /**
     * @name addQuestion
     * @description add a new question to the survey after the current question
     */
    function addQuestion(question) {
      if (!question) {
        question = {
          choices: [{ option: '' }],
          isCompulsory: true,
          nonCompulsoryText: 'Skip',
          promptMessage: null,
          imageUrl: '',
        }
      }

      // add question
      $scope.survey.questions.splice($scope.data.tab, 0, question)

      // re-order questions
      orderQuestions()

      // update view
      $scope.data.tab = $scope.data.tab === 'details' ? 1 : $scope.data.tab + 1
      $scope.surveyForm.$setPristine(false)
    }

    /**
     * @name duplicateQuestion
     * @description duplicate current question and add it to the question array after current question
     */
    function duplicateQuestion() {
      // duplicate question
      var currentIndex = $scope.data.tab - 1
      var question = angular.copy($scope.survey.questions[currentIndex])
      if (question.id) {
        delete question.id
      }
      // Add directly after the question being duplicated
      $scope.survey.questions.splice(currentIndex + 1, 0, question)

      // re-order questions
      orderQuestions()

      // update view
      $scope.data.tab++
      $scope.surveyForm.$setPristine(false)
    }

    /**
     * @name deleteQuestion
     * @description remove current question from survey
     * @param  {Object} $event click event
     */
    function deleteQuestion($event) {
      glDialog
        .confirm(
          'Delete Question',
          'Are you sure you want to delete this question?',
          {
            target: '.main-content',
            targetEvent: $event,
          }
        )
        .then(
          function() {
            // delete question
            $scope.survey.questions.splice($scope.data.tab - 1, 1)

            // re-order questions
            orderQuestions()

            // update view
            $scope.data.tab =
              $scope.data.tab > 1 ? $scope.data.tab - 1 : 'details'
            $scope.surveyForm.$setPristine(false)
          },
          function() {
            glToast.show("Couldn't delete this survey question.", {
              target: '.main-content',
            })
          }
        )
    }

    /**
     * @name moveQuestion
     * @description move question in array relative to current position
     * @param  {Object} question     question to move
     * @param  {Number} placesToMove number of array positions to move - +'ve forwards | -'ve backwards
     */
    function moveQuestion(question, placesToMove) {
      var questions = $scope.survey.questions
      var newIndex = parseInt(question.order, 10) + placesToMove

      if (newIndex >= 0 && newIndex <= questions.length - 1) {
        // move question
        questions.splice(newIndex, 0, questions.splice(question.order, 1)[0])

        // re-order questions
        orderQuestions()

        // update view
        $timeout(function() {
          $scope.data.tab += placesToMove
          $scope.surveyForm.$setPristine(false)
        })
      }
    }

    /**
     * @name orderQuestions
     * @description set questions order property based on array order
     */
    function orderQuestions() {
      var questions = $scope.survey.questions
      for (var i = 0, len = questions.length; i < len; i++) {
        questions[i].order = i
      }
    }

    /**
     * remove choice from question
     * @param  {Object} question question object
     * @param  {Number} index    index of choice
     */
    function removeChoice(question, index) {
      if (question.choices.length <= 2) {
        return glToast.show(
          'You must have at least two options for this question.',
          {
            target: '.main-content',
          }
        )
      }
      $scope.surveyForm.$setPristine(false)
      question.choices.splice(index, 1)
    }

    /**
     * go to previous tab
     */
    function prevTab() {
      if (!isNaN($scope.data.tab)) {
        if ($scope.data.tab === 1) {
          $scope.data.tab = 'details'
        } else {
          $scope.data.tab = $scope.data.tab - 1
        }
      }
    }

    /**
     * go to next tab
     */
    function nextTab() {
      if ($scope.data.tab === 'details') {
        $scope.data.tab = 0
      }
      if ($scope.data.tab < $scope.survey.questions.length) {
        $scope.data.tab++
      }
    }

    /**
     * on left or right arrow press, change tab
     * @param  {Object} e event object
     */
    function onKeyDown(e) {
      if (
        document.activeElement.nodeName === 'INPUT' ||
        document.activeElement.nodeName === 'TEXTAREA'
      ) {
        return
      }
      $scope.$apply(function() {
        if (e.keyCode === 37) {
          $scope.prevTab()
        } else if (e.keyCode === 39) {
          $scope.nextTab()
        }
      })
    }

    /**
     * save survey
     * @param  {Boolean} noRefresh - do not refresh the survey, i.e. during prepublish phase (optional)
     * @return {Promise}
     */
    function save(noRefresh) {
      validateSurvey()

      if (displayError()) {
        return $q.reject()
      }

      $scope.processing = true

      var action = $scope.survey.id ? 'update' : 'create'
      var data = toServer($scope.survey)

      return surveyService[action](data)
        .then(function(resp) {
          $scope.surveyForm.$setPristine(true)

          if (resp.id) {
            $scope.survey.id = resp.id
          }

          if (!noRefresh) {
            return refreshSurvey()
              .then(function() {
                glToast.show('Survey saved.', { target: '.main-content' })
              })
              .catch(function() {
                glToast.show(
                  'Error refreshing survey, please refresh the page.',
                  { target: '.main-content' }
                )
              })
          }
        })
        .catch(function() {
          glToast.show("Couldn't " + action + ' survey.', {
            target: '.main-content',
          })
        })
        .finally(function() {
          $scope.processing = false
        })
    }

    function displayError() {
      if ($scope.errorMessage) {
        glToast.show($scope.errorMessage, { target: '.main-content' })
        return true
      }
      return false
    }

    function setImage(imageData) {
      if (!imageData) {
        $scope.survey.imageUrl = null
        $scope.surveyForm.$setDirty()
        return
      }

      uploadImage(imageData).then(
        function(response) {
          $scope.survey.imageUrl = response.imageUrl
          $scope.surveyForm.$setDirty()
        },
        function() {
          displayImageError()
        }
      )
    }

    function setListImage(imageData) {
      if (!imageData) {
        $scope.survey.listImageUrl = null
        $scope.surveyForm.$setDirty()
        return
      }

      uploadImage(imageData).then(
        function(response) {
          $scope.survey.listImageUrl = response.imageUrl
          $scope.surveyForm.$setDirty()
        },
        function() {
          displayImageError()
        }
      )
    }

    function setQuestionImage(question, imageData) {
      if (!imageData) {
        question.imageUrl = null
        $scope.surveyForm.$setDirty()
        return
      }

      uploadImage(imageData).then(
        function(response) {
          question.imageUrl = response.imageUrl
          $scope.surveyForm.$setDirty()
        },
        function() {
          displayImageError()
        }
      )
    }

    function displayImageError() {
      glToast.show('Failed to save image', { target: '.main-content' })
    }

    function uploadImage(imageData) {
      $scope.processing = true

      var data = {
        imageData: imageData,
        request: 'survey.save',
        surveyId: $stateParams.surveyId,
        source: 'admin.glowfeed.com',
      }

      return imagesService.create(data).finally(function() {
        $scope.processing = false
      })
    }

    function saveAsTemplate() {
      validateSurvey(true)
      var isError = displayError()
      if (isError) {
        return
      }

      var survey = toServer($scope.survey)

      // remove id
      delete survey.id

      // remove question ids
      _.each(survey.questions, function(question) {
        delete question.id
      })

      // remove element association
      delete survey.elementId

      $log.debug('saveAsTemplate:data', survey)

      $scope.isTemplateSaving = true

      // DEBUG: fake save
      // $timeout(function () {
      //     glDialog.alert(null, 'Template saved with ID: "this is a mock!"');
      //     $scope.isTemplateSaving = false;
      // }, 500);

      surveyTemplatesResource
        .create(survey)
        .success(function(x, resp) {
          $log.debug('saveAsTemplate:id', resp.id)
          glDialog.alert(null, 'Template saved with ID: ' + resp.id)
          $scope.isTemplateSaving = false
        })
        .error(function() {
          $log.error('saveAsTemplate:error')
        })
    }

    /**
     * refresh survey
     * @param  {Function} callback callback
     */
    function refreshSurvey() {
      if (!$scope.survey.id) {
        return $q.reject()
      }

      return surveyService.get($scope.survey.id).then(function(survey) {
        breadcrumbsService.updateCurrentTitle(survey.title)
        $scope.survey = fromServer(survey)
        setQR()

        $state.go(
          'app.survey',
          {
            elementId: $stateParams.elementId,
            subscriberId: $stateParams.subscriberId,
            surveyId: $scope.survey.id,
          },
          {
            location: 'replace',
            notify: false,
          }
        )
      })
    }

    function validateSurvey(asTemplate) {
      var survey = $scope.survey
      var numCompulsory = 0

      if (!survey) {
        return
      }
      if (!survey.title) {
        return finished('Title is required.')
      }
      if (!glUtils.checkDates(survey.startAt, survey.endAt)) {
        return finished('Please check your start/end dates')
      }
      if (survey.questions.length < 1) {
        return finished('Survey must have at least one question')
      }
      if (
        typeof survey.responseCount === 'number' &&
        parseInt(survey.responseCount) > 0 &&
        typeof survey.responseLimit === 'number' &&
        survey.responseLimit < survey.responseCount
      ) {
        return finished(
          'Response limit cannot be less than current response count (' +
            survey.responseCount +
            ')'
        )
      }

      var isProfilingQuestions
      _.each(survey.questions, function(question, i) {
        var name = 'Question ' + (i + 1) + ' '

        if (question.profiling && question.profiling.key) {
          isProfilingQuestions = true
        }

        if (!question.title) {
          return finished(name + 'has no title')
        }
        if (question.isCompulsory) {
          numCompulsory++
        }
        if (!question.type) {
          return finished(name + 'has no type')
        }
        if (
          question.type === 'multichoice' ||
          question.type === 'multiselect'
        ) {
          if (question.choices.length < 2) {
            return finished(name + ', at least 2 choices required.')
          }
          _.each(question.choices, function(choice) {
            if (choice.length === 0) {
              return finished(name + ', choices must not be blank')
            }
          })
        }
        // set subscriberIdFilter property to null to avoid API error when key does not exist after being set
        if (question.type === 'placesnearme' && !question.subscriberIdFilter) {
          question.subscriberIdFilter = null
        }
      })

      if (numCompulsory === 0) {
        return finished('Must have at least one compulsory question')
      }

      if (isProfilingQuestions && !survey.showLandingPage) {
        return finished(
          'Landing page must be visible if you have profiling questions'
        )
      }

      // template validation ---

      if (asTemplate && !survey.description) {
        return finished('Description is required')
      }

      // --- end template validation

      finished()

      function finished(error) {
        $scope.isFormValid = !error
        $scope.errorMessage = error
      }
    }

    /**
     * publish survey
     */
    function publish() {
      var msg =
        'Publishing a survey will make it public. ' +
        'Once published, it cannot be edited but can be pulled. ' +
        'Do you want to publish this survey?'

      glDialog
        .confirm('', msg, {
          parent: '.main-content',
        })
        .then(publishSurvey, publishSurveyCancelled)

      function publishSurvey() {
        $scope.processing = true

        surveyService.publish($scope.survey.id).then(
          function() {
            refreshSurvey()
              .then(function() {
                glToast.show('Survey published.', { target: '.main-content' })
              })
              .catch(function() {
                glToast.show('Error re-fetching survey. Please refresh.', {
                  target: '.main-content',
                })
              })
              .finally(function() {
                $scope.processing = false
              })
          },
          function() {
            glToast.action('Error publishing survey.', 'Retry', publish, {
              target: '.main-content',
            })
          }
        )
      }

      function publishSurveyCancelled() {
        glToast.show('Publish survey cancelled.', {
          target: '.main-content',
        })
      }
    }

    /**
     * check is survey has a share url link
     * @return {Boolean} true if link exists
     */
    function hasShareUrl() {
      return (
        $scope.survey && !!_.find($scope.survey.links, { context: 'shareUrl' })
      )
    }

    /**
     * get survey survey share url link
     * (Note: if there are multiple links with the shareUrl context the first is returned)
     * @return {Object} link object or undefined if not found
     */
    function getShareUrl() {
      return _.find($scope.survey.links, { context: 'shareUrl' })
    }

    /**
     * generate survey URL with a random token
     * @return {String} survey URL
     */
    function generateTokenUrl() {
      var token = glUtils.randomString(8)
      var baseUrl = configService.getUserPortalUrl()
      return glUtils.addParams(baseUrl + '/survey/' + $scope.survey.id, {
        token: token,
      })
    }

    /**
     * renew survey - generate new share URL
     * @return {Promise}
     */
    function renew() {
      var shareUrl = getShareUrl().value
      var tokenUrl = generateTokenUrl()

      return linkService
        .update(shareUrl, tokenUrl)
        .then(function() {
          glToast.show('Survey share token updated.', {
            target: '.main-content',
          })
        })
        .catch(function() {
          $log.error('could not renew share URL')
          glToast.action('Error renewing survey.', 'Retry', renew, {
            target: '.main-content',
          })
          return $q.reject()
        })
    }

    /**
     * pull (un-publish) survey
     */
    function pull() {
      var msg = 'Are you sure you want to un-publish the survey?'

      glDialog
        .confirm('', msg, {
          parent: '.main-content',
        })
        .then(pullSurvey, pullSurveyCancelled)

      function pullSurvey() {
        $scope.processing = true
        surveyService.pull($scope.survey.id).then(
          function() {
            $scope.processing = false
            $state.go('app.surveys', {
              eid: $stateParams.elementId,
              sid: $stateParams.subscriberId,
            })
          },
          function() {
            $scope.processing = false
            glToast.action('Error un-publishing survey', 'Retry', pull, {
              target: '.main-content',
            })
          }
        )
      }

      function pullSurveyCancelled() {
        glToast.show('Pull survey cancelled.', {
          target: '.main-content',
        })
      }
    }

    /**
     * export survey results
     */
    function exportSurveyData() {
      var token = glAuthService.getToken()
      var baseUrl = configService.getApiUrl()

      var url = glUtils.addParams(baseUrl + '/survey-responses', {
        type: 'csv',
        surveyId: $scope.survey.id,
        api_token: token,
        subscriberId: $scope.survey.ownerId,
      })

      glToast.show('Starting download...')
      window.location.href = url
    }

    /**
     * export survey definition for later import
     */
    function exportSurveyDefinition() {
      if ($scope.errorMessage) {
        glToast.show('Cannot export survey: ' + $scope.errorMessage, {
          target: '.main-content',
        })
        return
      }

      var survey = surveyService.getDefinition(_.cloneDeep($scope.survey))

      imexportService.add('survey', survey.title, survey)
      glToast.show('Survey exported!', {
        target: '.main-content',
      })
    }

    /**
     * export survey definition for later import
     */
    function exportSurveyDefinitionJson() {
      if ($scope.errorMessage) {
        glToast.show('Cannot export survey: ' + $scope.errorMessage, {
          target: '.main-content',
        })
        return
      }

      var survey = surveyService.getDefinition(_.cloneDeep($scope.survey))

      glDialog.show({
        templateUrl: 'survey-export-json.template.html',
        locals: {
          json: JSON.stringify(survey, null, 4),
        },
        clickOutsideToClose: true,
        escapeToClose: true,
      })
    }

    /**
     * import survey definition from a previous export
     */
    function importSurveyDefinition() {
      var parentScope = $scope
      var dialogOptions = {
        target: '.main-content',
        templateUrl: 'import-dialog.template.html',
        /* @ngInject */
        controller: ["$scope", function($scope) {
          function mergeAndClose(dataToMerge) {
            _.mergeWith(parentScope.survey, dataToMerge, function(obj, src) {
              if (_.isArray(obj)) {
                return obj.concat(src)
              }
            })
            $scope.dialog.close()
          }

          $scope.select = function(item) {
            mergeAndClose(item.data)
          }
          $scope.importJsonDefinition = function() {
            try {
              var s = JSON.parse($scope.surveyJson)
              mergeAndClose(s)
            } catch (e) {
              glToast.show('Cannot parse JSON, please check your input!', {
                target: '.main-content',
              })
            }
          }

          $scope.importJson = false
        }],
        locals: {
          title: 'Import Survey',
          subtitle:
            'Select a survey to import (this will merge existing survey)',
          none: 'No exported surveys',
          items: imexportService.get('survey'),
        },
      }
      glDialog.show(dialogOptions)
    }

    function onQuestionTypeChange(question) {
      // Delete anything related to placesnearme
      if (question.type !== 'placesnearme') {
        delete question.isMultiSelect
        delete question.subscriberIdFilter
        delete question.showSearchField
        delete question.placesSearchTerms
      }

      if (question.type === 'placesnearme' || question.type === 'image') {
        question.hideCompulsory = true
        question.isCompulsory = false
      } else {
        question.hideCompulsory = false
        question.isCompulsory = question.isCompulsory || true
      }
    }

    function shouldShowQR() {
      if (!$scope.survey) {
        return
      }
      var haveCode = !!$scope.survey.qrString
      var isAcceptedState = _.includes(['LIVE', 'DRAFT'], $scope.survey.state)
      return haveCode && isAcceptedState
    }

    /**
     * @name getSubscriberId
     * @description returns the subscriber ID for this survey
     */
    function getSubscriberId() {
      return $stateParams.subscriberId || $scope.survey.ownerId
    }

    /**
     * @name showNotes
     * @description show survey notes dialog
     */
    function showNotes() {
      glDialog.show({
        target: '.main-content',
        templateUrl: 'survey-notes-dialog.template.html',
        controller: 'SurveyNotesDialogController',
        locals: {
          surveyId: $scope.survey.id,
        },
      })
    }

    /**
     * @name toggleQuestionLock
     * @description locks or unlocks the question from being editable by the subscriber
     * @param {Object} question the question object
     */
    function toggleQuestionLock(question) {
      $scope.processing = true

      var action = question.isLocked ? 'unlockQuestion' : 'lockQuestion'
      surveyService[action](question.id)
        .then(function() {
          question.isLocked = !question.isLocked
        })
        .catch(function() {
          action = question.isLocked ? 'unlock' : 'lock'
          glToast.show('Could not ' + action + ' question', {
            target: '.main-content',
          })
        })
        .finally(function() {
          $scope.processing = false
        })
    }
  }
})()
