;(function() {
  'use strict'

  angular
    .module('core.noPropagate')
    .directive('noPropagate', noPropagateDirective)

  function noPropagateDirective() {
    return {
      restrict: 'A',
      link: function(scope, elem) {
        elem.bind('click tap', function(e) {
          e.stopPropagation()
        })
      },
    }
  }
})()
