;(function() {
  'use strict'

  claimService.$inject = ["$q", "subscribersResource"];
  angular.module('core.services').factory('claimService', claimService)

  /* @ngInject */
  function claimService($q, subscribersResource) {
    return {
      claim: claim,
      claimElements: claimElements,
    }

    function claim(subscriberDetails, users, elements) {
      var deferred = $q.defer()

      if (!_.isArray(elements)) {
        elements = [{ elementId: elements }]
      }

      if (!_.isArray(users)) {
        users = [
          {
            email: users.email,
            fullname: users.name,
            authorityToAct: true,
            termsAgreed: true,
            phoneNumber: subscriberDetails.phoneNumber,
          },
        ]
      }

      _.assign(subscriberDetails, {
        plan: 'basic',
        elements: elements,
        userCreatedElements: [],
        users: users,
      })

      subscribersResource
        .create(subscriberDetails)
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    // Return the required object structure for each element to be claimed
    function cleanUpElements(elements) {
      var cleanElements = []

      if (!_.isArray(elements)) {
        // Single element id comes from a glow user's claim request, which admin user accepts
        cleanElements = [{ elementId: elements }]
      } else {
        // Array of elements comes from admin user claiming elements for a subscriber
        _.forEach(elements, function(value) {
          cleanElements.push({ elementId: value.id })
        })
      }

      return cleanElements
    }

    function claimElements(subscriberId, elements) {
      var deferred = $q.defer()

      var data = {
        id: subscriberId,
        elements: cleanUpElements(elements),
        userCreatedElements: [],
      }

      subscribersResource
        .claimElements(data)
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }
  }
})()
