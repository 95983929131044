;(function() {
  'use strict'

  Service.$inject = ["glDialog", "glUtils", "glAuthService", "configService"];
  angular.module('app.core').service('surveyReportExporter', Service)

  /* @ngInject */
  function Service(glDialog, glUtils, glAuthService, configService) {
    this.show = show
    this.getDownloadUrl = getDownloadUrl

    function show() {
      // prettier-ignore
      var template = [
        '<gl-dialog class="survey-report-exporter__dialog">',
          '<survey-report-exporter on-cancel="dialog.cancel()"/>',
        '</gl-dialog>',
      ]
      glDialog.show({
        template: template.join(' '),
        closeOnOutsideClick: true,
      })
    }

    function getDownloadUrl(start, end) {
      var token = glAuthService.getToken()
      var endpoint = configService.getApiUrl('/surveys/activity-report')
      var url = glUtils.addParams(endpoint, {
        startAt: start.toISOString(),
        endAt: end.toISOString(),
        api_token: token,
      })
      return url
    }
  }
})()
