;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$state", "$log", "glUtils", "glToast", "plansService", "stateService", "breadcrumbsService"];
  angular.module('app.core').controller('Plan', Controller)

  /* @ngInject */
  function Controller(
    $scope,
    $state,
    $log,
    glUtils,
    glToast,
    plansService,
    stateService,
    breadcrumbsService
  ) {
    var ctrl = this
    var log = $log.create('Plan')

    ctrl.$onInit = onInit
    ctrl.save = save
    ctrl.intervalGetterSetter = intervalGetterSetter
    ctrl.getMRPResponseCost = getMRPResponseCost

    function onInit() {
      $scope.$on('$destroy', onDestroy)

      ctrl.id = $state.params.id
      ctrl.state = stateService.define(['loading', 'error', 'ready'])
      breadcrumbsService.addBreadcrumb('Plan', 'app.plan')
      plansService.loadMRP().then(function(mrp) {
        ctrl.mrp = mrp

        if (ctrl.id === 'new') {
          ctrl.plan = plansService.spawn()
          ctrl.plan.applyMRP(ctrl.mrp)
          ctrl.state.ready()
        } else {
          ctrl.state.loading()
          plansService
            .get(ctrl.id)
            .then(function(plan) {
              ctrl.plan = plan
              breadcrumbsService.updateCurrentTitle(ctrl.plan.name)
              ctrl.state.ready()
            })
            .catch(function(error) {
              ctrl.state.error()
              glToast.show('Error loading plan.')
              log.error(error)
            })
        }
      })
    }

    function intervalGetterSetter(type) {
      if (arguments.length) {
        ctrl.plan.setInterval(type)
      } else {
        return ctrl.plan.interval.type
      }
    }

    function getMRPResponseCost(type, currency) {
      var response = _.find(ctrl.mrp.responses, { type: type })
      return response.price.byCurrency(currency).format()
    }

    function onDestroy() {
      ctrl.plan.revert() // revert any un-saved changes
    }

    function save() {
      var wasNew = ctrl.plan.isNew()
      ctrl.isSaving = true
      return plansService
        .save(ctrl.plan)
        .then(function() {
          if (wasNew) {
            glUtils.changeParams({ id: ctrl.plan.id })
          }
          glToast.show('Plan saved.')
        })
        .catch(function(error) {
          glToast.show('Error saving plan.')
          log.error(error)
        })
        .finally(function() {
          ctrl.isSaving = false
        })
    }
  }
})()
