;(function() {
  'use strict'

  SurveysConfig.$inject = ["$stateProvider"];
  angular.module('app.surveys', []).config(SurveysConfig)

  /* @ngInject */
  function SurveysConfig($stateProvider) {
    $stateProvider.state('app.surveys', {
      url: '/surveys?eid&sid&filters',
      templateUrl: 'surveys.template.html',
      controller: 'SurveysController',
      params: {
        eid: {
          value: null,
          squash: true,
        },
      },
    })
  }
})()
