;(function() {
  'use strict'

  glContentDirective.$inject = ["$animate"];
  angular.module('core.sidenav').directive('glContent', glContentDirective)

  /* @ngInject */
  function glContentDirective($animate) {
    return {
      restrict: 'EA',
      link: link,
    }

    function link(scope, element) {
      // default to navigation large -> content not expanded
      scope.isExpanded = false

      scope.$on('sidenav:toggleMin', function() {
        scope.isExpanded = !scope.isExpanded
      })

      // watch for isExpanded change and update content
      scope.$watch('isExpanded', updateContent)

      /**
       * update the content width
       */
      function updateContent() {
        $animate[scope.isExpanded ? 'addClass' : 'removeClass'](
          element,
          'gl-expanded'
        )
      }
    }
  }
})()
