;(function() {
  'use strict'

  SurveyNotesDialogController.$inject = ["$scope", "$log", "surveyService"];
  angular
    .module('app.survey')
    .controller('SurveyNotesDialogController', SurveyNotesDialogController)

  /* @ngInject */
  function SurveyNotesDialogController($scope, $log, surveyService) {
    $log = $log.create('SurveyNotesDialogController')

    // methods
    $scope.save = save

    $scope.note = {
      text: '',
    }

    activate()

    /**
     * Initialse
     */
    function activate() {
      getNotes()
    }

    /**
     * @name getNotes
     * @description get survey notes
     * @return {[type]} [description]
     */
    function getNotes() {
      $scope.loading = true
      surveyService
        .getNotes($scope.surveyId)
        .then(getSuccess)
        .catch(getError)

      function getSuccess(data) {
        $scope.note.text = (data[0] && data[0].text) || ''
        $scope.loading = false
      }

      function getError() {
        $scope.loading = false
        $log.error('error fetching notes for survey: ', $scope.surveyId)
      }
    }

    /**
     * @name save
     * @description save survey notes and close dialog
     */
    function save() {
      $scope.processing = true
      surveyService
        .saveNotes($scope.surveyId, $scope.note.text)
        .then(saveSuccess)
        .catch(saveError)

      function saveSuccess() {
        $scope.processing = false
        $scope.dialog.close()
      }

      function saveError() {
        $scope.processing = false
        $log.error('error saving notes for survey: ', $scope.surveyId)
      }
    }
  }
})()
