;(function() {
  'use strict'

  postConfig.$inject = ["$stateProvider"];
  angular.module('app.post', []).config(postConfig)

  /* @ngInject */
  function postConfig($stateProvider) {
    $stateProvider.state('app.post', {
      url: '/post/:id',
      templateUrl: 'post.template.html',
      controller: 'PostController',
    })
  }
})()
