;(function() {
  'use strict'

  statsService.$inject = ["$q", "adminStatsResource"];
  angular.module('core.services').factory('statsService', statsService)

  /* @ngInject */
  function statsService($q, adminStatsResource) {
    return {
      getDashboardStats: getDashboardStats,
    }

    /**
     * @name getDashboardStats
     * @description get admin stats for dashboard
     * @return {Promise} promise to return a stats object
     */
    function getDashboardStats() {
      var deferred = $q.defer()

      adminStatsResource
        .get()
        .success(function(resources) {
          deferred.resolve(resources)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }
  }
})()
