/**
 * Created by xinyun on 31/01/15.
 */
;(function() {
  'use strict'

  angular
    .module('GlowAdmin', [
      'templates',
      'ui.router',
      'ngAnimate',
      'ngCookies',
      'ngSanitize',
      'ngQuickDate',

      'glow.lib',
      'glow.components',

      'app.core',

      'app.app',
      'app.claim',
      'app.coupon',
      'app.coupons',
      'app.dashboard',
      'app.element',
      'app.elements',
      'app.hellocloud',
      'app.interactions',
      'app.links',
      'app.login',
      'app.contentGroup',
      'app.contentGroups',
      'app.post',
      'app.posts',
      'app.question-templates',
      'app.question-template',
      'app.subscriber',
      'app.subscribers',
      'app.survey',
      'app.surveys',
      'app.user',
      'app.users',

      'monospaced.elastic',
    ])

    .config(["$urlServiceProvider", "$uiViewScrollProvider", "$locationProvider", function(
      $urlServiceProvider,
      $uiViewScrollProvider,
      $locationProvider
    ) {
      $uiViewScrollProvider.useAnchorScroll()
      $urlServiceProvider.rules.otherwise('/')
      $locationProvider.html5Mode(true)
    }])

    .run(["$transitions", "$state", "userService", function($transitions, $state, userService) {
      $transitions.onStart(null, function(trans) {
        var nextState = trans.to()

        // if authenticated route but no user, route to login
        if (
          nextState.data &&
          nextState.data.authenticate &&
          !userService.hasCredentials()
        ) {
          trans.abort()

          // save requested url to route to after login
          userService.saveRequestedUrl()
          return $state.go('login')
        }

        // if user but not authenticated route, route to portal - default route for logged in users
        if (userService.hasCredentials() && !nextState.data) {
          trans.abort()
          return $state.go('app.dashboard')
        }
      })

      $transitions.onError(null, function(trans) {
        var error = trans.error().detail
        trans.abort()

        // if token expired logout
        if (error && error.__status === 403) {
          userService.logout()
        }
      })
    }])
})()
