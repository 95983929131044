;(function() {
  'use strict'

  angular
    .module('core.contentPanel')
    .directive('glContentPanel', glContentPanelDirective)

  /* @ngInject */
  function glContentPanelDirective() {
    return {
      restrict: 'EA',
      templateUrl: 'content-panel.tpl.html',
      transclude: true,
      replace: true,
    }
  }
})()
