;(function() {
  'use strict'

  SubscribersConfig.$inject = ["$stateProvider"];
  angular.module('app.subscribers', []).config(SubscribersConfig)

  /* @ngInject */
  function SubscribersConfig($stateProvider) {
    $stateProvider.state('app.subscribers', {
      url: '/subscribers?filters',
      templateUrl: 'subscribers.template.html',
      controller: 'SubscribersController',
    })
  }
})()
