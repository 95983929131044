;(function() {
  'use strict'

  ElementsConfig.$inject = ["$stateProvider"];
  angular.module('app.elements', []).config(ElementsConfig)

  /* @ngInject */
  function ElementsConfig($stateProvider) {
    $stateProvider.state('app.elements', {
      url: '/elements?sid&filters',
      templateUrl: 'elements.template.html',
      controller: 'ElementsController',
    })
  }
})()
