;(function() {
  'use strict'

  Service.$inject = ["$q", "questionTemplateFactory", "questionTemplatesResource"];
  angular.module('core.services').factory('questionTemplatesService', Service)

  /* @ngInject */
  function Service($q, questionTemplateFactory, questionTemplatesResource) {
    return {
      get: get,
      list: list,
    }

    function get(id) {
      return $q(function(resolve, reject) {
        if (!id) {
          return reject()
        }

        questionTemplatesResource
          .get({ id: id })
          .success(function(x, template) {
            template = questionTemplateFactory.create().fromServer(template)
            resolve(template)
          })
          .error(function(data, status) {
            reject({ data: data, status: status })
          })
      })
    }

    function list(options) {
      return $q(function(resolve, reject) {
        _.defaults(options, {})

        questionTemplatesResource
          .get(options)
          .success(function(x, templates) {
            templates = _.map(templates, function(template) {
              return questionTemplateFactory.create().fromServer(template)
            })
            resolve(templates)
          })
          .error(function(data, status) {
            reject({ data: data, status: status })
          })
      })
    }
  }
})()
