;(function() {
  'use strict'

  LinksController.$inject = ["$scope", "$stateParams", "breadcrumbsService", "glDialog", "linkService", "glToast"];
  angular.module('app.links').controller('LinksController', LinksController)

  /* @ngInject */
  function LinksController(
    $scope,
    $stateParams,
    breadcrumbsService,
    glDialog,
    linkService,
    glToast
  ) {
    $scope.links = []
    $scope.filters = {
      top: '100',
    }
    $scope.loading = false

    $scope.getLinks = getLinks
    $scope.editLink = editLink

    activate()

    /**
     * initialise
     */
    function activate() {
      getLinks()
      breadcrumbsService.addBreadcrumb('Links', 'home')
    }

    /**
     * Get links from server
     */
    function getLinks() {
      $scope.loading = true

      var filters = []
      if ($scope.filters.query) {
        filters.push(
          'shortUrl regex /' +
            $scope.filters.query +
            '/i or longUrl regex /' +
            $scope.filters.query +
            '/i'
        )
      }

      var options = {
        orderby: 'updatedAt desc',
        top: $scope.filters.top,
        filter: filters.join(' and '),
      }

      linkService
        .get(options)
        .then(getSuccess, getError)
        .finally(getFinally)

      function getSuccess(data) {
        $scope.links = data
      }

      function getError() {
        glToast.action('Error retrieving links.', 'Retry', getLinks, {
          target: '.main-content',
          timeout: 5000,
        })
      }

      function getFinally() {
        $scope.loading = false
      }
    }

    /**
     * @name Edit Link
     * @param {object} link - the link the view/edit, otherwise one will be created
     */
    function editLink(link) {
      glDialog
        .show({
          templateUrl: 'link-edit-dialog.template.html',
          controller: 'LinkEditDialog',
          locals: {
            link: angular.copy(link),
          },
          parent: '.main-content',
          escapeToClose: false,
        })
        .then(function(updatedLink) {
          // if link was updated - reflect changes in the list

          if (link && updatedLink) {
            angular.copy(updatedLink, link)

            // if link was created - add to list
          } else if (updatedLink) {
            $scope.links.unshift(updatedLink)
          }
        })
    }
  }
})()
