;(function() {
  'use strict'

  angular.module('core.spinner').directive('glSpinner', glSpinnerDirective)

  /* @ngInject */
  function glSpinnerDirective() {
    return {
      restrict: 'E',
      templateUrl: 'spinner.tpl.html',
      scope: {
        color: '@',
      },
    }
  }
})()
