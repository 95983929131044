;(function() {
  'use strict'

  UsageExporterService.$inject = ["glDialog", "api", "glUtils", "glAuthService", "configService"];
  angular.module('app.core').service('usageExporterService', UsageExporterService)

  /* @ngInject */
  function UsageExporterService(
    glDialog,
    api,
    glUtils,
    glAuthService,
    configService
  ) {
    this.getDownloadUrl = getDownloadUrl
    this.getDataDownloadUrl = getDataDownloadUrl

    function getDownloadUrl(subscriberId, type, startAt, endAt, statementId) {
      var token = glAuthService.getToken()
      var endpoint = configService.getApiUrl('/transaction-statements')
      return glUtils.addParams(endpoint, {
        subscriberId: subscriberId,
        type: type,
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
        api_token: token,
        query: 'ExportTransactionsByRange',
        statementId: statementId
      })
    }

    function getDataDownloadUrl(subscriberId, startAt, endAt, statementId) {
      var token = glAuthService.getToken()
      var endpoint = configService.getApiUrl('/transaction-statements')
      return glUtils.addParams(endpoint, {
        subscriberId: subscriberId,
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
        api_token: token,
        query: 'ExportDataTransactionsByRange',
        statementId: statementId
      })
    }
  }
})()
