;(function() {
  'use strict'

  elementService.$inject = ["$q", "elementsResource", "configService"];
  angular.module('core.services').factory('elementService', elementService)

  /* @ngInject */
  function elementService($q, elementsResource, configService) {
    return {
      get: get,
      getAll: getAll,
      getBySubscriber: getBySubscriber,
      getPendingElements: getPendingElements,
      save: save,
      setClaimState: setClaimState,
      updatePostCouponId: updatePostCouponId,
      updateCustomerCareEmail: updateCustomerCareEmail,
      getQRString: getQRString,
    }

    /**
     * @name get
     * @description get an element resource by ID
     * @param {String} elementId element ID
     * @return {Promise} promise to resolve an element resource
     */
    function get(elementId) {
      var deferred = $q.defer()

      elementsResource
        .get({ id: elementId })
        .success(function(resource) {
          deferred.resolve(resource)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name getAll
     * @description get list of element resources
     * @return {Promise} promise to resolve element resources
     */
    function getAll(options) {
      var deferred = $q.defer()

      options = options || {}

      elementsResource
        .get(options)
        .success(function(resources) {
          deferred.resolve(resources)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name getBySubscriber
     * @description get elements by subscriber
     * @param {Object} options must include subscriber ID
     * @return {Promise} promise will resolve array of element resources
     */
    function getBySubscriber(options) {
      var deferred = $q.defer()

      elementsResource
        .getBySubscriber(options)
        .success(function(resource) {
          deferred.resolve(resource)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name getPendingElements
     * @description return an array of elements in the "PENDING" state
     * @return {Array} array of elements
     */
    function getPendingElements() {
      var deferred = $q.defer()

      elementsResource
        .get({
          filter: 'claimState.value eq PENDING',
        })
        .success(function(data) {
          deferred.resolve(data)
        })
        .error(function(error) {
          deferred.reject(error.message)
        })

      return deferred.promise
    }

    /**
     * @name save
     * @description save element
     * @param {{}} element
     * @return {Promise} promise to resolve on success
     */
    function save(element) {
      var deferred = $q.defer()

      // create copy and remove unnecessary properties
      var elem = angular.copy(element)
      delete elem.postCouponId

      elementsResource
        .save(elem)
        .success(function(resource) {
          deferred.resolve(resource)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name setClaimState
     * @description set claim state of an element
     * @param {{}} element element to update
     * @param {String} state valud to set claimState - must be CLAIMED, UNCLAIMED or PENDING
     */
    function setClaimState(element, state) {
      var deferred = $q.defer()

      elementsResource
        .updateClaimState({ id: element.id, claimState: state })
        .success(function(data) {
          deferred.resolve(data)
        })
        .error(function(error) {
          deferred.reject(error.message)
        })

      return deferred.promise
    }

    /**
     * @name updatePostCouponId
     * @description update the coupon attached to the element
     * @param {{}} element element to update
     * @param {string} couponId the id of the coupon to attach
     */
    function updatePostCouponId(element, couponId) {
      var deferred = $q.defer()

      var elementId = element.id

      elementsResource
        .updatePostCouponId({
          id: elementId,
          postCouponId: couponId,
        })
        .success(function(data) {
          deferred.resolve(data)
        })
        .error(function(error) {
          deferred.reject(error.message)
        })

      return deferred.promise
    }

    /**
     * @name updateCustomerCareEmail
     * @description update the care email of an element
     * @param {{}} element element to update
     * @param {string} customerCareEmail the new email address, null to delete
     */
    function updateCustomerCareEmail(element, customerCareEmail) {
      var deferred = $q.defer()

      var elementId = element.id

      elementsResource
        .updateCustomerCareEmail({
          id: elementId,
          customerCareEmail: customerCareEmail,
        })
        .success(function(data) {
          deferred.resolve(data)
        })
        .error(function(error) {
          deferred.reject(error.message)
        })

      return deferred.promise
    }

    /**
     * @name getQRString
     * @description get string to generate QR code for element
     * @param  {Object} elementId element ID to create code from
     * @return {String}         value to encode into QR code
     */
    function getQRString(elementId) {
      return configService.getUserPortalUrl() + '/action/!E' + elementId
    }
  }
})()
