;(function() {
  'use strict'

  SubscriberConfig.$inject = ["$stateProvider"];
  angular.module('app.subscriber', []).config(SubscriberConfig)

  /* @ngInject */
  function SubscriberConfig($stateProvider) {
    $stateProvider.state('app.subscriber', {
      url: '/subscriber/:id',
      templateUrl: 'subscriber.template.html',
      controller: 'SubscriberController',
    })
  }
})()
