;(function() {
  'use strict'

  angular
    .module('core.filters')
    .filter('groupInteractions', groupInteractionsFilter)

  /**
   * @name groupInteractions
   * @description group interactions by day
   * @param {[]} array of interaction objects
   * @returns {[]} array of arrays of interactions grouped by createdAt date
   */
  function groupInteractionsFilter() {
    return function(input) {
      return _(input)
        .groupBy(function(interaction) {
          var date = interaction.updatedAt || interaction.createdAt
          var dateAsDay = moment(date)
            .startOf('day')
            .toISOString()
          return dateAsDay
        })
        .toPairs()
        .sortBy(function(pair) {
          var dayTimestamp = -moment(pair[0]).unix()
          return dayTimestamp
        })
        .map(function(pairs) {
          var interactionGroup = pairs[1]
          return _.sortBy(interactionGroup, function(interaction) {
            var date = interaction.updatedAt || interaction.createdAt
            var timestamp = moment(date).unix()
            return -timestamp
          })
        })
        .value()
    }
  }
})()
