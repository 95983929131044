;(function() {
  'use strict'

  couponService.$inject = ["$q", "couponsResource"];
  angular.module('core.services').factory('couponService', couponService)

  /* @ngInject */
  function couponService($q, couponsResource) {
    return {
      create: create,
      deleteBySubscriber: deleteBySubscriber,
      get: get,
      getAll: getAll,
      getBySubscriber: getBySubscriber,
      saveImage: saveImage,
      saveRedemptionImage: saveRedemptionImage,
      update: update,
    }

    /**
     * @name create
     * @description create a new coupon
     * @param {{}} couponData coupon object
     * @return {Promise} promise will resolve on creation success
     */
    function create(couponData) {
      var deferred = $q.defer()

      couponsResource
        .createSecure(couponData)
        .success(function(resource) {
          deferred.resolve(resource)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name deleteBySubscriber
     * @description delete coupon from subscriber
     * @param {String} couponId coupon ID
     * @param {String} subscriberId subscriber ID
     * @return {Promise} promise will resolve on deletion success
     */
    function deleteBySubscriber(couponId, subscriberId) {
      var deferred = $q.defer()
      var data = {
        id: couponId,
        subscriberId: subscriberId,
      }

      couponsResource
        .deleteBySubscriber(data)
        .success(function(resource) {
          deferred.resolve(resource)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name get
     * @description get a coupon resource by ID
     * @param {String} couponId coupon ID
     * @return {Promise} promise to resolve a coupon resource
     */
    function get(couponId) {
      var deferred = $q.defer()

      couponsResource
        .get({ id: couponId })
        .success(function(resource) {
          deferred.resolve(resource)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name getAll
     * @description get list of coupon resources
     * @return {Promise} promise to resolve coupon resources
     */
    function getAll(options) {
      var deferred = $q.defer()

      options = options || {}

      couponsResource
        .get(options)
        .success(function(resources) {
          deferred.resolve(resources)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name getBySubscriber
     * @description get coupon from subscriber
     * @param {String} subscriberId subscriber ID
     * @return {Promise} promise will resolve on deletion success
     */
    function getBySubscriber(subscriberId) {
      var deferred = $q.defer()

      couponsResource
        .getBySubscriber({ subscriberId: subscriberId })
        .success(function(resource) {
          deferred.resolve(resource)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name saveImage
     * @description save image to coupon
     * @param {String} couponId coupon ID
     * @param {String} image image data to be added
     * @return {Promise} promise will resolve on save success
     */
    function saveImage(couponId, image) {
      var deferred = $q.defer()
      var data = {
        id: couponId,
        imageData: image,
      }

      couponsResource
        .saveImage(data)
        .success(function(resource) {
          deferred.resolve(resource)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name saveRedemptionImage
     * @description save image to coupon
     * @param {String} couponId coupon ID
     * @param {String} image image data to be added
     * @return {Promise} promise will resolve on save success
     */
    function saveRedemptionImage(couponId, image) {
      var deferred = $q.defer()
      var data = {
        id: couponId,
        imageData: image,
      }

      couponsResource
        .saveRedemptionImage(data)
        .success(function(resource) {
          deferred.resolve(resource)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name update
     * @description update coupon data
     * @param {{}} couponData coupon object
     * @return {Promise} promise will resolve on update success
     */
    function update(couponData) {
      var deferred = $q.defer()

      couponsResource
        .update(couponData)
        .success(function(resource) {
          deferred.resolve(resource)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }
  }
})()
