;(function() {
  'use strict'

  Controller.$inject = ["glDialog"];
  angular.module('app.core').component('contentGroupsSelector', {
    controller: Controller,
    templateUrl: 'content-groups-selector.html',
    bindings: {
      contentGroups: '<',
      onAdd: '&',
      onRemove: '&',
    },
  })

  /* @ngInject */
  function Controller(glDialog) {
    var ctrl = this

    ctrl.add = add

    function add() {
      glDialog
        .show({
          templateUrl: 'content-groups-selector-dialog.html',
          locals: {
            currentContentGroups: _.map(ctrl.contentGroups, 'id'),
          },
        })
        .then(function(contentGroup) {
          ctrl.onAdd({ $contentGroup: contentGroup })
        })
    }
  }
})()
