;(function() {
  'use strict'

  qr.$inject = ["$log"];
  angular.module('core.qr').directive('qr', qr)

  // --------------------

  /* @ngInject */
  function qr($log) {
    var img
    var imgUrl

    var log = $log.create('qrDirective')

    return {
      restrict: 'E',
      templateUrl: 'qr.tpl.html',
      scope: {
        value: '=',
        size: '=',
        onLoad: '&',
        onError: '&',
      },
      link: link,
    }

    // -----

    function link(scope) {
      scope.onLoad = scope.onLoad || angular.noop
      scope.onError = scope.onError || angular.noop
      scope.loadImage = loadImage
      scope.getQRUrl = getQRUrl

      init()

      // ----------

      function init() {
        imgUrl = getQRUrl()
        loadImage()
      }

      function getQRUrl() {
        if (!scope.value) {
          return
        }
        var baseUrl = 'https://api.qrserver.com/v1/create-qr-code/'
        var params = {
          bgColor: 'fff',
          color: '4c4c4c',
          size: scope.size || 150,
          data: scope.value,
        }
        var urlParams

        _.each(params, function(value, key) {
          urlParams = urlParams ? urlParams + '&' : '?'
          urlParams += key + '=' + encodeURIComponent(value)
        })

        return baseUrl + urlParams
      }

      function loadImage() {
        img = new Image()
        img.addEventListener('load', onImageLoad, false)
        img.addEventListener('error', onImageError, false)
        img.src = imgUrl
      }

      function onImageLoad() {
        scope.$evalAsync(scope.onLoad)
      }

      function onImageError() {
        log.error('QRCode image could not be loaded.')
        scope.$evalAsync(scope.onError)
      }
    }
  }
})()
