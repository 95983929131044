;(function() {
  'use strict'

  LinkCreateController.$inject = ["$scope", "configService", "glToast", "linkService", "glUtils"];
  angular.module('app.links').controller('LinkEditDialog', LinkCreateController)

  /* @ngInject */
  function LinkCreateController(
    $scope,
    configService,
    glToast,
    linkService,
    glUtils
  ) {
    var INVALID_GLOW_URL = /http:\/\/([a-zA-Z]*\.glowfeed\.com|glowfeed\.com)/i

    $scope.prefix = configService.getGlowLinkHost()

    $scope.save = save
    $scope.close = close

    activate()

    function activate() {
      if (!$scope.link) {
        $scope.link = {}
        $scope.isNew = true
      }
    }

    function save() {
      var validUrl = glUtils.validateUrl($scope.link.longUrl)
      var invalidGlowUrls = $scope.link.longUrl.match(INVALID_GLOW_URL)

      // reset existing error
      $scope.error = null

      if (!validUrl) {
        $scope.error = 'Invalid URL'
        return
      }

      if (invalidGlowUrls) {
        $scope.error = 'glowfeed.com domains should be on https not http.'
        return
      }

      if ($scope.isNew) {
        create()
      } else {
        update()
      }
    }

    function create() {
      $scope.error = null
      $scope.processing = true

      linkService
        .create($scope.link.longUrl, $scope.link.fragment)
        .then(success, error)
        .finally(complete)

      function success(response) {
        // optimistic changes
        $scope.link.shortUrl = response.shortUrl
        $scope.link.fragment = response.shortUrl.split('/').pop()
        $scope.link.createdAt = $scope.link.updatedAt = moment().format()

        // reset form
        $scope.isNew = false
        $scope.form.$setPristine()

        // notify
        glToast.show('Link Created.', { target: '.main-content' })

        // remember latest changes
        $scope.updatedLink = angular.copy($scope.link)
      }

      function error() {
        $scope.error = 'Link could not be created, please try again.'
      }

      function complete() {
        $scope.processing = false
      }
    }

    function update() {
      $scope.error = null
      $scope.processing = true

      linkService
        .update($scope.link.shortUrl, $scope.link.longUrl)
        .then(success, error)
        .finally(complete)

      function success() {
        // optimistic changes
        $scope.link.updatedAt = moment().format()

        // notify
        glToast.show('Link Updated.', { target: '.main-content' })

        // reset form
        $scope.form.$setPristine()

        // remember latest changes
        $scope.updatedLink = angular.copy($scope.link)
      }

      function error() {
        $scope.error = 'Link could not be updated, please try again.'
      }

      function complete() {
        $scope.processing = false
      }
    }

    function close() {
      $scope.dialog.close($scope.updatedLink)
    }
  }
})()
