;(function() {
  'use strict'

  promotionsService.$inject = ["$q", "promotionsResource"];
  angular
    .module('core.services')
    .factory('promotionsService', promotionsService)

  /* @ngInject */
  function promotionsService($q, promotionsResource) {
    return {
      addToPromotion: addToPromotion,
      removeFromPromotion: removeFromPromotion,
    }

    /**
     * @name addToPromotion
     * @description add an element to a promotion
     * @param {String} elementId element ID
     * @param {String} promotion promotion name
     * @return {Promise}
     */
    function addToPromotion(elementId, promotion) {
      var deferred = $q.defer()

      promotionsResource
        .add({ elementId: elementId, promotion: promotion })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name removeFromPromotion
     * @description add an element to a promotion
     * @param {String} elementId element ID
     * @param {String} promotion promotion name
     * @return {Promise}
     */
    function removeFromPromotion(elementId, promotion) {
      var deferred = $q.defer()

      promotionsResource
        .remove({ elementId: elementId, promotion: promotion })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }
  }
})()
