;(function() {
  'use strict'

  angular.module('core.backdrop').directive('glBackdrop', glBackdropDirective)

  /* @ngInject */
  function glBackdropDirective() {
    return {
      restrict: 'EA',
      replace: true,
      template:
        '<div class="gl-backdrop-wrapper"><div class="gl-backdrop"></div></div>',
    }
  }
})()
