;(function() {
  'use strict'

  UsageService.$inject = ["$q", "transactionsResource", "subscriberService", "UsageStatement"];
  angular.module('core.services').factory('usageService', UsageService)

  /* @ngInject */
  function UsageService(
    $q,
    transactionsResource,
    subscriberService,
    UsageStatement
  ) {
    return {
      usageByDateRange: usageByDateRange,
      getPreviousUsageStatement: getPreviousUsageStatement,
      listPreviousUsageStatements: listPreviousUsageStatements,
      processUsageStatement: processUsageStatement,
      createAdjustmentTransaction: createAdjustmentTransaction,
      sendUsageStatementToXero: sendUsageStatementToXero,
    }

    function usageByDateRange(subscriberId, startAt, endAt) {
      return $q(function(resolve, reject) {
        transactionsResource
          .getUsageStatement({
            subscriberId: subscriberId,
            startAt: startAt,
            endAt: endAt,
          })
          .success(function(x, resp) {
            subscriberService.get(subscriberId).then(function(subscriber) {
              var data = {}

              data.subscriberId = subscriber.id
              data.currency = subscriber.billing.currency
              data.lineItems = resp.items
              data.range = {
                startAt: startAt,
                endAt: endAt,
              }
              data.accountBalance = subscriber.billing.credit

              var usageStatement = new UsageStatement().deserialize(data)

              resolve(usageStatement)
            })
          })
          .error(reject)
      })
    }

    function getPreviousUsageStatement(subscriberId, statementId) {
      return $q(function(resolve, reject) {
        transactionsResource
          .getPreviousUsageStatement({
            id: statementId,
          })
          .success(function(x, resp) {
            subscriberService.get(subscriberId).then(function(subscriber) {
              var data = {}

              // Hide some stuff
              data.isProcessed = true

              if (resp.consumedCredit) {
                data.consumedCredit = resp.consumedCredit
              }

              data.id = statementId
              data.invoiceId = resp.invoiceId
              data.subscriberId = subscriber.id
              data.currency = subscriber.billing.currency
              data.lineItems = resp.items
              data.range = {
                startAt: resp.startAt,
                endAt: resp.endAt,
              }
              data.accountBalance = subscriber.billing.balance

              var usageStatement = new UsageStatement().deserialize(data)

              resolve(usageStatement)
            })
          })
          .error(reject)
      })
    }

    function listPreviousUsageStatements(subscriberId) {
      return $q(function(resolve, reject) {
        transactionsResource
          .listPreviousUsageStatements({
            subscriberId: subscriberId,
          })
          .success(function(x, resp) {
            resolve(resp)
          })
          .error(reject)
      })
    }

    function processUsageStatement(
      subscriberId,
      startAt,
      endAt,
      consumedCredit
    ) {
      var deferred = $q.defer()

      transactionsResource
        .processUsageStatement({
          subscriberId: subscriberId,
          startAt: startAt.toISOString(),
          endAt: endAt.toISOString(),
          consumedCredit: consumedCredit,
        })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    function sendUsageStatementToXero(id) {
      var deferred = $q.defer()

      transactionsResource
        .sendUsageStatementToXero({
          id: id,
        })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    function createAdjustmentTransaction(
      subscriberId,
      type,
      cost,
      insertIntoDate
    ) {
      var deferred = $q.defer()

      var data = {
        subscriberId: subscriberId,
        type: type,
        cost: cost,
        insertIntoDate: insertIntoDate,
      }

      transactionsResource
        .createAdjustmentTransaction(data)
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }
  }
})()
