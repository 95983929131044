;(function() {
  'use strict'

  ElementController.$inject = ["$scope", "$stateParams", "breadcrumbsService", "glDialog", "elementService", "elementsResource", "glToast", "couponService", "imagesService", "promotionsService", "$q"];
  angular
    .module('app.element')
    .controller('ElementController', ElementController)

  /* @ngInject */
  function ElementController(
    $scope,
    $stateParams,
    breadcrumbsService,
    glDialog,
    elementService,
    elementsResource,
    glToast,
    couponService,
    imagesService,
    promotionsService,
    $q
  ) {
    var feedbackCategoriesChanged
    var haveSentPostAccessEmails

    $scope.loading = false
    $scope.processing = false

    $scope.subscriberId = $stateParams.subscriberId

    $scope.data = {}

    $scope.countries = [{ label: 'Australia', value: 'Australia' }]

    $scope.countryCodes = {
      Australia: 'au',
    }

    $scope.save = save
    $scope.addCategory = addCategory
    $scope.removeCategory = removeCategory
    $scope.onCountryChange = onCountryChange
    $scope.toggleFbfg = toggleFbfg
    $scope.canSendPostAccessEmails = canSendPostAccessEmails
    $scope.sendPostAccessEmails = sendPostAccessEmails

    $scope.setImage = setImage
    $scope.setFeatureImage = setFeatureImage

    activate()

    /**
     * initialise
     */
    function activate() {
      var elementPromise = getElement()
      var couponsPromise = getCoupons()
      $scope.loading = true
      $q.all([elementPromise, couponsPromise]).then(function() {
        $scope.loading = false
      })

      breadcrumbsService.addBreadcrumb('Element', 'app.elements')
      $scope.$watch('data.element.feedbackCategories', onCategoriesChange, true)
    }

    /**
     * On categories changes set flag as true
     */
    function onCategoriesChange(newVal, oldVal) {
      if (oldVal && !feedbackCategoriesChanged) {
        feedbackCategoriesChanged = true
        $scope.elementForm.$pristine = false
      }
    }

    /**
     * Get element resource
     */
    function getElement() {
      if ($scope.data.element) {
        return
      }

      if (!$stateParams.elementId) {
        $scope.data.element = {
          categories: [],
        }
        return
      }

      return elementService
        .get($stateParams.elementId)
        .then(getSuccess, getError)

      function getSuccess(data) {
        $scope.data.element = data
        $scope.data.element.qrString = elementService.getQRString(
          $scope.data.element.id
        )
        breadcrumbsService.updateCurrentTitle(data.title)
      }

      function getError() {
        glToast.action('Error retrieving element.', 'Retry', getElement, {
          target: '.main-content',
          timeout: 5000,
        })
      }
    }

    /**
     * Get coupons of the element if subscriberId exists
     */
    function getCoupons() {
      var subscriberId = $scope.subscriberId
      if ('string' === typeof subscriberId && '' !== subscriberId) {
        return couponService
          .getBySubscriber(subscriberId)
          .then(getSuccess, getError)
      }

      function getSuccess(data) {
        $scope.data.coupons = data
      }

      function getError() {
        glToast.action('Error retrieving coupons.', 'Retry', getElement, {
          target: '.main-content',
          timeout: 5000,
        })
      }
    }

    /**
     * Save changes to element
     */
    function save() {
      if (feedbackCategoriesChanged) {
        glDialog
          .confirm(
            'Feedback Categories',
            'This element has already received feedback but you have ' +
              "changed it's categories. Are you sure you want to continue?"
          )
          .then(saveChanges)
      } else {
        saveChanges()
      }

      function saveChanges() {
        var saveElementPromise = saveElement()
        var saveCouponsPromise = saveCoupons()
        var saveCustomerCareEmailPromise = saveCustomerCareEmail()
        $scope.processing = true

        $q.all([
          saveElementPromise,
          saveCouponsPromise,
          saveCustomerCareEmailPromise,
        ])
          .then(saveSuccess, saveError)
          .finally(saveFinished)

        function saveElement() {
          _.each(['immediateSurveyId', 'qrString'], function(key) {
            if (typeof $scope.data.element[key] !== 'undefined') {
              delete $scope.data.element[key]
            }
          })
          return elementService.save($scope.data.element)
        }

        function saveCoupons() {
          if (
            'postCouponId' in $scope.elementForm &&
            $scope.elementForm.postCouponId.$pristine === false
          ) {
            return elementService.updatePostCouponId(
              $scope.data.element,
              $scope.data.element.postCouponId
            )
          }
        }

        function saveCustomerCareEmail() {
          if ($scope.elementForm.customerCareEmail.$pristine === false) {
            var customerCareEmail = $scope.data.element.customerCareEmail
              ? $scope.data.element.customerCareEmail
              : null
            return elementService.updateCustomerCareEmail(
              $scope.data.element,
              customerCareEmail
            )
          }
        }

        function saveSuccess() {
          haveSentPostAccessEmails = false
          $scope.elementForm.$setPristine(true)
          glToast.show('Element saved', {
            target: '.main-content',
          })
        }

        function saveError() {
          glToast.action("Couldn't save element.", 'Retry', save, {
            target: '.main-content',
            timeout: 5000,
          })
        }

        function saveFinished() {
          $scope.processing = false
        }
      }
    }

    /**
     * add new feedback category
     */
    function addCategory() {
      if (!$scope.data.element.feedbackCategories) {
        $scope.data.element.feedbackCategories = []
      }
      $scope.data.element.feedbackCategories.push('')
    }

    /**
     * remove feedback category
     * @param  {Number} index index of category in array to remove
     */
    function removeCategory(index) {
      $scope.data.element.feedbackCategories.splice(index, 1)
    }

    /**
     * update country code on country change
     */
    function onCountryChange() {
      $scope.data.element.placeData.cc =
        $scope.countryCodes[$scope.data.element.placeData.country]
    }

    /**
     * @name toggleFbfg
     * @description toggle elements involvement in the Feedback for Good promotion
     */
    function toggleFbfg() {
      var element = $scope.data.element
      var action = element.promotions.fbfg
        ? 'removeFromPromotion'
        : 'addToPromotion'

      // assume success
      element.promotions.fbfg = !element.promotions.fbfg

      promotionsService[action](element.id, 'fbfg').then(onSuccess, onError)

      function onSuccess() {
        var message =
          action === 'addToPromotion'
            ? 'Element added to FBFG promotion'
            : 'Element removed from FBFG promotion'
        glToast.show(message, {
          target: '.main-content',
        })
      }

      function onError() {
        // reset toggle
        element.promotions.fbfg = !element.promotions.fbfg

        glToast.action(
          "Couldn't update FBFG promotion status.",
          'Retry',
          toggleFbfg,
          {
            target: '.main-content',
            timeout: 5000,
          }
        )
      }
    }

    function canSendPostAccessEmails() {
      return (
        $scope.data.element &&
        $scope.data.element.customerCareEmail &&
        $scope.elementForm.$pristine &&
        !$scope.data.element.isSubscriberOwned &&
        !haveSentPostAccessEmails
      )
    }

    function sendPostAccessEmails() {
      var title = 'Send post-access emails?'
      var message =
        'WARNING: This will send one email for every post this element has received, ' +
        'to their current customer care email...'

      glDialog.confirm(title, message).then(send)

      function send() {
        haveSentPostAccessEmails = true
        elementsResource
          .postAccess({ id: $scope.data.element.id })
          .success(success)
          .error(error)
      }

      function success() {
        glToast.show('Emails sent')
      }

      function error() {
        glToast.action('There was an error sending emails', 'Retry', send)
        haveSentPostAccessEmails = false
      }
    }

    function setImage(imageData) {
      if (!imageData) {
        $scope.data.element.imageUrl = null
        $scope.elementForm.$setDirty()
        return
      }

      uploadImage(imageData).then(
        function(response) {
          $scope.data.element.imageUrl = response.imageUrl
          $scope.elementForm.$setDirty()
        },
        function() {
          displayImageError()
        }
      )
    }

    function setFeatureImage(imageData) {
      if (!imageData) {
        $scope.data.element.featureImageUrl = null
        $scope.elementForm.$setDirty()
        return
      }

      uploadImage(imageData).then(
        function(response) {
          $scope.data.element.featureImageUrl = response.imageUrl
          $scope.elementForm.$setDirty()
        },
        function() {
          displayImageError()
        }
      )
    }

    function displayImageError() {
      glToast.show('Failed to save image', { target: '.main-content' })
    }

    function uploadImage(imageData) {
      $scope.processing = true

      var data = {
        imageData: imageData,
        request: 'element.save',
        elementId: $stateParams.elementId,
        source: 'admin.glowfeed.com',
      }

      return imagesService.create(data).finally(function() {
        $scope.processing = false
      })
    }
  }
})()
