;(function() {
  'use strict'

  angular
    .module('core.services')
    .factory('visualisationService', visualisationService)

  /**
   * ElementsService
   *
   * @ngInject */
  function visualisationService() {
    var service = {
      parseSeriesToColumns: parseSeriesToColumns,
    }
    return service

    /**
     * @name parseSeriesToColumns
     * @description parse series data as required by report templates into column data as
     *      required by c3.js chart library
     * @param {Array} series array of series objects of the type {name: name, data: [data]}
     * @return {Array} array of columns
     */
    function parseSeriesToColumns(series) {
      var columns = []

      angular.forEach(series, function(series) {
        columns.push([series.name].concat(series.data))
      })

      return columns
    }
  }
})()
