;(function() {
  'use strict'

  UsersConfig.$inject = ["$stateProvider"];
  angular.module('app.users', []).config(UsersConfig)

  /* @ngInject */
  function UsersConfig($stateProvider) {
    $stateProvider.state('app.users', {
      url: '/users?sid&filters',
      templateUrl: 'users.template.html',
      controller: 'UsersController',
    })
  }
})()
