;(function() {
  'use strict'

  ListFilterService.$inject = ["$state", "$stateParams"];
  angular
    .module('core.services')
    .service('listFilterService', ListFilterService)

  /* @ngInject */
  function ListFilterService($state, $stateParams) {
    this.initFilter = function() {
      try {
        return JSON.parse($stateParams.filters)
      } catch (e) {
        return undefined
      }
    }

    this.writeFilter = function(filter) {
      var encodedFilter = JSON.stringify(filter)
      var currentState = $state.current.name
      var newParams = _.extend($stateParams, { filters: encodedFilter })

      $state.go(currentState, newParams, { notify: false, reload: false })
    }

    return this
  }
})()
