;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$state", "glUtils", "glToast", "subscriberPromotionService", "stateService", "configService", "clipboardService", "breadcrumbsService"];
  angular.module('app.core').controller('Promotion', Controller)

  /* @ngInject */
  function Controller(
    $scope,
    $state,
    glUtils,
    glToast,
    subscriberPromotionService,
    stateService,
    configService,
    clipboardService,
    breadcrumbsService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.save = save
    ctrl.getLink = getLink
    ctrl.copyLink = copyLink

    function onInit() {
      $scope.$on('$destroy', onDestroy)

      ctrl.id = $state.params.id
      ctrl.state = stateService.define(['loading', 'error', 'ready'])
      breadcrumbsService.addBreadcrumb('Promotion', 'app.promotions')

      if (ctrl.id === 'new') {
        ctrl.promotion = subscriberPromotionService.instantiate()
        ctrl.state.ready()
      } else {
        ctrl.state.loading()
        subscriberPromotionService
          .get(ctrl.id)
          .then(function(promotion) {
            ctrl.promotion = promotion
            breadcrumbsService.updateCurrentTitle(ctrl.promotion.name)
            ctrl.state.ready()
          })
          .catch(function() {
            ctrl.state.error()
            glToast.show('Could not load promotion')
          })
      }
    }

    function getLink() {
      var code = ctrl.promotion.code || '<code>'
      return configService.getSubscriberPortalUrl('/signup?promo=' + code)
    }

    function copyLink() {
      clipboardService.copy(getLink())
    }

    function save() {
      var wasNew = ctrl.promotion.isNew()
      ctrl.isSaving = true
      return subscriberPromotionService
        .save(ctrl.promotion)
        .then(function() {
          if (wasNew) {
            glUtils.changeParams({ id: ctrl.promotion.id })
          }
          glToast.show('Promotion saved')
        })
        .catch(function(error) {
          glToast.show('Could not save promotion')
          console.error(error)
        })
        .finally(function() {
          ctrl.isSaving = false
        })
    }

    function onDestroy() {
      ctrl.promotion.revert() // revert any un-saved changes
    }
  }
})()
