;(function() {
  'use strict'

  UsageController.$inject = ["$q", "usageService", "glToast", "usageExporterService"];
  angular.module('core.usage').component('usage', {
    controller: UsageController,
    templateUrl: 'usage.html',
    bindings: {
      subscriberId: '<',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function UsageController($q, usageService, glToast, usageExporterService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.getUsageStatement = getUsageStatement
    ctrl.onPreviousChange = onPreviousChange
    ctrl.exportUsageByDateRange = exportUsageByDateRange
    ctrl.exportDataUsageByDateRange = exportDataUsageByDateRange
    ctrl.onStartChange = onStartChange
    ctrl.onEndChange = onEndChange
    ctrl.onProcess = onProcess
    ctrl.onSendToXero = onSendToXero

    function onInit() {
      ctrl.today = moment().endOf('day')
      ctrl.startAt = moment().subtract(30, 'days').startOf('day')
      ctrl.endAt = ctrl.today

      listPreviousUsageStatements()
    }

    function listPreviousUsageStatements() {
      usageService
        .listPreviousUsageStatements(ctrl.subscriberId)
        .then(function(statements) {
          ctrl.previousStatements = statements
          ctrl.previousUsageStatements = []
          statements.forEach(function(statement) {
            ctrl.previousUsageStatements.push({
              label:
                moment(statement.startAt).format('DD/MM/YYYY') +
                ' - ' +
                moment(statement.endAt).format('DD/MM/YYYY'),
              value: statement.id,
            })
          })
        })
    }

    function getUsageStatement() {
      ctrl.isGettingUsage = true
      if (ctrl.previousStatementId) {
        usageService
          .getPreviousUsageStatement(ctrl.subscriberId, ctrl.previousStatementId)
          .then(function(usageStatement) {
            ctrl.isGettingUsage = false
            ctrl.usageStatement = usageStatement
          })
      } else {
        usageService
          .usageByDateRange(ctrl.subscriberId, ctrl.startAt, ctrl.endAt)
          .then(function(usageStatement) {
            ctrl.isGettingUsage = false
            ctrl.usageStatement = usageStatement
          })
      }
    }

    function exportUsageByDateRange(type) {
      glToast.show('Starting download...', {
        target: '.main-content',
      })

      var statementId = null
      if (ctrl.previousStatementId) {
        statementId = ctrl.previousStatementId
      }

      window.location.href = usageExporterService.getDownloadUrl(
        ctrl.subscriberId,
        type,
        ctrl.startAt,
        ctrl.endAt,
        statementId
      )
    }

    function exportDataUsageByDateRange() {
      glToast.show('Starting download...', {
        target: '.main-content',
      })

      var statementId = null
      if (ctrl.previousStatementId) {
        statementId = ctrl.previousStatementId
      }

      window.location.href = usageExporterService.getDataDownloadUrl(
        ctrl.subscriberId,
        ctrl.startAt,
        ctrl.endAt,
        statementId
      )
    }

    function onProcess() {
      ctrl.isProcessingUsage = true

      var adjustmentPromises = []
      ctrl.usageStatement.adjustments.forEach(function(adjustment) {
        var promise = usageService.createAdjustmentTransaction(
          ctrl.subscriberId,
          adjustment.type,
          adjustment.total.amount,
          ctrl.endAt
        )

        adjustmentPromises.push(promise)
      })

      $q.all(adjustmentPromises).then(function() {
        usageService
          .processUsageStatement(
            ctrl.subscriberId,
            ctrl.startAt,
            ctrl.endAt,
            ctrl.usageStatement.consumedCredit.amount
          )
          .then(function(resp) {
            ctrl.usageStatement.isProcessed = true
            ctrl.usageStatement.id = resp.id
            ctrl.isProcessingUsage = false
            listPreviousUsageStatements()
          })
      }).catch(function(resp) {
        glToast.show('Failed to process...', {
          target: '.main-content',
        })
      })
    }

    function onSendToXero() {
      usageService
        .sendUsageStatementToXero(
          ctrl.usageStatement.id
        )
        .then(function(resp) {
          ctrl.usageStatement.invoiceId = resp.id
          glToast.show('Sent to Xero...', {
            target: '.main-content',
          })
        })
    }

    function onPreviousChange(value) {
      var statement = _.find(ctrl.previousStatements, { id: value })

      if (statement) {
        ctrl.startAt = moment(statement.startAt)
        ctrl.endAt = moment(statement.endAt)
        ctrl.previousStatementId = statement.id
      }
    }

    function onStartChange(date) {
      ctrl.startAt = date.startOf('day')
      ctrl.previousStatementId = null
    }

    function onEndChange(date) {
      ctrl.endAt = date.endOf('day')
      ctrl.previousStatementId = null
    }
  }
})()
