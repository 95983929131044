;(function() {
  'use strict'

  claimConfig.$inject = ["$stateProvider"];
  angular.module('app.claim', []).config(claimConfig)

  /* @ngInject */
  function claimConfig($stateProvider) {
    $stateProvider.state('app.claim', {
      url:
        '/claim?eid&etitle&esubtext&uid&ufullname&uemail&upost&sid&fname&fphone',
      templateUrl: 'claim.template.html',
      controller: 'ClaimController',
    })
  }
})()
