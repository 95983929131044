;(function() {
  'use strict'

  angular.module('core.filters').filter('prettyTimeAgo', filter)

  function filter() {
    return function(date) {
      if (!date) {
        return
      }

      var suffix
      var now = moment()
      var then = moment(date)
      var diffMins = now.diff(then, 'minutes')
      var diffHours = now.diff(then, 'hours')
      var diffDays = now.diff(then, 'days')

      // x mins ago
      // x hours ago
      // x days ago
      if (diffMins < 5) {
        return 'just now'
      } else if (diffMins < 60) {
        suffix = diffMins === 1 ? ' min ago' : ' mins ago'
        return diffMins + suffix
      } else if (diffHours < 24) {
        suffix = diffHours === 1 ? ' hour ago' : ' hours ago'
        return diffHours + suffix
      } else if (diffDays < 28) {
        suffix = diffDays === 1 ? ' day ago' : ' days ago'
        return diffDays + suffix
      }

      // jan 21
      // nov 2015
      if (now.get('year') === then.get('year')) {
        return then.format('MMM Do')
      } else {
        return then.format('MMM YYYY')
      }
    }
  }
})()
