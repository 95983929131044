;(function() {
  'use strict'

  usersService.$inject = ["$q", "usersResource"];
  angular.module('core.services').factory('usersService', usersService)

  /* @ngInject */
  function usersService($q, usersResource) {
    return {
      create: create,
      createSubscriberSupport: createSubscriberSupport,
      get: get,
      getAll: getAll,
      getBySubscriber: getBySubscriber,
      update: update,
      associateUserWithGroup: associateUserWithGroup,
      removeUserFromGroup: removeUserFromGroup,
    }

    /**
     * @name create
     * @description create a new user
     * @param {{}} userData user object
     * @return {Promise} promise will resolve on creation success
     */
    function create(userData) {
      var deferred = $q.defer()

      usersResource
        .create(userData)
        .success(function(resource) {
          deferred.resolve(resource)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name createSubscriberSupportUser
     * @description create a new subscriber support user
     * @param {object} userData - the user object
     * @returns {promise} a promise for the user creation
     */
    function createSubscriberSupport(userData) {
      return $q(function(resolve, reject) {
        usersResource
          .createSubscriberSupportUser(userData)
          .success(resolve)
          .error(reject)
      })
    }

    /**
     * @name get
     * @description get a user resource by ID
     * @param {String} userId user ID
     * @return {Promise} promise to resolve a user resource
     */
    function get(userId) {
      var deferred = $q.defer()

      usersResource
        .get({ id: userId })
        .success(function(resource) {
          deferred.resolve(resource)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name getAll
     * @description get list of user resources
     * @param {Object} options filter options
     * @return {Promise} promise to resolve user resources
     */
    function getAll(options) {
      var deferred = $q.defer()

      options = options || {}

      usersResource
        .get(options)
        .success(function(resources) {
          deferred.resolve(resources)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name getBySubscriber
     * @description get users by subscriber
     * @param {Object} options must include subscriber ID
     * @return {Promise} promise will resolve array of user resources
     */
    function getBySubscriber(options) {
      var deferred = $q.defer()

      usersResource
        .getBySubscriber(options)
        .success(function(resource) {
          deferred.resolve(resource)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name update
     * @description update user data
     * @param {{}} userData user object
     * @return {Promise} promise will resolve on update success
     */
    function update(userData) {
      var deferred = $q.defer()

      usersResource
        .update(userData)
        .success(function(resource) {
          deferred.resolve(resource)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    function associateUserWithGroup(userId, groupId) {
      var deferred = $q.defer()

      usersResource
        .associateUserWithGroup({
          id: userId,
          groupId: groupId,
        })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    function removeUserFromGroup(userId, groupId) {
      var deferred = $q.defer()

      usersResource
        .removeUserFromGroup({
          id: userId,
          groupId: groupId,
        })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }
  }
})()
