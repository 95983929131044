;(function() {
  'use strict'

  Service.$inject = ["glDialog"];
  angular
    .module('core.question-templates-picker')
    .factory('questionTemplatesPicker', Service)

  /* @ngInject */
  function Service(glDialog) {
    var state

    return {
      show: show,
      getState: getState,
      setState: setState,
    }

    function show() {
      return glDialog.show({
        templateUrl: 'question-templates-picker.template.html',
        controller: 'QuestionTemplatesPicker',
        escapeToClose: true,
        clickOutsideToClose: true,
      })
    }

    function getState() {
      return state
    }

    function setState(newState) {
      state = newState
    }
  }
})()
