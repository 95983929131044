;(function() {
  'use strict'

  angular.module('core.filters').filter('dateX', dateXFilter)

  function dateXFilter() {
    return function(dateString) {
      return moment(dateString).format('MMM d, YYYY')
    }
  }
})()
