;(function() {
  'use strict'

  Controller.$inject = ["surveyService"];
  angular
    .module('core.exportSurveyResponses')
    .component('exportSurveyResponses', {
      controller: Controller,
      templateUrl: 'export-survey-responses.html',
      bindings: {
        onCancel: '&',
      },
    })

  /* @ngInject */
  function Controller(surveyService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.exportByComparisonKey = exportByComparisonKey
    ctrl.exportByDateRange = exportByDateRange
    ctrl.onStartChange = onStartChange
    ctrl.onEndChange = onEndChange

    function onInit() {
      ctrl.today = moment().endOf('day')
      ctrl.startAt = moment().startOf('month')
      ctrl.endAt = ctrl.today
    }

    function exportByComparisonKey() {
      surveyService.exportAllResponsesByComparisonKey(ctrl.comparisonKey)
    }

    function exportByDateRange() {
      surveyService.exportSurveyTransactionsByDateRange(
        ctrl.startAt,
        ctrl.endAt
      )
    }

    function onStartChange(date) {
      ctrl.startAt = date.startOf('day')
    }

    function onEndChange(date) {
      ctrl.endAt = date.endOf('day')
    }
  }
})()
