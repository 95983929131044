;(function() {
  'use strict'

  angular.module('app.post').directive('postReply', postReplyDirective)

  /* @ngInject */
  function postReplyDirective() {
    controller.$inject = ["$scope", "postService", "glToast"];
    return {
      restrict: 'E',
      templateUrl: 'post-reply.template.html',
      controller: controller,
    }

    /* @ngInject */
    function controller($scope, postService, glToast) {
      $scope.replyActive = false
      $scope.reply = {
        message: '',
      }

      // methods
      $scope.replyFocus = replyFocus
      $scope.replyBlur = replyBlur
      $scope.sendReply = sendReply

      /**
       * set reply box active on focus
       */
      function replyFocus() {
        $scope.replyActive = true
      }

      /**
       * set reply box inactive if textarea blurred and no valid message
       */
      function replyBlur() {
        if (
          !$scope.reply.message ||
          ($scope.reply.message && $scope.reply.message.length < 1)
        ) {
          $scope.replyActive = false
        }
      }

      /*
             * Submit a reply to feedback
             */
      function sendReply() {
        var reply = {
          content: $scope.reply.message,
          creator: {
            type: 'admin',
            id: $scope.user.id,
            name: $scope.user.username,
          },
          createdAt: moment().format(),
        }

        $scope.sending = true

        // push to comments array
        $scope.data.post.thread.push(reply)

        postService
          .reply($scope.data.post.id, $scope.reply.message)
          .then(replySuccess, replyError)

        function replySuccess() {
          $scope.sending = false
          $scope.reply.message = ''
          replyBlur()
          glToast.show('Reply sent.', {
            target: '.main-content',
          })
        }

        function replyError() {
          $scope.sending = false
          $scope.data.post.thread.pop()
          glToast.action('Error posting comment.', 'Retry', sendReply, {
            target: '.main-content',
            timeout: 5000,
          })
        }
      }
    }
  }
})()
