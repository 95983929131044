;(function() {
  'use strict'

  angular.module('core.filters').filter('formatAnswer', formatAnswer)

  /* @ngInject */
  function formatAnswer() {
    return function(input) {
      switch (input.question.type) {
        // case 'multichoice':
        //     output = input.textAnswer;
        //     break;
        case 'multiselect':
          return _.map(input.choiceAnswers, function(choice) {
            return choice.option
          }).join(', ')
        // case 'singletext':
        //     output = input.textAnswer;
        //     break;
        // case 'text':
        //     output = input.textAnswer;
        //     break;
        case 'placesnearme':
          return formatPlaceAnswers(input.placesAnswers)
        case 'image':
          return formatImageUrlElement(input.textAnswer)
        case 'text':
          return input.textAnswer ? input.textAnswer.replace(/\|/g, ', ') : ''
        default:
          return input.textAnswer
      }
    }

    function formatPlaceAnswers(placesAnswers) {
      var place
      var placesString = ''

      for (var i = 0, len = placesAnswers.length; i < len; i++) {
        place = placesAnswers[i]
        placesString += place.name + ', ' + place.address + ' ' + place.state

        // if not last element in array add a seperator
        if (i < len - 1) {
          placesString += '\n'
        }
      }

      return placesString
    }

    function formatImageUrlElement(image) {
      return (
        '<img class="survey-response-image" ng-src="' +
        image +
        '" gl-image-viewer></img>'
      )
    }
  }
})()
