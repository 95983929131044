;(function() {
  'use strict'

  Config.$inject = ["$stateProvider"];
  angular.module('app.contentGroup', []).config(Config)

  /* @ngInject */
  function Config($stateProvider) {
    $stateProvider.state('app.contentGroup', {
      url: '/content-groups/:id',
      templateUrl: 'content-group.html',
      controller: 'ContentGroup',
      controllerAs: '$ctrl',
    })
  }
})()
