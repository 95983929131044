;(function() {
  'use strict'

  breadcrumbsService.$inject = ["$state"];
  angular
    .module('core.breadcrumbs')
    .service('breadcrumbsService', breadcrumbsService)

  /* @ngInject */
  function breadcrumbsService($state) {
    var list = []

    var service = {
      addBreadcrumb: addBreadcrumb,
      go: go,
      list: list,
      updateCurrentTitle: updateCurrentTitle,
    }
    return service

    /**
     * add breadcrumb to breadcrumbs list
     * @param {String} title  title for the breadcrumb - to appear in the breadcrumbs list
     * @param {String} parent parent state of the current page - to ensure new crumb appears just after
     */
    function addBreadcrumb(title, parent) {
      var curr = _.findIndex(list, function(crumb) {
        return crumb.origTitle === title
      })

      // if crumb already exists slice list at that crumb and return
      if (curr >= 0) {
        if (_.isEqual(list[curr].params, $state.params)) {
          list.length = curr + 1
          return
        }
      }

      // else we need to add a new crumb
      if (parent.toLowerCase() === 'home') {
        // if parent is home, reset list first
        list.length = 0
      } else if (angular.isDefined(parent)) {
        // check if parent is in list already and slice list at parent
        var index = _.findIndex(list, function(crumb) {
          return crumb.state === parent
        })
        list.length = index >= 0 ? index + 1 : 0
      }

      // add the new crumb
      list.push({
        title: title,
        origTitle: title,
        state: $state.current.name,
        params: $state.params,
      })
    }

    /**
     * go to state referenced by breadcrumb
     * @param  {Number} index index of the crumb in the list
     */
    function go(index) {
      $state.go(list[index].state, list[index].params)
    }

    /**
     * update current breadcrumb title i.e. after content has loaded
     * @param  {String} title new title for the breadcrumb
     */
    function updateCurrentTitle(title) {
      list[list.length - 1].title = title
    }
  }
})()
