;(function() {
  'use strict'

  glBreadcrumbsDirective.$inject = ["breadcrumbsService"];
  angular
    .module('core.breadcrumbs')
    .directive('glBreadcrumbs', glBreadcrumbsDirective)

  /* @ngInject */
  function glBreadcrumbsDirective(breadcrumbsService) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'breadcrumbs.template.html',
      link: link,
    }

    function link(scope) {
      scope.breadcrumbs = breadcrumbsService.list
      scope.go = function(index) {
        breadcrumbsService.go(index)
      }
    }
  }
})()
