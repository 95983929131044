;(function() {
  'use strict'

  Directive.$inject = ["$log", "questionFactory", "questionTemplatesService"];
  angular
    .module('core.question-templates-list')
    .directive('questionTemplatesList', Directive)

  /* @ngInject */
  function Directive($log, questionFactory, questionTemplatesService) {
    Controller.$inject = ["$scope"];
    var log = $log.create('questionTemplatesList')

    return {
      restrict: 'E',
      scope: {
        actions: '=',
        initialState: '=',
        onStateChange: '&',
        onSelect: '&',
      },
      templateUrl: 'question-templates-list.template.html',
      link: PostLink,
      controller: Controller,
    }

    function PostLink(scope, elem) {
      elem.addClass('question-templates-picker')
    }

    function Controller($scope) {
      var AMOUNT_PER_PAGE = ($scope.AMOUNT_PER_PAGE = 20)

      // set state defaults including initial-state
      var state = ($scope.state = _.defaultsDeep(
        $scope.initialState,
        {},
        {
          pages: 1,
          filters: {
            query: { value: null, isDisabled: false },
            type: { value: null, isDisabled: false },
            state: { value: null, isDisabled: false },
          },
          filterString: null,
        }
      ))

      $scope.fetchTemplates = fetchTemplates
      $scope.fetchMoreTemplates = fetchMoreTemplates
      $scope.select = select

      activate()

      /* activate
             * @desc initialise filter options and fetch templates
             */
      function activate() {
        setFilterOptions()
        fetchTemplates(state.pages)
      }

      /* setFilterOptions
             * @desc initialise filter interface options
             */
      function setFilterOptions() {
        // question-type options
        var Types = questionFactory.Question.Types
        $scope.questionFilters = questionFactory.getInfo([
          Types.MULTI_CHOICE,
          Types.MULTI_SELECT,
          Types.SINGLE_TEXT,
          Types.TEXT,
          Types.SCAN,
          Types.NPS,
          Types.MOOD,
          Types.IMAGE,
          Types.PLACES_NEAR_ME,
        ])

        // state options
        $scope.stateFilters = [
          { label: 'Draft', value: 'DRAFT' },
          { label: 'Published', value: 'PUBLISHED' },
          { label: 'Archived', value: 'ARCHIVED' },
        ]
      }

      /* fetchTemplates
             * @desc fetches a fresh set of templates based on the pages and filters set
             * @param {Number} pages how many pages of data to be fetched
             */
      function fetchTemplates(pages) {
        if (!_.isNumber(pages)) {
          return log.error('fetchTemplates no page number')
        }

        setState({ pages: pages })

        // request options
        var options = {
          top: state.pages * AMOUNT_PER_PAGE,
          orderby: 'updatedAt desc',
          filter: state.filterString,
        }

        // clear existing list
        $scope.templates = null

        // show loading spinner
        $scope.loading = true

        // fetch
        questionTemplatesService
          .list(options)
          .then(function(templates) {
            $scope.isMore = templates.length === options.top
            $scope.templates = templates
          })
          .catch(function() {
            $scope.error = true
            log.error('error fetching initial templates', options)
          })
          .finally(function() {
            $scope.loading = false
          })
      }

      /* fetchMoreTemplates
             * @desc fetches the next page of templates and appends to the existing set
             */
      function fetchMoreTemplates() {
        // increment pages by 1
        setState({ pages: state.pages + 1 })

        // request options
        var options = {
          top: AMOUNT_PER_PAGE,
          skip: (state.pages - 1) * AMOUNT_PER_PAGE,
          orderby: 'updatedAt desc',
          filter: state.filterString,
        }

        // show loading spinner
        $scope.loading = true

        // fetch
        questionTemplatesService
          .list(options)
          .then(function(templates) {
            $scope.isMore = templates.length === options.top
            $scope.templates.push.apply($scope.templates, templates)
          })
          .catch(function() {
            $scope.error = true
            log.error('error fetching more templates', options)
          })
          .finally(function() {
            $scope.loading = false
          })
      }

      /* setState
             * @desc updates state and notifies listeners
             * @param {Object} newState the state changes to be made
             * @returns {Object} state the new state
             */
      function setState(newState) {
        _.assign(state, newState)

        // parse filters into a string for requests
        state.filterString = parseFilters(state.filters)

        // notify listeners
        if ($scope.onStateChange) {
          $scope.onStateChange({ $state: state })
        }

        return state
      }

      /* parseFilters
             * @desc parses a filters object into the filter string used by the backend
             * @param {Object} filters the state filters
             * @returns {String} filters
             */
      function parseFilters(filters) {
        var arr = []
        if (filters.query.value) {
          arr.push('name regex /' + filters.query.value + '/i')
        }
        if (filters.type.value) {
          arr.push('question.type eq ' + filters.type.value)
        }
        if (filters.state.value) {
          arr.push('state eq ' + filters.state.value)
        }
        return arr.join(' and ')
      }

      function select(event, template, action) {
        event.preventDefault()
        event.stopPropagation()
        $scope.onSelect({ $template: template, $action: action })
      }
    }
  }
})()
