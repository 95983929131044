;(function() {
  'use strict'

  SurveysController.$inject = ["$scope", "$state", "$stateParams", "listFilterService", "breadcrumbsService", "glDialog", "glToast", "surveyService"];
  angular
    .module('app.surveys')
    .controller('SurveysController', SurveysController)

  /* @ngInject */
  function SurveysController(
    $scope,
    $state,
    $stateParams,
    listFilterService,
    breadcrumbsService,
    glDialog,
    glToast,
    surveyService
  ) {
    $scope.loading = false

    $scope.data = {
      elementId: $stateParams.eid,
      subscriberId: $stateParams.sid,
      surveys: [],
    }

    $scope.getSurveys = getSurveys
    $scope.clone = clone
    $scope.exportSurveys = exportSurveys

    // filters
    var uriFilters = listFilterService.initFilter($stateParams)
    $scope.filters = _.assign(
      {
        query: null,
        state: 'all',
        visibility: 'all',
        orderBy: 'createdAt',
        order: 'desc',
        top: 100,
        page: 1,
      },
      uriFilters
    )

    activate()

    /**
     * initialise
     */
    function activate() {
      getSurveys()
      var parentState = $stateParams.eid ? 'app.element' : 'home'
      breadcrumbsService.addBreadcrumb('Surveys', parentState)
      $scope.isEid = !!$stateParams.eid
    }

    function organiseSearchObj(srcObj) {
      var sendingObj = {}

      // top, page, orderby
      sendingObj.top = srcObj.top
      sendingObj.skip = srcObj.top * (srcObj.page - 1)
      sendingObj.orderBy = srcObj.orderBy + ' ' + srcObj.order

      if (typeof sendingObj.filter !== 'string') {
        sendingObj.filter = ''
      }

      if (srcObj.query) {
        if (sendingObj.filter.length !== 0) {
          sendingObj.filter += ' and '
        }
        sendingObj.filter +=
          '(title regex /' +
          srcObj.query +
          '/i or reference regex /' +
          srcObj.query +
          '/i or id eq ' +
          srcObj.query +
          ')'
      }

      if (srcObj['state'] !== 'all') {
        if (sendingObj.filter.length !== 0) {
          sendingObj.filter += ' and '
        }
        sendingObj.filter += "(state.value eq '" + srcObj['state'] + "')"
      }

      if (srcObj['visibility'] !== 'all') {
        if (sendingObj.filter.length !== 0) {
          sendingObj.filter += ' and '
        }
        sendingObj.filter += "(visibility eq '" + srcObj['visibility'] + "')"
      }

      if (sendingObj.filter === '') {
        delete sendingObj.filter
      }

      return sendingObj
    }

    /**
     * Get element from server to get surveys -- no way to just get surveys at this point
     * @param  {Boolean} append if true then append to existing surveys data set
     */
    function getSurveys(append) {
      $scope.loading = true

      if (!append && $scope.data.surveys) {
        $scope.data.surveys = []
        $scope.filters.page = 1
        $scope.filters.pageExhausted = false
        listFilterService.writeFilter($scope.filters)
      } else if (append) {
        $scope.filters.page++
      }

      var options = organiseSearchObj($scope.filters)

      if (!$stateParams.eid) {
        surveyService
          .getAll(options)
          .then(getSuccess, getError)
          .finally(getFinally)
      } else {
        surveyService
          .getByElement($stateParams.eid, options)
          .then(getSuccess, getError)
          .finally(getFinally)
      }

      function getSuccess(data) {
        $scope.data.surveys.push.apply($scope.data.surveys, data)

        // HACK force exhaustion if backend returns too many results (filters broken)
        if (data.length > $scope.filters.top) {
          $scope.filters.pageExhausted = true
        }

        if (data.length < $scope.filters.top) {
          $scope.filters.pageExhausted = true
        }
      }

      function getError() {
        glToast.action('Error retrieving surveys.', 'Retry', getSurveys, {
          target: '.main-content',
          timeout: 5000,
        })
      }

      function getFinally() {
        $scope.loading = false
      }
    }

    /**
     * clone given survey
     * @param  {String} id id of survey to clone
     */
    function clone(id) {
      $scope.processing = true

      surveyService.get(id).then(cloneSuccess, cloneError)

      function cloneSuccess(data) {
        $scope.processing = false

        // set next template
        surveyService.setNextTemplate(data)

        // go to create new survey
        $state.go('app.survey', {
          subscriberId: $scope.data.subscriberId,
          elementId: $scope.data.elementId,
          surveyId: 'new',
        })
      }

      function cloneError() {
        $scope.processing = false
        glToast.action('Error cloning survey.', 'Retry', clone, {
          target: '.main-content',
          timeout: 5000,
        })
      }
    }

    function exportSurveys() {
      if ($stateParams.eid) {
        surveyService.exportAllResponsesByElement($stateParams.eid)
      } else {
        var template = [
          '<gl-dialog class="export-survey-responses__dialog">',
          '<export-survey-responses on-cancel="dialog.cancel()"/>',
          '</gl-dialog>',
        ]
        glDialog.show({
          template: template.join(' '),
        })
      }
    }
  }
})()
