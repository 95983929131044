;(function() {
  'use strict'

  Controller.$inject = ["$state", "$log", "glUtils", "glToast", "bundleService", "stateService", "breadcrumbsService"];
  angular.module('app.core').controller('Bundle', Controller)

  /* @ngInject */
  function Controller(
    $state,
    $log,
    glUtils,
    glToast,
    bundleService,
    stateService,
    breadcrumbsService
  ) {
    var ctrl = this
    var log = $log.create('Bundle')

    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy
    ctrl.save = save

    function onInit() {
      ctrl.id = $state.params.id
      ctrl.state = stateService.define(['loading', 'error', 'ready'])
      breadcrumbsService.addBreadcrumb('Bundle', 'app.bundles')
      if (ctrl.id === 'new') {
        ctrl.bundle = bundleService.spawn()
        ctrl.state.ready()
      } else {
        ctrl.state.loading()
        bundleService
          .load(ctrl.id)
          .then(function(bundle) {
            ctrl.bundle = bundle
            breadcrumbsService.updateCurrentTitle(ctrl.bundle.name)
            ctrl.state.ready()
          })
          .catch(function(error) {
            ctrl.state.error()
            glToast.show('Error loading bundle.')
            log.error(error)
          })
      }
    }

    function onDestroy() {
      ctrl.bundle.revert() // revert any un-saved changes
    }

    function save() {
      var wasNew = ctrl.bundle.isNew()
      ctrl.isSaving = true
      return bundleService
        .save(ctrl.bundle)
        .then(function() {
          if (wasNew) {
            glUtils.changeParams({ id: ctrl.bundle.id })
          }
          glToast.show('Bundle saved.')
        })
        .catch(function(error) {
          glToast.show('Error saving bundle.')
          log.error(error)
        })
        .finally(function() {
          ctrl.isSaving = false
        })
    }
  }
})()
