;(function() {
  'use strict'

  Controller.$inject = ["$state", "glToast", "glUtils", "configService", "breadcrumbsService", "channelService", "stateService"];
  angular.module('app.links').controller('Channels', Controller)

  /* @ngInject */
  function Controller(
    $state,
    glToast,
    glUtils,
    configService,
    breadcrumbsService,
    channelService,
    stateService
  ) {
    var ctrl = this

    ctrl.orders = [
      {
        id: 'created',
        label: 'Created',
        filterKey: 'createdAt',
        sortKey: 'createdAt',
      },
      {
        id: 'updated',
        label: 'Updated',
        filterKey: 'updatedAt',
        sortKey: 'updatedAt',
      },
      {
        id: 'responses',
        label: 'Responses',
        filterKey: 'responseCount',
        sortKey: 'responseCount',
      },
      {
        id: 'exits',
        label: 'Exits',
        filterKey: 'exitCount',
        sortKey: 'exitCount',
      },
      {
        id: 'cost',
        label: 'Cost',
        filterKey: 'totalCost',
        sortKey: 'cost.amount',
      },
    ]

    ctrl.$onInit = onInit
    ctrl.search = search
    ctrl.getSurveyUrl = getSurveyUrl

    function onInit() {
      breadcrumbsService.addBreadcrumb('Channels', 'home')
      ctrl.subscribersById = {}
      ctrl.state = stateService.define(['loading', 'error', 'ready'])
      ctrl.options = {
        query: $state.params.query,
        top: '100',
        order: $state.params.order || 'updated',
        direction: $state.params.direction || 'desc',
        type: $state.params.type || null,
      }
      search(false, true)
    }

    function getOrderById(id) {
      return ctrl.orders.find(function(order) {
        return order.id === id
      })
    }

    function search(more, dontUpdateUrl) {
      if (!dontUpdateUrl) {
        glUtils.changeParams({
          query: ctrl.options.query,
          order: ctrl.options.order,
          direction: ctrl.options.direction,
          type: ctrl.options.type,
        })
      }
      var filters = []
      if (ctrl.options.query) {
        filters.push(
          'id eq ' +
            ctrl.options.query +
            ' or ownerId eq ' +
            ctrl.options.query +
            ' or survey.id eq ' +
            ctrl.options.query
        )
      }
      if (ctrl.options.type) {
        filters.push('type eq ' + ctrl.options.type)
      }
      filters.push('isArchived ne true')

      var order = getOrderById(ctrl.options.order)
      var options = {
        orderBy: order.filterKey + ' ' + ctrl.options.direction,
        top: ctrl.options.top,
      }

      if (more) {
        options.skip = ctrl.channels.length
      } else {
        ctrl.channels = null
      }

      if (filters.length) {
        options.filter = filters.join(' and ')
      }

      ctrl.state.loading()

      channelService
        .list(options)
        .then(function(channels) {
          var set = new Set()
          for (var channel of channels) {
            set.add(channel.id)
          }
          console.log(set)
          channels = _.orderBy(channels, order.sortKey, ctrl.options.direction)
          if (more) {
            ctrl.channels = _.concat(ctrl.channels, channels)
          } else {
            ctrl.channels = channels
          }
          ctrl.state.ready()
        })
        .catch(function() {
          ctrl.state.error()
          glToast.action('Error loading channels.', 'Retry', search)
        })
    }

    function getSurveyUrl(channel) {
      return configService.getSubscriberPortalUrl(
        '/surveys/' + channel.survey.id + '?tab=channels'
      )
    }
  }
})()
