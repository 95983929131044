;(function() {
  'use strict'

  Controller.$inject = ["$state", "$log", "glUtils", "glToast", "externalPanelService", "stateService", "breadcrumbsService"];
  angular.module('app.core').controller('ExternalPanel', Controller)

  /* @ngInject */
  function Controller(
    $state,
    $log,
    glUtils,
    glToast,
    externalPanelService,
    stateService,
    breadcrumbsService
  ) {
    var ctrl = this
    var log = $log.create('ExternalPanel')

    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy
    ctrl.save = save
    ctrl.updateRespondentIdSelection = updateRespondentIdSelection

    function onInit() {
      ctrl.id = $state.params.id
      ctrl.state = stateService.define(['loading', 'error', 'ready'])
      breadcrumbsService.addBreadcrumb('External Panel', 'app.externalPanels')

      if (ctrl.id === 'new') {
        ctrl.panel = externalPanelService.spawn()
        ctrl.state.ready()
      } else {
        ctrl.state.loading()
        externalPanelService
          .get(ctrl.id)
          .then(function(panel) {
            ctrl.panel = panel
            breadcrumbsService.updateCurrentTitle(ctrl.panel.name)
            ctrl.state.ready()
          })
          .catch(function(error) {
            ctrl.state.error()
            glToast.show('Error loading external panel.')
            log.error(error)
          })
      }
    }

    function onDestroy() {
      // revert any un-saved changes
      ctrl.panel.revert()
    }

    function save() {
      var wasNew = ctrl.panel.isNew()
      ctrl.isSaving = true
      return externalPanelService
        .save(ctrl.panel)
        .then(function() {
          if (wasNew) {
            glUtils.changeParams({ id: ctrl.panel.id })
          }
          glToast.show('External panel ' + wasNew ? 'created' : 'updated')
        })
        .catch(function(error) {
          glToast.show('Error saving external panel.')
          log.error(error)
        })
        .finally(function() {
          ctrl.isSaving = false
        })
    }

    function updateRespondentIdSelection(excludeTransformId) {
      _.each(ctrl.panel.transforms, function(transform) {
        if (transform.id === excludeTransformId) return
        transform.isRespondentId = false
      })
    }
  }
})()
