;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.core').config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('app.externalPanels', {
      url: '/external-panel?query&status',
      templateUrl: 'external-panels.html',
      controller: 'ExternalPanels',
      controllerAs: '$ctrl',
    })
  }
})()
