;(function() {
  'use strict'

  ElementSearch.$inject = ["$scope", "$stateParams", "$document", "elementSourcesResource", "subscribersResource", "glToast"];
  angular
    .module('core.element-search')
    .controller('ElementSearch', ElementSearch)

  /* @ngInject */
  function ElementSearch(
    $scope,
    $stateParams,
    $document,
    elementSourcesResource,
    subscribersResource,
    glToast
  ) {
    /**
     * load subscriber
     * TODO: this should be refactored into route resolves once we restructure with resources
     */
    $scope.subscriber = null
    $scope.getSubscriber = function() {
      subscribersResource
        .get({ id: $stateParams.id })
        .success(function(resource) {
          $scope.subscriber = resource
        })
        .error(function() {
          // ... do nothing
        })
    }
    $scope.getSubscriber()

    $scope.placeholder = {
      place: 'eg. Coles Port Melbourne',
      product: 'eg. Bertoli Olive Oil',
      other: 'Type your search code or text',
    }

    $scope.types = [
      { name: 'Place', value: 'place' },
      { name: 'Product', value: 'product' },
      { name: 'Other', value: 'other' },
    ]

    $scope.searchData = {
      type: 'place',
      limit: 5,
    }

    $scope.selectedItem = null

    $scope.queryError = ''

    // unbind enter key close from modal popup directive
    $document.unbind('keydown', $scope.onPopupKeydown)

    // clear results if type is changed
    $scope.$watch('searchData.type', function() {
      $scope.selectedItem = null
      $scope.results = null
      $scope.searchData.query = ''
    })

    /**
     * format text to show in results table
     */
    $scope.subText = function(item) {
      if ($scope.searchData.type === 'place') {
        return item.location.formattedAddress[0] || 'No Address'
      } else {
        return item.brand
      }
    }

    /**
     * search for element sources
     */
    $scope.query = function() {
      if (!$scope.searchData.query) {
        return delete $scope.results
      }

      $scope.queryError = ''

      elementSourcesResource
        .get($scope.searchData)
        .success(function(response) {
          $scope.results = response
        })
        .error(function(error) {
          $scope.queryError = error
          glToast.action(
            'Error searching for elements',
            'Retry',
            $scope.query,
            {
              target: '.main-content',
            }
          )
        })
    }

    /**
     * set selected element source item
     */
    $scope.select = function(item) {
      if (!item.isSubscriberOwned) {
        $scope.selectedItem = item
      }
    }

    /**
     * add element to claimElements array with correct object structure
     */
    var claimElement
    $scope.addElement = function(item) {
      if (item.elementId) {
        claimElement = { elementId: item.elementId }
      } else if (item.id) {
        claimElement = { elementItemId: item.id, type: 'place' }
      } else {
        glToast.show('Element cannot be added as no ID exists', {
          target: '.main-content',
        })
      }
    }

    /**
     * create element from selected item and add it to the subscriber
     */
    $scope.add = function() {
      if (!$scope.subscriber) {
        return glToast.action('No subscriber loaded', 'Retry', $scope.add, {
          target: '.main-content',
        })
      }
      if (!$scope.selectedItem) {
        glToast.show('No element is selected', {
          target: '.main-content',
        })
      }

      $scope.addElement($scope.selectedItem)
      $scope.subscriber.claimElements = [claimElement]

      $scope.subscriber
        .$claimElements()
        .success(function() {
          glToast.show('Element added successfully', {
            target: '.main-content',
          })
          $scope.cb()
        })
        .error(function() {
          return glToast.action(
            'Could not claim element.',
            'Retry',
            $scope.add,
            {
              target: '.main-content',
            }
          )
        })

      $scope.selectedItem = null
    }
  }
})()
