;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.core').config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('app.plans', {
      url: '/plans?types&query',
      params: {
        types: { dynamic: true },
        query: { dynamic: true },
      },
      templateUrl: 'plans.html',
      controller: 'Plans',
      controllerAs: '$ctrl',
    })
  }
})()
