;(function() {
  'use strict'

  Controller.$inject = ["glUtils", "$state", "breadcrumbsService", "plansService", "glToast"];
  angular.module('app.core').controller('Plans', Controller)

  /* @ngInject */
  function Controller(
    glUtils,
    $state,
    breadcrumbsService,
    plansService,
    glToast
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.isVisible = isVisible
    ctrl.updateFilterParams = updateFilterParams

    ctrl.PlanTypeOptions = [
      { value: 'DRAFT', label: 'Draft' },
      { value: 'SAAS', label: 'Saas' },
      { value: 'CUSTOM', label: 'Custom' },
      { value: 'LEGACY', label: 'Legacy' },
    ]

    function onInit() {
      var types = $state.params.types
      var query = $state.params.query
      ctrl.filter = {
        query: query || '',
        types: types ? types.split(',') : ['CUSTOM', 'DRAFT', 'SAAS'],
      }

      breadcrumbsService.addBreadcrumb('Plans', 'home')
      ctrl.loading = true
      plansService
        .list()
        .then(function(plans) {
          ctrl.plans = plans
          ctrl.loading = false
        })
        .catch(function() {
          glToast.show('Could not load plans.')
          ctrl.loading = false
        })
    }

    function updateFilterParams() {
      var types = ctrl.filter.types
        .slice()
        .sort()
        .join(',')
      var isDefaultTypes = types === 'CUSTOM,DRAFT,SAAS'
      glUtils.changeParams({
        query: ctrl.filter.query,
        types: !isDefaultTypes ? types : null,
      })
    }

    function isVisible(plan) {
      var query = ctrl.filter.query
      var types = ctrl.filter.types

      if (
        query &&
        plan.id !== query &&
        !plan.name.toLowerCase().includes(query.toLowerCase())
      ) {
        return false
      }

      if (types.indexOf(plan.type) === -1) {
        return false
      }

      return true
    }
  }
})()
