;(function() {
  'use strict'

  Controller.$inject = ["glDialog"];
  angular.module('core.manageKits').component('manageKits', {
    controller: Controller,
    templateUrl: 'manage-kits.html',
    bindings: {
      kits: '<',
      onAdd: '&',
      onRemove: '&',
    },
  })

  /* @ngInject */
  function Controller(glDialog) {
    var ctrl = this

    ctrl.showAddKitDialog = showAddKitDialog

    function showAddKitDialog() {
      glDialog.show({ templateUrl: 'add-kit-dialog.html' }).then(function(kit) {
        ctrl.onAdd({ $kit: kit })
      })
    }
  }
})()
