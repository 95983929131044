;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.hellocloud', []).config(config)

  // --------------------

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('app.hellocloud', {
      url: '/hellocloud',
      templateUrl: 'hello-cloud.template.html',
      controller: 'HelloCloud',
    })
  }
})()
