;(function() {
  'use strict'

  DashboardConfig.$inject = ["$stateProvider"];
  angular.module('app.dashboard', []).config(DashboardConfig)

  /* @ngInject */
  function DashboardConfig($stateProvider) {
    $stateProvider.state('app.dashboard', {
      url: '/dashboard',
      templateUrl: 'dashboard.html',
      controller: 'Dashboard',
      controllerAs: '$ctrl',
    })
  }
})()
