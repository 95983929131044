;(function() {
  'use strict'

  PostController.$inject = ["$scope", "$stateParams", "breadcrumbsService", "$timeout", "postService", "glToast"];
  angular.module('app.post').controller('PostController', PostController)

  /* @ngInject */
  function PostController(
    $scope,
    $stateParams,
    breadcrumbsService,
    $timeout,
    postService,
    glToast
  ) {
    $scope.loading = false
    $scope.replyActive = false

    $scope.data = {}

    // feedback reply object
    $scope.reply = {
      message: '',
    }

    $scope.getPost = getPost
    $scope.commentCircleText = commentCircleText
    $scope.postHeaderText = postHeaderText
    $scope.postIcon = postIcon
    $scope.togglePostVisibility = togglePostVisibility
    $scope.toggleCommentVisibility = toggleCommentVisibility
    $scope.openUrl = openUrl

    activate()

    /**
     * initialise
     */
    function activate() {
      getPost()
      breadcrumbsService.addBreadcrumb('Post', 'app.posts')
    }

    /**
     * get user from server
     */
    function getPost() {
      $scope.loading = true
      postService.get($stateParams.id).then(getSuccess, getError)

      function getSuccess(data) {
        $scope.loading = false
        $scope.data.post = data
        breadcrumbsService.updateCurrentTitle(data.id)

        if ('accessToken' in $scope.data.post) {
          $scope.postAccessUrl = postService.getPostAccessURL(
            $stateParams.id,
            $scope.data.post.accessToken
          )
        }
      }

      function getError() {
        $scope.loading = false
        glToast.action('Error retrieving post.', 'Retry', getPost, {
          target: '.main-content',
          timeout: 5000,
        })
      }
    }

    /**
     * determine post icon class(s)
     * @param  {Object} post post to generate icon class(s) for
     */
    function postIcon(post) {
      var iconTypes = {
        feedback: 'gi-chat blue',
        idea: 'gi-idea blue',
        complaint: 'gi-complaint blue',
        praise: 'gi-praise blue',
        suggestion: 'gi-suggestion blue',
        request: 'gi-request blue',
        question: 'gi-question blue',
        'survey-response': 'gi-survey green',
      }

      return iconTypes[post.type] || 'feedback'
    }

    /**
     * generate post header text
     * @param  {Object} type type
     * @return {String}             interpolated post header title
     */
    function postHeaderText(type) {
      var textHash = {
        feedback: ' has given feedback to ',
        idea: ' has given an idea for ',
        complaint: ' has given a complaint for ',
        praise: ' has given some praise for ',
        suggestion: ' has a suggestion for ',
        request: ' has a request for ',
        question: ' has a question for ',
      }

      return textHash[type]
    }

    /**
     * get first two characters from users name to put into comment colour circle
     * @param  {Object} comment the comment to create the text for
     * @return {String} two character string with first character uppercase
     */
    function commentCircleText(comment) {
      return (
        comment.creator.name[0].toUpperCase() +
        comment.creator.name[1].toLowerCase()
      )
    }

    /**
     * toggle a posts public visibility
     */
    function togglePostVisibility() {
      var action = $scope.data.post.hidden ? 'unhide' : 'hide'
      $scope.data.post.hidden = !$scope.data.post.hidden
      postService[action + 'Post']($scope.data.post.id).then(onSuccess, onError)

      function onSuccess() {
        if (action === 'hide') {
          $scope.data.post.state = 'closed'
        }
      }

      function onError() {
        $scope.data.post.hidden = !$scope.data.post.hidden
        glToast.show('Error: could not ' + action + ' post', {
          target: '.main-content',
        })
      }
    }

    /**
     * toggle comment's public visibility
     * @param  {Object} comment comment
     */
    function toggleCommentVisibility(comment) {
      var action = comment.hidden ? 'unhide' : 'hide'
      comment.hidden = !comment.hidden
      postService[action + 'Comment']($scope.data.post.id, comment.id).then(
        angular.noop,
        onError
      )

      function onError() {
        comment.hidden = !comment.hidden
        glToast.show('Error: could not ' + action + ' comment', {
          target: '.main-content',
        })
      }
    }

    /**
     * @name openUrl
     * @description open URL - replace if current domain, else open in new tab
     * @param  {String} url URL to open
     * @return {[type]}     [description]
     */
    function openUrl(url) {
      if (url.match(/admin.*.glowfeed.com/)) {
        window.open(url, '_self', '', true)
      } else {
        window.open(url, '_blank')
      }
    }
  }
})()
