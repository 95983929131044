;(function() {
  'use strict'

  InteractionsController.$inject = ["$scope", "$stateParams", "$filter", "breadcrumbsService", "interactionService", "glDialog", "glToast"];
  angular
    .module('app.interactions')
    .controller('InteractionsController', InteractionsController)

  /* @ngInject */
  function InteractionsController(
    $scope,
    $stateParams,
    $filter,
    breadcrumbsService,
    interactionService,
    glDialog,
    glToast
  ) {
    $scope.loading = false

    $scope.interactionsData = {}

    $scope.data = {
      subscriberId: $stateParams.sid,
      elementId: $stateParams.eid,
    }

    // filters
    // $scope.filters = {
    //     type: 'all',
    //     top: 50,
    //     page: 1,
    //     pageExhausted: false,
    //     orderby: 'title',
    //     order: 'asc',
    //     keyword: ''
    // };

    $scope.getInteractions = getInteractions
    // $scope.getMore = getMore;

    activate()

    /**
     * initialise
     */
    function activate() {
      getInteractions()
      var parentState = $stateParams.sid
        ? 'app.subscriber'
        : $stateParams.eid
          ? 'app.element'
          : 'home'
      breadcrumbsService.addBreadcrumb('Interactions', parentState)
    }

    /**
     * Build search object from filters
     * @param  {Object} srcObj filter object to build upon
     * @return {Object}
     */
    // function organiseSearchObj (srcObj) {
    //     var sendingObj = {};

    //     // top, page, orderby
    //     sendingObj.top = srcObj.top;
    //     sendingObj.skip = srcObj.top * (srcObj.page - 1);
    //     sendingObj.orderby = srcObj.orderby + ' ' + srcObj.order;

    //     if ($stateParams.sid) {
    //         sendingObj.subscriberId = $stateParams.sid;
    //     }

    //     // filter
    //     var keyword = srcObj.keyword;
    //     if (keyword) {
    //         sendingObj.filter = 'title regex /' + keyword + '/i or email regex /' + keyword + '/i';
    //     }

    //     // type
    //     // TODO this does not seem to work
    //     if (srcObj.type !== 'all') {
    //         if (typeof sendingObj.filter === 'string') {
    //             sendingObj.filter += ' and about.type eq ' + srcObj.type;
    //         } else {
    //             sendingObj.filter = 'about.type eq ' + srcObj.type;
    //         }
    //     }

    //     return sendingObj;
    // }

    /**
     * Get interactions from server
     */
    function getInteractions() {
      $scope.loading = true
      // if (!append && $scope.data.interactions) {
      //     $scope.data.interactions = [];
      //     $scope.filters.page = 1;
      //     $scope.filters.pageExhausted = false;
      // }

      var action
      var param

      if ($stateParams.sid) {
        action = 'getBySubscriber'
        param = $stateParams.sid
      } else if ($stateParams.eid) {
        action = 'getByElement'
        param = $stateParams.eid
      } else {
        $scope.loading = false
        return glDialog.alert(
          '',
          'Element or Subscriber ID required, GET all not supported yet.',
          {
            parent: '.main-content',
          }
        )
      }

      interactionService[action](param).then(getSuccess, getError)

      function getSuccess(data) {
        $scope.loading = false
        $scope.interactionsData.groupedInteractions = $filter(
          'groupInteractions'
        )(data)
      }

      function getError() {
        $scope.loading = false
        glToast.action(
          'Error retrieving interactions.',
          'Retry',
          getInteractions,
          {
            target: '.main-content',
            timeout: 5000,
          }
        )
      }
    }

    /**
     * Get more interactions from server
     */
    // function getMore () {
    //     $scope.filters.page++;
    //     $scope.getInteractions(true);
    // }
  }
})()
