;(function() {
  'use strict'

  Controller.$inject = ["glToast", "kitsService"];
  angular.module('core.kitsList').component('kitsList', {
    controller: Controller,
    templateUrl: 'kits-list.html',
    bindings: {
      onLoaded: '&',
      onSelect: '&',
    },
  })

  /* @ngInject */
  function Controller(glToast, kitsService) {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      fetch()
    }

    function fetch() {
      ctrl.isLoading = true
      return kitsService
        .getAll()
        .then(function(kits) {
          ctrl.kits = kits
          ctrl.isLoading = false
        })
        .catch(function() {
          glToast.show('Error fetching Survey Kits')
        })
    }
  }
})()
