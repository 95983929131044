;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$compile", "$filter"];
  angular.module('app.hellocloud').controller('HelloCloud', Controller)

  /* @ngInject */
  function Controller($scope, $compile, $filter) {
    $scope.data = {}

    // methods
    $scope.buildCloud = buildCloud

    function buildCloud() {
      if (!$scope.data.words) {
        return
      }

      $scope.data.filteredWords = $filter('helloCloudWords')($scope.data.words)

      var element = angular.element(document.querySelector('.wordcloud-inner'))
      var newDirective = angular.element(
        '<hellocloud words="data.filteredWords" height="500""></hellocloud>'
      )
      element.empty().append(newDirective)
      $compile(newDirective)($scope)
    }
  }
})()
