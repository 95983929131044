;(function() {
  'use strict'

  UserService.$inject = ["$q", "$location", "$state", "usersResource", "glAuthService"];
  angular.module('core.services').service('userService', UserService)

  function UserService($q, $location, $state, usersResource, glAuthService) {
    var user
    var requestedUrl // url user requested before logging in

    return {
      get: get,
      hasCredentials: hasCredentials,
      login: login,
      logout: logout,
      redirectToRequestedUrl: redirectToRequestedUrl,
      saveRequestedUrl: saveRequestedUrl,
    }

    /**
     * get user object
     * @return {Object} user
     */
    function get() {
      return user
    }

    /**
     * @name hasCredentials
     * @desc check if user has credentials to attempt a login
     * @return {Boolean} whether the user has credentials
     */
    function hasCredentials() {
      return glAuthService.hasCredentials()
    }

    /**
     * @name login
     * @desc log user in
     * @param {Object} auth the auth data
     * @return {Promise} promise to log in
     */
    function login(auth) {
      return glAuthService
        .login(auth, glAuthService.Roles.ADMIN)
        .then(function(userData) {
          user = userData
          return user
        })
    }

    /**
     * @name logout
     * @description log user out
     * @return {Promise} Promise to log out
     */
    function logout() {
      return glAuthService.logout().then(function() {
        user = null
        $state.go('login')
      })
    }

    /**
     * @name redirectToRequestedUrl
     * @description Redirect to requested url if it exists -- called on login success from login controller
     */
    function redirectToRequestedUrl() {
      if (requestedUrl) {
        $location.url(requestedUrl)
        requestedUrl = ''
      } else {
        $state.go('app.dashboard')
      }
    }

    /**
     * @name saveRequesterUrl
     * @description Save requested url if user is not logged in, to be routed to after succesful login
     */
    function saveRequestedUrl() {
      if ($location.path().toLowerCase() !== '/') {
        requestedUrl = $location.url()
      }
    }
  }
})()
