;(function() {
  'use strict'

  UserConfig.$inject = ["$stateProvider"];
  angular.module('app.user', []).config(UserConfig)

  /* @ngInject */
  function UserConfig($stateProvider) {
    $stateProvider.state('app.user', {
      url: '/user/:id?sid',
      templateUrl: 'user.template.html',
      controller: 'UserController',
    })
  }
})()
