;(function() {
  'use strict'

  angular.module('core.filters').filter('urlEncode', urlEncodeFilter)

  /**
   * @name urlEncode
   * @description uri encode a string for use in anchor or src tag
   */
  function urlEncodeFilter() {
    return window.encodeURIComponent
  }
})()
