;(function() {
  'use strict'

  Controller.$inject = ["$state", "breadcrumbsService"];
  angular.module('app.contentGroups').controller('ContentGroups', Controller)

  /* @ngInject */
  function Controller($state, breadcrumbsService) {
    var ctrl = this

    ctrl.goToContentGroup = goToContentGroup

    ctrl.$onInit = onInit

    function onInit() {
      breadcrumbsService.addBreadcrumb('ContentGroup', 'home')
    }

    function goToContentGroup(contentGroup) {
      $state.go('app.contentGroup', { id: contentGroup.id })
    }
  }
})()
