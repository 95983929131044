;(function() {
  'use strict'

  Controller.$inject = ["$sce", "glDialog", "breadcrumbsService", "configService", "statsService", "acquiredPublicationsExporter", "surveyTransactionsExporter", "subscriberReportExporter", "creditTransactionsExporter", "surveyReportExporter", "channelReportExporter"];
  angular.module('app.dashboard').controller('Dashboard', Controller)

  /* ngInject */
  function Controller(
    $sce,
    glDialog,
    breadcrumbsService,
    configService,
    statsService,
    acquiredPublicationsExporter,
    surveyTransactionsExporter,
    subscriberReportExporter,
    creditTransactionsExporter,
    surveyReportExporter,
    channelReportExporter
  ) {
    var ctrl = this
    var logzUrl =
      'https://app.logz.io/goto/cd1c610003a93dba4a156c1d2fafeb17?shareToken=330121d7-321c-40ad-9b61-a2004faa8f4c'

    ctrl.$onInit = onInit
    ctrl.showAcquiredPublicationsExporter = acquiredPublicationsExporter.show
    ctrl.showSurveyTransactionsExporter = surveyTransactionsExporter.show
    ctrl.showSubscriberReportExporter = subscriberReportExporter.show
    ctrl.showCreditTransactionsExporter = creditTransactionsExporter.show
    ctrl.showSurveyReportExporter = surveyReportExporter.show
    ctrl.showChannelReportExporter = channelReportExporter.show

    function onInit() {
      ctrl.canShowLogz = configService.isProduction()
      ctrl.logzFrameUrl = $sce.trustAsResourceUrl(logzUrl)

      if (!ctrl.canShowLogz) {
        loadStats()
        breadcrumbsService.addBreadcrumb('Dashboard', 'home')
      }
    }

    function loadStats() {
      ctrl.loading = true
      statsService
        .getDashboardStats()
        .then(
          function(stats) {
            ctrl.stats = stats
          },
          function(error) {
            glDialog.alert('', 'Error fetching stats\n\n' + error.message, {
              parent: '.main-content',
            })
          }
        )
        .finally(function() {
          ctrl.loading = false
        })
    }
  }
})()
