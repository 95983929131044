;(function() {
  'use strict'

  InteractionsConfig.$inject = ["$stateProvider"];
  angular.module('app.interactions', []).config(InteractionsConfig)

  /* @ngInject */
  function InteractionsConfig($stateProvider) {
    $stateProvider.state('app.interactions', {
      url: '/interactions?eid&sid',
      templateUrl: 'interactions-list.template.html',
      controller: 'InteractionsController',
    })
  }
})()
