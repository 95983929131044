;(function() {
  'use strict'

  Controller.$inject = ["$state", "api", "glToast", "glUtils", "breadcrumbsService", "stateService", "ExternalPanel"];
  angular.module('app.links').controller('ExternalPanels', Controller)

  /* @ngInject */
  function Controller(
    $state,
    api,
    glToast,
    glUtils,
    breadcrumbsService,
    stateService,
    ExternalPanel
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.search = search

    ctrl.statusOptions = [
      {
        label: 'Active',
        value: 'ACTIVE',
      },
      {
        label: 'Inactive',
        value: 'INACTIVE',
      },
    ]
    ctrl.statusOptionsByValue = _.mapKeys(ctrl.statusOptions, 'value')

    function onInit() {
      breadcrumbsService.addBreadcrumb('External Panels', 'home')
      ctrl.state = stateService.define(['loading', 'error', 'ready'])
      ctrl.options = {
        query: $state.params.query,
        status: $state.params.status,
        top: '20',
      }
      search(false, true)
    }

    function search(more, dontUpdateUrl) {
      if (!dontUpdateUrl) {
        glUtils.changeParams({
          query: ctrl.options.query,
          status: ctrl.options.status,
        })
      }
      var filters = []
      if (ctrl.options.query) {
        filters.push(
          'id eq ' +
            ctrl.options.query +
            ' or name eq ' +
            ctrl.options.query +
            ' or desc eq ' +
            ctrl.options.query
        )
      }
      if (ctrl.options.status) {
        filters.push('status eq ' + ctrl.options.status)
      }

      var options = {
        top: ctrl.options.top,
      }

      if (more) {
        options.skip = ctrl.externalPanels.length
      } else {
        ctrl.externalPanels = null
      }

      if (filters.length) {
        options.filter = filters.join(' and ')
      }

      ctrl.state.loading()

      api.externalPanels
        .get(options)
        .then(function(data) {
          var externalPanels = _.map(data, function(data) {
            return new ExternalPanel().deserialize(data)
          })
          if (more) {
            ctrl.externalPanels = _.concat(ctrl.externalPanels, externalPanels)
          } else {
            ctrl.externalPanels = externalPanels
          }
          ctrl.state.ready()
        })
        .catch(function(error) {
          console.error(error)
          ctrl.state.error()
          glToast.action('Error loading external panels.', 'Retry', search)
        })
    }
  }
})()
