;(function() {
  'use strict'

  ElementConfig.$inject = ["$stateProvider"];
  angular.module('app.element', []).config(ElementConfig)

  /* @ngInject */
  function ElementConfig($stateProvider) {
    $stateProvider.state('app.element', {
      url: '/element/:elementId?subscriberId',
      templateUrl: 'element.template.html',
      controller: 'ElementController',
    })
  }
})()
