;(function() {
  'use strict'

  formatUserRoles.$inject = ["glAuthService"];
  angular.module('core.filters').filter('formatUserRoles', formatUserRoles)

  /* @ngInject */
  function formatUserRoles(glAuthService) {
    var Roles = glAuthService.Roles

    return function(roles) {
      // Get unique roles only
      roles = _.uniq(roles)

      // Remove ROLE_USER, which is no longer in use
      _.remove(roles, function(role) {
        return role === Roles.USER
      })

      // If we end up with no more roles (e.g. we began with ROLE_USER only) then handle it
      var len = roles.length
      if (len === 0) {
        return 'Role undefined'
      }

      // Make everything else more readable
      for (var i = 0; i < len; i++) {
        switch (roles[i]) {
          case Roles.EMAIL:
            roles[i] = 'Email'
            break
          case Roles.PHONE:
            roles[i] = 'Phone'
            break
          case Roles.SUBSCRIBER:
            roles[i] = 'Subscriber'
            break
          case Roles.SUBSCRIBER_SUPPORT:
            roles[i] = 'Subscriber Support'
            break
          case Roles.ADMIN:
            roles[i] = 'Admin'
            break
        }
      }

      return roles.join(', ')
    }
  }
})()
