;(function() {
  'use strict'

  textToWordsFilter.$inject = ["stopWords"];
  angular
    .module('app.hellocloud')
    /**
     * @name stopWords
     * @description list of words that should not be used for word cloud
     */
    .constant('stopWords', [
      'a',
      'able',
      'about',
      'across',
      'after',
      'all',
      'almost',
      'also',
      'am',
      'among',
      'an',
      'and',
      'any',
      'are',
      'as',
      'at',
      'be',
      'because',
      'been',
      'but',
      'by',
      'can',
      'cannot',
      'could',
      'dear',
      'did',
      'do',
      'does',
      'either',
      'else',
      'ever',
      'every',
      'for',
      'from',
      'get',
      'got',
      'had',
      'has',
      'have',
      'he',
      'her',
      'hers',
      'him',
      'his',
      'how',
      'however',
      'i',
      'if',
      'in',
      'into',
      'is',
      'it',
      'its',
      'just',
      'least',
      'let',
      'like',
      'likely',
      'may',
      'me',
      'might',
      'most',
      'must',
      'my',
      'neither',
      'no',
      'nor',
      'not',
      'of',
      'off',
      'often',
      'on',
      'only',
      'or',
      'other',
      'our',
      'own',
      'rather',
      'said',
      'say',
      'says',
      'she',
      'should',
      'since',
      'so',
      'some',
      'than',
      'that',
      'the',
      'their',
      'them',
      'then',
      'there',
      'these',
      'they',
      'this',
      'tis',
      'to',
      'too',
      'twas',
      'us',
      'wants',
      'was',
      'we',
      'were',
      'what',
      'when',
      'where',
      'which',
      'while',
      'who',
      'whom',
      'why',
      'will',
      'with',
      'would',
      'yet',
      'you',
      'your',
    ])
    .filter('helloCloudWords', textToWordsFilter)

  /**
   * @name toWords
   * @description filters a text string into an object to be used by the wordCloud directive
   *
   * @param {Array} stopWords array of words to be excluded from calculations
   * @returns {Array} array of unique words with their respective word count
   *
   * @ngInject
   */
  function textToWordsFilter(stopWords) {
    return function(input) {
      var limit = 50

      var frequencies = {}
      var stopWordsHash = {}
      var output

      // parse stopwords to associative-array / hash object for O(1) lookup
      for (var i = 0, len = stopWords.length; i < len; i++) {
        stopWordsHash[stopWords[i]] = true
      }

      // process input
      addWords(input.split(' '))

      // convert to array
      output = _.map(frequencies, function(value, key) {
        value.word = key
        return value
      })

      // sort and limit
      output = _.sortBy(output, function(word) {
        return -word.total
      }).slice(0, limit)

      return output

      /**
       * Clean unwanted characters
       */
      // function cleanString (string) {
      //     return string.replace(/[0-9\.,-\/#!$@%\^&\*;:{}=\-_`~()?]/g, '')
      //                     .replace(/\s{2,}/g, ' ')
      //                     .replace(/[ \t]+$/g, '');
      // }

      /**
       * Add words to the frequency associative array / hash
       */
      function addWords(words) {
        var wordCounted = {}
        for (var i = 0, len = words.length; i < len; i++) {
          var word = words[i].toLowerCase()

          // if not a stop word, add it to frequencies
          if (!stopWordsHash[word]) {
            frequencies[word] = frequencies[word] || {}
            frequencies[word].total = frequencies[word].total || 0
            frequencies[word].total++
            if (!wordCounted[word]) {
              wordCounted[word] = true
            }
          }
        }
      }
    }
  }
})()
