;(function() {
  'use strict'

  timeAgoFilter.$inject = ["$log"];
  angular.module('core.filters').filter('timeago', timeAgoFilter)

  /**
   * @name timeago
   * @description converts a time from the server into time ago string using moment
   * @param {String} time of format 'YYYY-MM-DD HH:mm:ss Z'
   * @returns {String} how long ago the time occured as a nicely formatted string
   */
  /* @ngInject */
  function timeAgoFilter($log) {
    return function(isoDateString, noSuffix, timezone) {
      var method = timezone && timezone === 'UTC' ? moment.utc : moment
      var date = method(isoDateString)
      if (!date.isValid()) {
        $log.error(
          'timeAgo filter requires ISO 8601 formatted strings (other formats will break stuff)'
        )
      }
      return date.fromNow(noSuffix)
    }
  }
})()
