;(function() {
  'use strict'

  ClaimController.$inject = ["$scope", "$state", "$stateParams", "$location", "breadcrumbsService", "claimService", "subscriberService", "glToast"];
  angular.module('app.post').controller('ClaimController', ClaimController)

  /* @ngInject */
  function ClaimController(
    $scope,
    $state,
    $stateParams,
    $location,
    breadcrumbsService,
    claimService,
    subscriberService,
    glToast
  ) {
    $scope.claim = {
      elementId: $stateParams.eid,
      elementTitle: $stateParams.etitle,
      elementSubTitle: $stateParams.esubtext,
      userId: $stateParams.uid,
      userName: $stateParams.ufullname || $stateParams.fname,
      userEmail: $stateParams.uemail,
      userPostId: getPostId($stateParams.upost),
      subscriberId: $stateParams.sid || null,
      formName: $stateParams.fname,
      formPhone: $stateParams.fphone,
    }

    $scope.acceptClaim = acceptClaim
    $scope.rejectClaim = rejectClaim

    activate()

    /**
     * initialise
     */
    function activate() {
      breadcrumbsService.addBreadcrumb('Claim', 'app.claim')

      if (!$scope.claim.subscriberId) {
        // create new subscriber object
        $scope.subscriber = {
          name: $scope.claim.elementTitle,
          phoneNumber: $scope.claim.formPhone,
        }

        $scope.claimUser = {
          id: $scope.claim.userId,
          email: $scope.claim.userEmail,
          name: $scope.claim.userName,
        }
      } else {
        getSubscriber($scope.claim.subscriberId)
      }
    }

    function getSubscriber(id) {
      $scope.loading = true
      subscriberService
        .get(id)
        .then(getSuccess, getError)
        .finally(getFinished)

      function getSuccess(data) {
        $scope.subscriber = data
      }

      function getError() {
        glToast.action('Error retrieving subscriber.', 'Retry', getSubscriber, {
          target: '.main-content',
          timeout: 5000,
        })
      }

      function getFinished() {
        $scope.loading = false
      }
    }

    function acceptClaim() {
      $scope.processing = true

      if ($scope.claim.subscriberId) {
        claimService
          .claimElements($scope.claim.subscriberId, $scope.claim.elementId)
          .then(onSuccess, onError)
      } else {
        claimService
          .claim($scope.subscriber, $scope.claimUser, $scope.claim.elementId)
          .then(onSuccess, onError)
      }

      function onSuccess(data) {
        $scope.processing = false
        glToast.show('Claim Successfull', { target: '.gl-content' })
        $state.go('app.subscriber', {
          id: $scope.claim.subscriberId || data.id,
        })
      }

      function onError() {
        $scope.processing = false
        glToast.action('Error claiming element.', 'Retry', acceptClaim, {
          target: '.main-content',
          timeout: 5000,
        })
      }
    }

    function rejectClaim() {
      glToast.show('Claim Rejected', { target: '.gl-content' })
      $state.go('app.post', { id: $scope.claim.userPostId })
    }

    function getPostId(url) {
      return url.substr(-36)
    }
  }
})()
