;(function() {
  'use strict'

  Config.$inject = ["$stateProvider"];
  angular.module('app.core').config(Config)

  /* @ngInject */
  function Config($stateProvider) {
    $stateProvider.state('app.bundle', {
      url: '/bundles/:id',
      templateUrl: 'bundle.html',
      controller: 'Bundle',
      controllerAs: '$ctrl',
    })
  }
})()
