;(function() {
  'use strict'

  Controller.$inject = ["$q", "$state", "glToast", "glUtils", "glDialog", "breadcrumbsService", "questionFactory", "questionTemplateFactory", "questionTemplatesService"];
  angular
    .module('app.question-template')
    .controller('QuestionTemplate', Controller)

  /* @ngInject */
  function Controller(
    $q,
    $state,
    glToast,
    glUtils,
    glDialog,
    breadcrumbsService,
    questionFactory,
    questionTemplateFactory,
    questionTemplatesService
  ) {
    var ctrl = this

    ctrl.save = save
    ctrl.select = select
    ctrl.addVariation = addVariation
    ctrl.isLocked = isLocked

    ctrl.$onInit = onInit

    function onInit() {
      breadcrumbsService.addBreadcrumb(
        'Question Template',
        'app.question-templates'
      )
      getQuestionInfo()
      getTemplate()
    }

    function getQuestionInfo() {
      var Types = questionFactory.Question.Types
      ctrl.questionTypes = questionFactory.getInfo([
        Types.MULTI_CHOICE,
        Types.MULTI_SELECT,
        Types.SINGLE_TEXT,
        Types.TEXT,
        Types.SCAN,
        Types.NPS,
        Types.MOOD,
        Types.IMAGE,
      ])
    }

    function getTemplate() {
      if ($state.params.id === 'new') {
        ctrl.template = $state.params.source || questionTemplateFactory.create()
        return
      }

      ctrl.loading = true

      questionTemplatesService
        .get($state.params.id)
        .then(function(template) {
          ctrl.template = template
          ctrl.selectedIndex = 0
          ctrl.selected = _.first(template.questions)
        })
        .catch(function() {
          ctrl.error = true
        })
        .finally(function() {
          ctrl.loading = false
        })
    }

    function select(question) {
      ctrl.selectedIndex = _.indexOf(ctrl.template.questions, question)
      ctrl.selected = question
    }

    function addVariation() {
      ctrl.template.addVariation()
      select(_.last(ctrl.template.questions))
    }

    function save(showSavedToast) {
      return $q(function(resolve, reject) {
        ctrl.showErrors = false

        ctrl.template
          .save()
          .then(function() {
            ctrl.selected = ctrl.template.questions[ctrl.selectedIndex]
            updateIdParam()
            resolve()
            if (showSavedToast) {
              glToast.show('Template Saved')
            }
          })
          .catch(function(err) {
            var errors = []

            if (err.errors) {
              ctrl.showErrors = true
              return
            }

            if (err.status === 409) {
              errors.push(
                'A field in your template already exists. Please try again.'
              )
            } else {
              errors.push(
                'There was an issue saving your template. Please try again.'
              )
            }

            if (err.data.message) {
              errors.push(err.data.message)
            }

            glDialog.alert('Error', errors.join('\n\n'))
            reject()
          })
      })
    }

    function isLocked(question) {
      return (
        question.state === questionFactory.States.PUBLISHED ||
        question.state === questionFactory.States.ARCHIVED
      )
    }

    function updateIdParam() {
      if ($state.params.id === 'new' && ctrl.template.id) {
        glUtils.changeParams({ id: ctrl.template.id })
      }
    }
  }
})()
