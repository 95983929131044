;(function() {
  'use strict'

  compileHtml.$inject = ["$compile"];
  angular.module('core.compileHtml').directive('compileHtml', compileHtml)

  /* @ngInject */
  function compileHtml($compile) {
    return {
      restrict: 'A',
      link: link,
    }

    function link(scope, element, attrs) {
      scope.$watch(attrs.compileHtml, doCompile)

      function doCompile() {
        var htmlString = scope.$eval(attrs.compileHtml)
        element.html(htmlString)
        $compile(element.contents())(scope)
      }
    }
  }
})()
