;(function() {
  'use strict'

  postsConfig.$inject = ["$stateProvider"];
  angular.module('app.posts', []).config(postsConfig)

  /* @ngInject */
  function postsConfig($stateProvider) {
    $stateProvider.state('app.posts', {
      url: '/posts?uid&eid&sid&filters',
      templateUrl: 'posts.template.html',
      controller: 'PostsController',
    })
  }
})()
