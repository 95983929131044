;(function() {
  'use strict'

  Controller.$inject = ["$state", "$log", "$q", "glUtils", "glToast", "breadcrumbsService", "contentGroupsService", "kitsService", "userService"];
  angular.module('app.contentGroup').controller('ContentGroup', Controller)

  /* @ngInject */
  function Controller(
    $state,
    $log,
    $q,
    glUtils,
    glToast,
    breadcrumbsService,
    contentGroupsService,
    kitsService,
    userService
  ) {
    var ctrl = this
    var log = $log.create('ContentGroup')

    ctrl.save = save
    ctrl.addKit = addKit
    ctrl.removeKit = removeKit

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.id = $state.params.id
      breadcrumbsService.addBreadcrumb('Content Group', 'app.contentGroup')
      ctrl.isLoading = true
      getContentGroup()
        .then(getKits)
        .finally(function() {
          ctrl.isLoading = false
        })
    }

    function getContentGroup() {
      return contentGroupsService
        .get(ctrl.id)
        .then(function(contentGroup) {
          ctrl.contentGroup = contentGroup
          ctrl.originalContentGroup = _.cloneDeep(ctrl.contentGroup)
          breadcrumbsService.updateCurrentTitle(ctrl.contentGroup.name)
        })
        .catch(function(error) {
          log.error(error)
          glToast.show('Error fetching Content Group')
        })
    }

    function getKits() {
      ctrl.kits = []
      ctrl.kitsPrevious = []
      if (ctrl.contentGroup.isNew()) {
        return $q.resolve()
      }
      return kitsService
        .getByContentGroup(ctrl.id)
        .then(function(kits) {
          ctrl.kits = kits
          ctrl.kitsPrevious = _.cloneDeep(ctrl.kits)
        })
        .catch(function(error) {
          log.error(error)
          glToast.show('Error fetching Survey Kits for Content Group')
        })
    }

    function addKit(kit) {
      ctrl.kits.push(kit)
    }

    function removeKit(kit) {
      _.remove(ctrl.kits, kit)
    }

    function save() {
      ctrl.isSaving = true
      if (contentGroupIsUpdated()) {
        addToLog('update', null)
      }

      return contentGroupsService
        .save(ctrl.contentGroup)
        .then(function(id) {
          if (id) {
            ctrl.contentGroup.id = id
            ctrl.id = id
            glUtils.changeParams({ id: id })
          }
          ctrl.originalContentGroup = _.cloneDeep(ctrl.contentGroup)
        })
        .then(updateKits)
        .then(function() {
          glToast.show('Content Group Saved')
        })
        .catch(function() {
          glToast.show('Error saving Content Group')
        })
        .finally(function() {
          ctrl.isSaving = false
        })
    }

    function contentGroupIsUpdated() {
      return (
        !ctrl.contentGroup.isNew() &&
        !_.isEqualWith(
          ctrl.contentGroup.serialize(),
          ctrl.originalContentGroup.serialize(),
          ignoreKeys
        )
      )

      function ignoreKeys(objValue, otherValue, key) {
        if (_.includes(['log'], key)) {
          return true
        }
      }
    }

    function updateKits() {
      return giveKits()
        .then(takeKits)
        .then(function() {
          ctrl.kitsPrevious = _.cloneDeep(ctrl.kits)
        })
    }

    function giveKits() {
      var kitsToGive = _.differenceBy(ctrl.kits, ctrl.kitsPrevious, 'id')

      if (!kitsToGive.length) {
        return $q.resolve()
      }

      var promises = kitsToGive.map(function(kit) {
        return kitsService.giveToContentGroup(ctrl.contentGroup.id, kit.id)
      })
      return $q.all(promises)
    }

    function takeKits() {
      var kitsToTake = _.differenceBy(ctrl.kitsPrevious, ctrl.kits, 'id')

      if (!kitsToTake.length) {
        return $q.resolve()
      }

      var promises = kitsToTake.map(function(kit) {
        return kitsService.takeFromContentGroup(ctrl.contentGroup.id, kit.id)
      })
      return $q.all(promises)
    }

    // TODO: remove all logging functionality when BE implements proper logging
    function addToLog(type, data) {
      var logEntry = createLogEntry(type, data)
      ctrl.contentGroup.log.push(logEntry)
    }

    function createLogEntry(type, data) {
      return {
        userId: userService.get().id,
        type: type,
        data: data,
        createdAt: moment().toISOString(),
      }
    }
  }
})()
