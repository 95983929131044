;(function() {
  'use strict'

  Controller.$inject = ["subscriberReportExporter", "glToast"];
  angular.module('app.core').component('subscriberReportExporter', {
    controller: Controller,
    templateUrl: 'subscriber-report-exporter.html',
    bindings: {
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(subscriberReportExporter, glToast) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.onStartChange = onStartChange
    ctrl.onEndChange = onEndChange
    ctrl.export = doExport

    function onInit() {
      ctrl.today = moment().endOf('day')
      ctrl.startAt = moment().startOf('month')
      ctrl.endAt = ctrl.today
    }

    function onStartChange(date) {
      ctrl.startAt = date.startOf('day')
    }

    function onEndChange(date) {
      ctrl.endAt = date.endOf('day')
    }

    function doExport() {
      glToast.show('Starting download...', {
        target: '.main-content',
      })
      var url = subscriberReportExporter.getDownloadUrl(
        ctrl.startAt,
        ctrl.endAt
      )
      window.location.href = url
    }
  }
})()
