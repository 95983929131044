;(function() {
  'use strict'

  Config.$inject = ["$stateProvider"];
  angular.module('app.core').config(Config)

  /* @ngInject */
  function Config($stateProvider) {
    $stateProvider.state('app.promotion', {
      url: '/promotions/:id',
      templateUrl: 'promotion.html',
      controller: 'Promotion',
      controllerAs: '$ctrl',
    })
  }
})()
