;(function() {
  'use strict'

  Controller.$inject = ["stateService", "breadcrumbsService", "bundleService"];
  angular.module('app.core').controller('Bundles', Controller)

  /* @ngInject */
  function Controller(stateService, breadcrumbsService, bundleService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.getMultiLabel = getMultiLabel

    function onInit() {
      breadcrumbsService.addBreadcrumb('Bundles', 'home')
      ctrl.state = stateService.define(['loading', 'error', 'ready'])
      ctrl.state.loading()
      bundleService
        .loadAll()
        .then(function(bundles) {
          ctrl.bundles = bundles
          ctrl.state.ready()
        })
        .catch(function() {
          ctrl.state.error()
        })
    }

    function getMultiLabel(canPurchaseMultiple) {
      return canPurchaseMultiple ? 'Yes' : 'No'
    }
  }
})()
