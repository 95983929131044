;(function() {
  'use strict'

  angular.module('app.core', [
    'core.filters',
    'core.services',

    'core.addElementsDialog',
    'core.addCredit',
    'core.breadcrumbs',
    'core.compileHtml',
    'core.contentHeader',
    'core.contentPanel',
    'core.exportSurveyResponses',
    'core.input',
    'core.interactions',
    'core.imageinput',
    'core.noPropagate',
    'core.qr',
    'core.question-templates-list',
    'core.question-templates-picker',
    'core.manageKits',
    'core.kitsList',
    'core.contentGroupsList',
    'core.sidenav',
    'core.spinner',
    'core.wordcloud',
    'core.usage',
  ])
})()
