;(function() {
  'use strict'

  Service.$inject = ["glDialog", "api", "glUtils", "glAuthService", "configService"];
  angular.module('app.core').service('surveyTransactionsExporter', Service)

  /* @ngInject */
  function Service(glDialog, api, glUtils, glAuthService, configService) {
    this.show = show
    this.getDownloadUrl = getDownloadUrl

    function show() {
      // prettier-ignore
      var template = [
        '<gl-dialog class="survey-transactions-exporter__dialog">',
          '<survey-transactions-exporter on-cancel="dialog.cancel()"/>',
        '</gl-dialog>',
      ]
      glDialog.show({
        template: template.join(' '),
        closeOnOutsideClick: true,
      })
    }

    function getDownloadUrl(start, end) {
      // api.surveys
      //   .getSurveyTransactions({
      //     type: 'csv',
      //     startAt: start.toISOString(),
      //     endAt: end.toISOString(),
      //   })
      //   .then(function(resp) {
      //     console.log('resp', resp)
      //   })
      var token = glAuthService.getToken()
      var endpoint = configService.getApiUrl('/survey-transactions')
      var url = glUtils.addParams(endpoint, {
        type: 'csv',
        startAt: start.toISOString(),
        endAt: end.toISOString(),
        api_token: token,
      })
      return url
    }
  }
})()
