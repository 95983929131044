;(function() {
  'use strict'

  CouponsConfig.$inject = ["$stateProvider"];
  angular.module('app.coupons', []).config(CouponsConfig)

  /* @ngInject */
  function CouponsConfig($stateProvider) {
    $stateProvider.state('app.coupons', {
      url: '/coupons/:sid',
      templateUrl: 'coupons.template.html',
      controller: 'CouponsController',
    })
  }
})()
