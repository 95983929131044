;(function() {
  'use strict'

  postService.$inject = ["$q", "postsResource", "configService"];
  angular.module('core.services').factory('postService', postService)

  /* @ngInject */
  function postService($q, postsResource, configService) {
    return {
      get: get,
      getAll: getAll,
      getByUser: getByUser,
      getByElement: getByElement,
      getBySubscriber: getBySubscriber,
      reply: reply,
      hidePost: hidePost,
      unhidePost: unhidePost,
      hideComment: hideComment,
      unhideComment: unhideComment,
      getPostAccessURL: getPostAccessURL,
    }

    /**
     * @name get
     * @description get an post resource by ID
     * @param {String} postId post ID
     * @return {Promise} promise to resolve an post resource
     */
    function get(postId) {
      var deferred = $q.defer()

      postsResource
        .get({ id: postId })
        .success(function(resource) {
          deferred.resolve(resource)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name getAll
     * @descrption get list of post resources
     * @param  {null} param      only exists to maintain same argument structure as getByUser, Element or Subscriber
     * @param  {options} options options
     * @return {Promise}         promise to resolve post resources
     */
    function getAll(param, options) {
      var deferred = $q.defer()

      options = options || {}

      postsResource
        .get(options)
        .success(function(resources) {
          deferred.resolve(resources)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name getByUser
     * @description get list of posts for a user
     * @param  {String} userId  id of the user
     * @param  {Object} options options
     * @return {Promise}        promise to resolve post resources
     */
    function getByUser(userId, options) {
      var deferred = $q.defer()

      options = options || {}

      options.filter = options.filter || null

      // append user condition to filter
      if (typeof options.filter === 'string') {
        options.filter += " and creator.id eq '" + userId + "'"
      } else {
        options.filter = "creator.id eq '" + userId + "'"
      }

      postsResource
        .get(options)
        .success(function(resources) {
          deferred.resolve(resources)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name getByElement
     * @description get list of posts for a element
     * @param  {String} elementId  id of the element
     * @param  {Object} options options
     * @return {Promise}        promise to resolve post resources
     */
    function getByElement(elementId, options) {
      var deferred = $q.defer()

      options = options || {}

      options.filter = options.filter || null

      // append element condition to filter
      if (typeof options.filter === 'string') {
        options.filter += " and subject.id eq '" + elementId + "'"
      } else {
        options.filter = "subject.id eq '" + elementId + "'"
      }

      postsResource
        .get(options)
        .success(function(resources) {
          deferred.resolve(resources)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name getBySubscriber
     * @description get list of posts for a subscriber
     * @param  {String} subscriberId  id of the subscriber
     * @param  {Object} options options
     * @return {Promise}        promise to resolve post resources
     */
    function getBySubscriber(subscriberId, options) {
      var deferred = $q.defer()

      options = options || {}

      options.filter = options.filter || null

      // append subscriber condition to filter
      if (typeof options.filter === 'string') {
        options.filter += " and subject.groupId eq '" + subscriberId + "'"
      } else {
        options.filter = "subject.groupId eq '" + subscriberId + "'"
      }

      postsResource
        .get(options)
        .success(function(resources) {
          deferred.resolve(resources)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name reply
     * @description add reply to a post thread
     * @param {String} postId ID of post to reply to
     * @param {String} content content of reply message
     * @return {Promise} promise to resolve reply
     */
    function reply(postId, content) {
      var deferred = $q.defer()

      var data = {
        id: postId,
        comment: content,
      }

      postsResource
        .reply(data)
        .success(function(resources) {
          deferred.resolve(resources)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name hidePost
     * @description hide a post from public view
     * @param  {String} postId    ID of post
     * @return {Promise}          promise
     */
    function hidePost(postId) {
      var deferred = $q.defer()

      postsResource
        .hide({ id: postId })
        .success(function(resources) {
          deferred.resolve(resources)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name unhidePost
     * @description unhide a hidden post
     * @param  {String} postId    ID of post
     * @return {Promise}          promise
     */
    function unhidePost(postId) {
      var deferred = $q.defer()

      postsResource
        .unhide({ id: postId })
        .success(function(resources) {
          deferred.resolve(resources)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name hideComment
     * @description hide a comment on a post
     * @param  {String} postId    ID of post
     * @param  {String} commentId ID of comment
     * @return {Promise}          promise
     */
    function hideComment(postId, commentId) {
      var deferred = $q.defer()

      var data = {
        id: postId,
        commentId: commentId,
      }

      postsResource
        .hidePostComment(data)
        .success(function(resources) {
          deferred.resolve(resources)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name unhideComment
     * @description unhide a hidden comment on a post
     * @param  {String} postId    ID of post
     * @param  {String} commentId ID of comment
     * @return {Promise}          promise
     */
    function unhideComment(postId, commentId) {
      var deferred = $q.defer()

      var data = {
        id: postId,
        commentId: commentId,
      }

      postsResource
        .unhidePostComment(data)
        .success(function(resources) {
          deferred.resolve(resources)
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     *
     */
    function getPostAccessURL(postId, accessToken) {
      if (typeof postId === 'undefined' || typeof accessToken === 'undefined') {
        return false
      }

      var domainUrl = configService.getUserPortalUrl()
      return domainUrl + '/post-token/' + postId + '?accessToken=' + accessToken
    }
  }
})()
