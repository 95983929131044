;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$state", "breadcrumbsService", "glUtils"];
  angular
    .module('app.question-templates')
    .controller('QuestionTemplates', Controller)

  /* @ngInject */
  function Controller($scope, $state, breadcrumbsService, glUtils) {
    $scope.onStateChange = onStateChange
    $scope.onSelect = onSelect

    activate()

    /* activate
     * @desc set breadcrumbs and initial state of template-list
     */
    function activate() {
      // set breadcrumbs
      breadcrumbsService.addBreadcrumb('Question Templates', 'home')

      // set template-list state
      var filters = $state.params.filters
        ? JSON.parse(atob($state.params.filters))
        : {}
      var pages = parseInt($state.params.pages, 10) || 1
      $scope.state = {
        pages: pages,
        filters: {
          query: { value: filters.query },
          type: { value: filters.type },
          state: { value: filters.state },
        },
      }

      // set template-list actions
      $scope.actions = [
        {
          name: 'clone',
          icon: 'gi-copy',
          tooltip: 'Clone Template',
        },
      ]
    }

    /* onStateChange
     * @desc update url params with new state (pages and filters)
     * @param {Object} state the template-list state
     */
    function onStateChange(state) {
      // parse filters into a clean url-param
      var filters
      if (!_.isEmpty(state.filterString)) {
        filters = {
          query: state.filters.query.value,
          type: state.filters.type.value,
          state: state.filters.state.value,
        }
        filters = _(filters)
          .omitBy(_.isNil)
          .omitBy(_.isEmpty)
          .value()
        filters = btoa(JSON.stringify(filters))
      }

      // update url params
      glUtils.changeParams({
        pages: state.pages,
        filters: filters,
      })
    }

    /* onSelect
     * @desc fired when user clicks an item in the template-list
     */
    function onSelect(action, template) {
      if (!action) {
        return $state.go('app.question-template', { id: template.id })
      }
      if (action.name === 'clone') {
        return $state.go('app.question-template', {
          id: 'new',
          source: template.clone(),
        })
      }
    }
  }
})()
