;(function() {
  'use strict'

  AppConfig.$inject = ["$stateProvider"];
  angular.module('app.app', []).config(AppConfig)

  /* @ngInject */
  function AppConfig($stateProvider) {
    $stateProvider
      // app
      .state('app', {
        abstract: true,
        templateUrl: 'app.template.html',
        controller: 'AppController',
        data: {
          authenticate: true,
        },
        resolve: {
          /* @ngInject */
          user: ["userService", function(userService) {
            return userService.login()
          }],
        },
      })
  }
})()
