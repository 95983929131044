;(function() {
  'use strict'

  var VALID_FILETYPES = [
    'image/bmp',
    'image/gif',
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
  ]

  angular.module('core.imageinput').directive('imageInput', Directive)

  function Directive() {
    controller.$inject = ["$scope", "$timeout", "glToast"];
    return {
      restrict: 'E',
      templateUrl: 'imageinput.html',
      scope: {
        src: '=',
        onChange: '&',
        canRemove: '=?',
        size: '@',
      },
      link: link,
      controller: controller,
    }

    function link(scope, elem) {
      scope.elem = elem
    }

    /* @ngInject */
    function controller($scope, $timeout, glToast) {
      if ($scope.canRemove !== false) {
        $scope.canRemove = true
      }

      $scope.isValidFile = isValidFile
      $scope.imageUrl = imageUrl
      $scope.imageStyle = imageStyle
      $scope.clear = clear
      $scope.rebuild = rebuild
      $scope.sizeStyle = sizeStyle
      $scope.ratioStyle = ratioStyle

      $timeout(init)

      function init() {
        $scope.elem
          .children()
          .find('input')
          .bind('change', onInputChange)
      }

      function onInputChange(e) {
        if (!isValidFile(e.target.files[0])) {
          return displayInvalidError()
        }

        var reader = new FileReader()
        reader.onload = onReaderLoad
        reader.readAsDataURL(e.target.files[0])
      }

      function onReaderLoad(event) {
        // Logic to extract size if needed in future

        // var img = new Image();
        // img.onload = function () {
        //     // console.log('image size: ' + img.width + 'x' + img.height + 'px');
        // };
        // img.src = reader.result;

        // Make changes and fire callbacks

        $scope.$apply(function() {
          var imageData = event.target.result
          $scope.imageData = imageData
          if ($scope.onChange) {
            $scope.onChange({ $imageData: imageData })
          }
        })
      }

      function displayInvalidError() {
        $scope.elem
          .children()
          .find('input')
          .unbind('change', onInputChange)
        clear()
        rebuild()
        glToast.show(
          'Invalid file type. Supported types are PNG, JPEG, JPG, BMP, GIF or SVG.',
          {
            target: '.main-content',
          }
        )
        return
      }

      function imageUrl() {
        return $scope.imageData || $scope.src
      }

      function imageStyle() {
        var url = imageUrl()
        return url && { backgroundImage: 'url(' + url + ')' }
      }

      function isValidFile(file) {
        return file && _.indexOf(VALID_FILETYPES, file.type) >= 0
      }

      function clear() {
        $scope.src = null
        $scope.imageData = null
        if ($scope.onChange) {
          $scope.onChange({ $imageData: null })
        }
      }

      function rebuild() {
        $scope.rebuilding = true
        $timeout(function() {
          $scope.rebuilding = false
          $timeout(init)
        })
      }

      function getSize() {
        if ($scope.size && $scope.size.indexOf('x')) {
          return $scope.size.split('x')
        }
      }

      function sizeStyle() {
        var size = getSize()
        return size && { width: '100%', maxWidth: size[0] + 'px' }
      }

      function ratioStyle() {
        var size = getSize()
        if (size) {
          var ratio = (size[1] / size[0]) * 100
          return { paddingTop: ratio + '%' }
        }
      }
    }
  }
})()
