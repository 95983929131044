;(function() {
  'use strict'

  linksConfig.$inject = ["$stateProvider"];
  angular.module('app.links', []).config(linksConfig)

  /* @ngInject */
  function linksConfig($stateProvider) {
    $stateProvider.state('app.links', {
      url: '/links?longUrl&filters',
      templateUrl: 'links.template.html',
      controller: 'LinksController',
    })
  }
})()
