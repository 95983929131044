;(function() {
  'use strict'

  angular
    .module('core.contentHeader')
    .directive('glContentHeader', glContentHeaderDirective)

  /* @ngInject */
  function glContentHeaderDirective() {
    return {
      restrict: 'EA',
      templateUrl: 'content-header.tpl.html',
      transclude: true,
      replace: true,
      scope: true,
      link: link,
    }

    function link(scope, element, attrs) {
      attrs.$observe('pageTitle', function(value) {
        scope.pageTitle = value
      })
    }
  }
})()
