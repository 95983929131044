;(function() {
  'use strict'

  angular.module('core.services').factory('imexportService', imexportService)

  /* @ngInject */
  function imexportService() {
    var storage = {}

    return {
      add: add,
      get: get,
    }

    function add(type, name, data) {
      // clone so we don't screw with angular bindings

      data = _.cloneDeep(data)
      data = clean(data)

      // create the type if it doesn't exist yet

      if (!storage[type]) {
        storage[type] = []
      }

      // append a version suffix if the same name already exists

      var duplicates = _.filter(storage[type], { name: name }).length
      var suffix = ''
      if (duplicates > 0) {
        suffix = ' (' + (duplicates + 1) + ')' // eg ` (2)`
      }

      // create the export item

      var item = {
        name: name,
        displayName: name + suffix,
        data: data,
        created: moment(),
      }

      // add it to our collection for this type

      storage[type].push(item)

      // clean() removes all `undefined`, `$` and `$$` keys recursively

      function clean(data) {
        if (_.isArray(data) || _.isObject(data)) {
          _.each(data, function(value, key) {
            // var deletable = (value === undefined) || (_.isString(key) && key.match)
            if (value === undefined || (_.isString(key) && key.match(/^\$/))) {
              delete data[key]
            } else {
              data[key] = clean(value)
            }
          })
        }
        return data
      }
    }

    function get(type) {
      return storage[type] || []
    }
  }
})()
