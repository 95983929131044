;(function() {
  'use strict'

  CouponController.$inject = ["glUtils", "$scope", "$stateParams", "imagesService", "breadcrumbsService", "couponService", "glToast"];
  angular.module('app.coupon').controller('CouponController', CouponController)

  var COUPON_TYPES = [
    { label: 'Regular', value: 'regular' },
    { label: 'Donation', value: 'donation' },
  ]

  /* @ngInject */
  function CouponController(
    glUtils,
    $scope,
    $stateParams,
    imagesService,
    breadcrumbsService,
    couponService,
    glToast
  ) {
    $scope.data = {}
    $scope.data.couponId = $stateParams.couponId
    $scope.types = COUPON_TYPES
    $scope.save = save
    $scope.setImage = setImage
    $scope.setRedemptionImage = setRedemptionImage
    // $scope.deleteCoupon = deleteCoupon;

    activate()

    /**
     * initialise
     */
    function activate() {
      getCoupon()
      breadcrumbsService.addBreadcrumb('Coupon', 'app.coupons')
    }

    /**
     * get coupon from server
     */
    function getCoupon() {
      if ($stateParams.couponId === 'new') {
        // new coupon defaults
        $scope.data.coupon = {
          type: 'regular',
          subscriberId: $stateParams.id,
        }
      } else {
        // get coupon
        $scope.loading = true
        couponService.get($stateParams.couponId).then(getSuccess, getError)
      }

      function getSuccess(data) {
        $scope.loading = false
        $scope.data.coupon = data
        breadcrumbsService.updateCurrentTitle(data.name)
      }

      function getError() {
        $scope.loading = false
        glToast.action('Error retrieving coupon.', 'Retry', getCoupon, {
          target: '.main-content',
          timeout: 5000,
        })
      }
    }

    function setImage(imageData) {
      if (!imageData) {
        $scope.data.coupon.imageUrl = null
        $scope.couponForm.$setDirty()
        return
      }

      uploadImage(imageData).then(
        function(response) {
          $scope.data.coupon.imageUrl = response.imageUrl
          $scope.couponForm.$setDirty()
        },
        function() {
          displayImageError()
        }
      )
    }

    function setRedemptionImage(imageData) {
      if (!imageData) {
        $scope.data.coupon.redemptionImageUrl = null
        $scope.couponForm.$setDirty()
        return
      }

      uploadImage(imageData).then(
        function(response) {
          $scope.data.coupon.redemptionImageUrl = response.imageUrl
          $scope.couponForm.$setDirty()
        },
        function() {
          displayImageError()
        }
      )
    }

    function displayImageError() {
      glToast.show('Failed to save image', { target: '.main-content' })
    }

    function uploadImage(imageData) {
      $scope.processing = true

      var data = {
        imageData: imageData,
        request: 'coupon.save',
        surveyId: $stateParams.couponId,
        source: 'admin.glowfeed.com',
      }

      return imagesService.create(data).finally(function() {
        $scope.processing = false
      })
    }

    /**
     * Save changes to user
     */
    function save() {
      $scope.processing = true

      var coupon = $scope.data.coupon
      var action = coupon.id ? 'update' : 'create'
      var data = {
        id: coupon.id,
        subscriberId: $stateParams.subscriberId,
        imageUrl: coupon.imageUrl,
        redemptionImageUrl: coupon.redemptionImageUrl,
        title: coupon.name,
        type: coupon.type,
        description: coupon.description,
        startAt: coupon.startAt,
        endAt: coupon.endAt,
      }

      couponService[action](data).then(
        function(resp) {
          $scope.data.coupon.id = resp.id || $scope.data.coupon.id
          refetch()
        },
        function() {
          $scope.processing = false
          glToast.action("Couldn't " + action + ' coupon.', 'Retry', save, {
            target: '.main-content',
            timeout: 5000,
          })
        }
      )
    }

    function refetch() {
      couponService.get($scope.data.coupon.id).then(
        function(data) {
          $scope.data.coupon = data

          // update url
          glUtils.changeParams({
            subscriberId: $stateParams.subscriberId,
            couponId: $scope.data.coupon.id,
          })

          $scope.processing = false
          glToast.show('Coupon saved', {
            target: '.main-content',
          })
        },
        function() {
          $scope.processing = false
          glToast.show('Error re-fetching coupon', {
            target: '.main-content',
          })
        }
      )
    }

    // function deleteCoupon () {
    //     glDialog.confirm('Delete Coupon', 'Are you sure you want to completely remove this coupon?', {
    //         parent: '.main-content'
    //     }).then(removeCoupon);

    //     function removeCoupon () {
    //         $scope.processing = true;
    //         couponService.deleteBySubscriber($scope.data.coupon.id, $stateParams.subscriberId)
    //             .then(removeSuccess, removeError);
    //     }

    //     function removeSuccess () {
    //         $scope.processing = false;
    //         $state.go('app.coupons', { subscriberId: $stateParams.subscriberId });
    //         glToast.show('Error saving image to coupon.', {
    //             target: '.main-content'
    //         });
    //     }

    //     function removeError () {
    //         $scope.processing = false;
    //         glToast.action('There was an issue deleting this coupon.', 'Retry', removeCoupon, {
    //             target: '.main-content',
    //             timeout: 5000
    //         });
    //     }
    // }
  }
})()
