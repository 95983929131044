;(function() {
  'use strict'

  AddElementsDialogController.$inject = ["$scope", "elementService", "glToast"];
  angular
    .module('core.addElementsDialog')
    .controller('AddElementsDialogController', AddElementsDialogController)

  /**
   * @name elementsDialogController
   * @description controller for add elements dialog modal
   *
   * @ngInject */
  function AddElementsDialogController($scope, elementService, glToast) {
    /**
     * defaults
     */
    $scope.processing = false

    $scope.search = {
      complete: false,
    }

    $scope.selected = {
      ids: {},
      elements: [],
    }

    var RESULTS_TOP = 50
    var RESULTS_PER_PAGE = 5
    var page = 0

    $scope.getDisplayedResults = getDisplayedResults
    $scope.isPrev = isPrev
    $scope.isNext = isNext
    $scope.goPrev = goPrev
    $scope.goNext = goNext

    /**
     * watch checkbox model changes and add elements to selected.elements on check
     */
    $scope.$watchCollection('selected.ids', function() {
      $scope.selected.elements = []
      _.forIn($scope.selected.ids, function(value, key) {
        if (value) {
          $scope.selected.elements.push(
            _.find($scope.search.results, function(element) {
              return element.id === key || element.elementId === key
            })
          )
        }
      })
    })

    /**
     * @name search
     * @description search for elements using scope.search query string
     * @return {Array} array of element-items or elements dependent on search type
     */
    $scope.search = function() {
      $scope.processing = true
      elementService
        .getAll({
          top: RESULTS_TOP,
          skip: 0,
          orderby: 'name',
          order: 'asc',
          filter: '(title regex /' + $scope.search.query + '/i)',
        })
        .then(function(data) {
          $scope.search.results = data

          // Clear selections and go back to the first page
          $scope.selected = {
            ids: {},
            elements: [],
          }
          page = 0

          $scope.processing = false
          $scope.search.complete = true
        })
        .catch(function() {
          $scope.processing = false
          glToast.action(
            'Search could not be completed.',
            'Retry',
            $scope.search,
            {
              target: '.element-add-dialog',
              position: 'bottom-full-width',
            }
          )
        })
    }

    /**
     * Check if there is a next page
     */
    function isNext() {
      return (
        $scope.search.results.length >
        page * RESULTS_PER_PAGE + RESULTS_PER_PAGE
      )
    }

    /**
     * Check if there is a previous page
     */
    function isPrev() {
      return page > 0
    }

    /**
     * Go to previous page
     */
    function goPrev() {
      if (isPrev()) {
        page--
      }
      return page
    }

    /**
     * Go to next page
     */
    function goNext() {
      if (isNext()) {
        page++
      }
      return page
    }

    /**
     * Update the results displayed in the dialog
     */
    function getDisplayedResults() {
      var start = page * RESULTS_PER_PAGE
      var end = start + RESULTS_PER_PAGE

      return $scope.search.results.slice(start, end)
    }
  }
})()
