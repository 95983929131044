;(function() {
  'use strict'

  SubscriberController.$inject = ["$q", "$scope", "$state", "$stateParams", "Currency", "breadcrumbsService", "glDialog", "subscriberService", "glToast", "industryService", "countryService", "currencyService", "claimService", "contentGroupsService", "plansService"];
  angular
    .module('app.subscriber')
    .controller('SubscriberController', SubscriberController)

  /* @ngInject */
  function SubscriberController(
    $q,
    $scope,
    $state,
    $stateParams,
    Currency,
    breadcrumbsService,
    glDialog,
    subscriberService,
    glToast,
    industryService,
    countryService,
    currencyService,
    claimService,
    contentGroupsService,
    plansService
  ) {
    $scope.loading = false
    $scope.processing = false

    $scope.data = {}
    $scope.subscriptionCodeValidation = '[a-zA-Z0-9-]{2,}'

    $scope.save = save
    $scope.isPlanDisabled = isPlanDisabled
    $scope.exportFeedback = exportFeedback
    $scope.associateUser = associateUser
    $scope.showAddElementsDialog = showAddElementsDialog
    $scope.showAddCreditDialog = showAddCreditDialog
    $scope.claimElements = claimElements
    $scope.addContentGroup = addContentGroup
    $scope.removeContentGroup = removeContentGroup
    $scope.togglePaymentType = togglePaymentType
    $scope.industries = industryService.getAll()
    $scope.countries = countryService.getAll()
    $scope.onCountryChange = onCountryChange

    activate()

    /**
     * initialise
     */
    function activate() {
      $scope.subscriberId = $stateParams.id
      breadcrumbsService.addBreadcrumb('Subscriber', 'app.subscribers')
      $scope.loading = true
      loadSubscriber()
        .then(loadContentGroups)
        .then(loadPlans)
        .then(function() {
          breadcrumbsService.updateCurrentTitle($scope.data.subscriber.name)
          $scope.loading = false
        })
        .catch(function(err) {
          console.error(err)
          glToast.show('Error retrieving subscriber.', {
            target: '.main-content',
            timeout: 5000,
          })
        })
    }

    function loadSubscriber() {
      return $q(function(resolve, reject) {
        if ($scope.subscriberId === 'new') {
          $scope.data.subscriber = {
            contentGroupIds: [],
            planDetail: {},
          }
          return resolve()
        }
        subscriberService
          .get($scope.subscriberId)
          .then(function(data) {
            $scope.data.subscriber = data
            resolve()
          })
          .catch(reject)
      })
    }

    function loadContentGroups() {
      if ($scope.subscriberId === 'new') {
        $scope.data.contentGroups = []
        return $q.resolve()
      }
      var promises = _.map($scope.data.subscriber.contentGroupIds, function(
        contentGroupId
      ) {
        return contentGroupsService.get(contentGroupId)
      })
      return $q.all(promises).then(function(contentGroups) {
        $scope.data.contentGroups = contentGroups
      })
    }

    function addContentGroup(contentGroup) {
      $scope.data.contentGroups.push(contentGroup)
      $scope.data.subscriber.contentGroupIds.push(contentGroup.id)
      $scope.subscriberForm.$setDirty()
    }

    function removeContentGroup(contentGroup) {
      _.remove($scope.data.contentGroups, contentGroup)
      _.remove($scope.data.subscriber.contentGroupIds, function(
        contentGroupId
      ) {
        return contentGroup.id === contentGroupId
      })
      $scope.subscriberForm.$setDirty()
    }

    function loadPlans() {
      return plansService.list().then(function(plans) {
        $scope.plans = _(plans)
          .filter(function(plan) {
            if (
              $scope.data.subscriber.id &&
              $scope.data.subscriber.plan.id === plan.id
            ) {
              return true
            }
            return (
              plan.type !== plan.Types.DRAFT && plan.type !== plan.Types.LEGACY
            )
          })
          .map(function(plan) {
            // Decorate the select options
            plan.selectLabel =
              plan.getTypeLabel() + '/' + plan.interval.label + ': ' + plan.name
            return plan
          })
          .value()
      })
    }

    function isPlanDisabled(plan) {
      return plan.type === plan.Types.LEGACY || plan.type === plan.Types.DRAFT
    }

    /**
     * Update or create subscriber
     */
    function save() {
      var action = $scope.data.subscriber.id ? 'save' : 'create'

      // Must have at least one contentGroup. BE will add one by default if none provided on create.
      var haveContentGroup = $scope.data.contentGroups.length > 0
      if (action === 'save' && !haveContentGroup) {
        return glDialog.alert(
          'Content Group Required',
          'Subscribers must have at least one content group.'
        )
      }

      var plan = null
      if (action === 'create' || $scope.data.planChanged) {
        plan = $scope.data.subscriber.plan
        $scope.data.subscriber.plan = null
      }
      $scope.processing = true
      subscriberService[action]($scope.data.subscriber)
        .then(function(resp) {
          if (action === 'create') {
            $scope.subscriberId = resp.id
          }
          return loadSubscriber()
        })
        .then(checkAndUpdateCurrency)
        .then(function() {
          if (!plan) return $q.resolve()
          $scope.data.subscriber.plan = plan
          return subscriberService.save($scope.data.subscriber)
        })
        .then(function() {
          glToast.show('Subscriber saved', {
            target: '.main-content',
          })
          $scope.data.willSetCurrency = false
          if (action === 'create') {
            reloadSubscriber($scope.data.subscriber.id)
          }
          $scope.subscriberForm.$setPristine()
        })
        .catch(function(err) {
          console.error(err)
          var msg = err && err.message
          glToast.action(msg || "Couldn't save subscriber.", 'Retry', save, {
            target: '.main-content',
            timeout: 5000,
          })
        })
        .finally(function() {
          $scope.processing = false
        })
    }

    function checkAndUpdateCurrency() {
      if (!$scope.data.currency) {
        return $q.resolve()
      }
      return subscriberService
        .setCurrency($scope.data.subscriber, $scope.data.currency.code)
        .then(function() {
          $scope.data.currency = null
        })
    }

    /**
     * reload subscriber after creating a new subscriber to attach ID and update route
     * @param  {String} id subscriber ID
     */
    function reloadSubscriber(id) {
      $scope.data.subscriber.id = id
      $state.transitionTo(
        'app.subscriber',
        {
          id: id,
        },
        {
          location: 'replace',
          inherit: true,
          notify: true,
        }
      )
    }

    /**
     * export subscriber data
     * @param  {Object} $event click event data
     */
    function exportFeedback($event) {
      // TODO
      // GET /subscriber/[id]/feedback
      glDialog.alert('Todo', 'Start download', {
        targetEvent: $event,
        parent: '.portal-container',
      })
    }

    function associateUser() {
      var parentScope = $scope
      var dialogOptions = {
        target: '.main-content',
        templateUrl: 'associate-user-dialog.template.html',

        /* @ngInject */
        controller: ["$scope", "usersService", function($scope, usersService) {
          $scope.users = []

          $scope.searchUserByEmail = function() {
            parentScope.processing = true

            usersService
              .getAll({
                top: 10,
                skip: 0,
                orderby: 'fullname',
                order: 'asc',
                filter: 'email regex /' + $scope.userEmail + '/i',
              })
              .then(
                function getSuccess(data) {
                  $scope.users = data
                },
                function getError() {
                  glToast.show('Failed to search for user.', {
                    target: '.main-content',
                    timeout: 5000,
                  })
                }
              )
              .finally(function getFinally() {
                parentScope.processing = false
              })
          }

          $scope.associateUser = function(user) {
            parentScope.processing = true

            usersService
              .associateUserWithGroup(user.id, parentScope.subscriberId)
              .then(
                function() {
                  $state.go('app.subscriber', { id: parentScope.subscriberId })
                  $scope.dialog.cancel()
                },
                function(error) {
                  console.log(error)
                  glToast.show(
                    "Couldn't associate user from subscriber. " + (error || ''),
                    {
                      target: '.main-content',
                      timeout: 5000,
                    }
                  )
                }
              )
              .finally(function() {
                parentScope.processing = false
              })
          }
        }],
      }

      glDialog.show(dialogOptions)
    }

    /**
     * show add elements dialog, capture selected elements and claim
     */
    function showAddElementsDialog() {
      glDialog
        .show({
          controller: 'AddElementsDialogController',
          parent: '.main-content',
          templateUrl: 'add-elements-dialog.tpl.html',
        })
        .then(function(data) {
          // capture selected elements and ensure no duplicates
          $scope.newElements = []
          $scope.newElements = _.uniqBy(
            $scope.newElements.concat(data),
            function(element) {
              return element.id || (element.title || element.name)
            }
          )
          claimElements()
        })
    }

    /**
     * claim elements
     */
    function claimElements() {
      if ($scope.newElements.length > 0) {
        $scope.processing = true
        claimService
          .claimElements($scope.subscriberId, $scope.newElements)
          .then(
            function() {
              // Success
              glToast.action('Elements claimed!', {
                target: '.main-content',
                timeout: 5000,
              })
            },
            function() {
              // do something with the error
              glToast.action(
                'Elements could not be claimed.',
                'Retry',
                $scope.claimElements,
                {
                  target: '.main-content',
                  timeout: 5000,
                }
              )
            }
          )
          .finally(function() {
            $scope.processing = false
          })
      }
    }

    function showAddCreditDialog() {
      // prettier-ignore
      var template = [
        '<gl-dialog class="add-credit__dialog">',
          '<g-dialog-header',
            ' title="Add Credit"',
            ' on-close="dialog.cancel()"',
            '></g-dialog-header>',
          '<add-credit',
            ' subscriber="subscriber"',
            ' on-credit-added="onCreditAdded($credit)"',
            '/>',
        '</gl-dialog>',
      ]
      glDialog.show({
        template: template.join(' '),
        locals: {
          subscriber: $scope.data.subscriber,
          onCreditAdded: onCreditAdded,
        },
      })
    }

    function onCreditAdded(credit) {
      $scope.data.subscriber.billing.credit.amount += credit
    }

    function onCountryChange() {
      if (
        !$scope.data.subscriber.id ||
        !$scope.data.subscriber.billing.currency
      ) {
        var localCurrency = currencyService.getByCountry(
          $scope.data.subscriber.country.cc
        )
        $scope.data.currency =
          localCurrency || currencyService.getByCode(Currency.Codes.USD)
      }
    }

    function togglePaymentType() {
      subscriberService.togglePaymentType($scope.data.subscriber)
    }
  }
})()
