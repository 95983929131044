;(function() {
  'use strict'

  UserController.$inject = ["$q", "$scope", "$state", "$stateParams", "breadcrumbsService", "usersService", "jobRoleService", "glToast", "glDialog", "glAuthService"];
  angular.module('app.users').controller('UserController', UserController)

  /* @ngInject */
  function UserController(
    $q,
    $scope,
    $state,
    $stateParams,
    breadcrumbsService,
    usersService,
    jobRoleService,
    glToast,
    glDialog,
    glAuthService
  ) {
    $scope.loading = true
    $scope.processing = false
    $scope.subscriberId = $stateParams.sid
    $scope.jobRoles = jobRoleService.getAll()
    $scope.types = [
      { name: 'Phone', value: 'phone', action: 'create' },
      {
        name: 'Subscriber Support',
        value: 'subscriber-support',
        action: 'createSubscriberSupport',
      },
    ]

    $scope.save = save
    $scope.associateGroup = associateGroup
    $scope.removeFromGroup = removeFromGroup

    activate()

    /**
     * initialise
     */
    function activate() {
      breadcrumbsService.addBreadcrumb('User', 'app.users')
      load()
    }

    function load() {
      $scope.loading = true
      loadUser()
        .then(function() {
          breadcrumbsService.updateCurrentTitle($scope.user.fullname || 'User')
        })
        .finally(function() {
          $scope.loading = false
        })
    }

    function loadUser() {
      if ($stateParams.id === 'new') {
        $scope.user = {}
        return $q.resolve()
      }
      return usersService
        .get($stateParams.id)
        .then(function(user) {
          $scope.user = user
          $scope.isSubscriber = _.includes(
            user.roles,
            glAuthService.Roles.SUBSCRIBER
          )
        })
        .catch(function() {
          glToast.action('Error retrieving user.', 'Retry', load, {
            target: '.main-content',
            timeout: 5000,
          })
        })
    }

    /**
     * Save changes to user
     */
    function save() {
      $scope.processing = true

      var user = $scope.user
      var data = {
        id: user.id,
        fullname: user.fullname,
        jobRole: user.jobRole,
        email: user.email,
        username: user.username,
        weeklyActivitySummaryEmail: user.weeklyActivitySummaryEmail,
        enabled: user.enabled,
      }

      if (user.password) {
        data.password = user.password
      }

      var action
      if (user.id) {
        action = 'update'
      } else {
        action = user.type ? user.type.action : 'create'
      }

      usersService[action](data).then(success, error)

      function success(response) {
        $scope.processing = false

        glToast.show('User saved', { target: '.main-content' })

        if ($stateParams.sid && action === 'create') {
          $scope.processing = true

          usersService
            .associateUserWithGroup(response.id, $stateParams.sid)
            .then(associateSuccess, associateError)
            .finally(associateComplete)
        } else if (action === 'create') {
          $state.go('app.user', { id: response.id })
        }

        function associateSuccess() {
          $state.go('app.user', { id: response.id })
        }

        function associateError() {
          glToast.show("Couldn't associate user with subscriber.", {
            target: '.main-content',
            timeout: 5000,
          })
        }

        function associateComplete() {
          $scope.processing = false
        }
      }

      function error() {
        $scope.processing = false

        glToast.action("Couldn't " + action + ' User.', 'Retry', save, {
          target: '.main-content',
          timeout: 5000,
        })
      }
    }

    function associateGroup() {
      var parentScope = $scope
      var dialogOptions = {
        target: '.main-content',
        templateUrl: 'associate-group-dialog.template.html',

        /* @ngInject */
        controller: ["$scope", "subscriberService", function($scope, subscriberService) {
          $scope.subscribers = []

          $scope.searchSubscriberByEmail = function() {
            parentScope.processing = true

            var opts = {
              top: 10,
              skip: 0,
              orderby: 'name',
              order: 'asc',
              filter:
                'administrator.email regex /' + $scope.subscriberEmail + '/i',
            }

            subscriberService
              .getAll(opts)
              .then(success, error)
              .finally(complete)

            function success(data) {
              $scope.subscribers = data
            }

            function error() {
              glToast.show('Error retrieving subscriber.', {
                target: '.main-content',
                timeout: 5000,
              })
            }

            function complete() {
              parentScope.processing = false
            }
          }

          $scope.associateSubscriber = function(subscriber) {
            parentScope.processing = true

            usersService
              .associateUserWithGroup(parentScope.user.id, subscriber.id)
              .then(success, error)
              .finally(complete)

            function success() {
              $state.go('app.user', {
                id: parentScope.user.id,
                sid: subscriber.id,
              })
              $scope.dialog.cancel()
            }

            function error() {
              glToast.show("Couldn't remove user from subscriber.", {
                target: '.main-content',
                timeout: 5000,
              })
            }

            function complete() {
              parentScope.processing = false
            }
          }
        }],
      }

      glDialog.show(dialogOptions)
    }

    function removeFromGroup() {
      $scope.processing = true

      usersService
        .removeUserFromGroup($scope.user.id, $scope.subscriberId)
        .then(success, error)
        .finally(complete)

      function success() {
        $state.go('app.user', { id: $scope.user.id, sid: '' })
      }

      function error() {
        glToast.show("Couldn't remove user from subscriber.", {
          target: '.main-content',
          timeout: 5000,
        })
      }

      function complete() {
        $scope.processing = false
      }
    }
  }
})()
