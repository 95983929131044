;(function() {
  'use strict'

  glSidenavDirective.$inject = ["$animate", "$compile", "$document"];
  angular.module('core.sidenav').directive('glSidenav', glSidenavDirective)

  /**
   * sidenav directive, slide in-out left side navigation
   *
   * @ngInject */
  function glSidenavDirective($animate, $compile, $document) {
    return {
      restrict: 'EA',
      link: function(scope, element) {
        var parent = element.parent()
        var body = $document.find('body').eq(0)

        var backdrop = $compile('<gl-backdrop class="gl-sidenav-backdrop">')(
          scope
        )

        // default to closed (mobile only)
        scope.isOpen = false

        // default to expanded
        scope.isMin = false

        // on sidenav:close event set isOpen to false
        scope.$on('sidenav:close', function() {
          scope.isOpen = false
        })

        // on sidenav:toggle event toggle isOpen value
        scope.$on('sidenav:toggleOpen', function() {
          scope.isOpen = !scope.isOpen
        })

        // on sidenav:toggle event toggle isMin value
        scope.$on('sidenav:toggleMin', function() {
          scope.isMin = !scope.isMin
        })

        // watch for isMin change and update sidenav
        scope.$watch('isMin', updateWidth)

        // watch for isMin change and update sidenav
        scope.$watch('isOpen', updateShow)

        /**
         * close the sidenav when click event on backdrop recieved
         */
        function close(e) {
          e.preventDefault()
          e.stopPropagation()

          scope.$broadcast('sidenav:close')
          scope.$digest()
        }

        /**
         * update the sidenav width
         */
        function updateWidth() {
          body[scope.isMin ? 'addClass' : 'removeClass']('no-scroll')
          $animate[scope.isMin ? 'addClass' : 'removeClass'](
            element,
            'gl-nav-min'
          )
        }

        /**
         * update the sidenav and backdrop element
         */
        function updateShow() {
          backdrop[scope.isOpen ? 'on' : 'off']('click', close)
          body[scope.isOpen ? 'addClass' : 'removeClass']('no-scroll')

          $animate[scope.isOpen ? 'enter' : 'leave'](backdrop, parent)
          $animate[scope.isOpen ? 'addClass' : 'removeClass'](
            element,
            'gl-nav-open'
          )
        }
      },
    }
  }
})()
