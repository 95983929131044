;(function() {
  'use strict'

  PostsController.$inject = ["$scope", "$stateParams", "breadcrumbsService", "postService", "glToast", "listFilterService"];
  angular.module('app.posts').controller('PostsController', PostsController)

  /* @ngInject */
  function PostsController(
    $scope,
    $stateParams,
    breadcrumbsService,
    postService,
    glToast,
    listFilterService
  ) {
    $scope.data = {
      posts: [],
    }

    // filters
    var uriFilters = listFilterService.initFilter($stateParams)
    $scope.filters = _.assign(
      {
        type: 'all',
        top: 50,
        page: 1,
        pageExhausted: false,
        groupId: 'all',
        userFlagged: 'all',
        creator: '',
        orderby: 'createdAt',
        order: 'desc',
      },
      uriFilters
    )

    // methods
    $scope.getPosts = getPosts

    activate()

    /**
     * initialise
     */
    function activate() {
      getPosts()

      var parentState = $stateParams.sid
        ? 'app.subscriber'
        : $stateParams.eid
          ? 'app.element'
          : $stateParams.uid
            ? 'app.user'
            : 'home'

      breadcrumbsService.addBreadcrumb('Posts', parentState)
    }

    /**
     * Build search object from filters
     * @param  {Object} srcObj filter object to build upon
     * @return {Object}
     */
    function organiseSearchObj(srcObj) {
      var sendingObj = {}

      // top, page
      sendingObj.top = srcObj.top
      sendingObj.skip = srcObj.top * (srcObj.page - 1)

      // orderby GLOWADMIN-139 sort by counts.upvotes NOT WORKING
      sendingObj.orderby = srcObj.orderby + ' ' + srcObj.order

      // creator
      var keyword = srcObj.creator
      if (keyword) {
        sendingObj.filter =
          "creator.id eq '" + keyword + "' or creator.name eq '" + keyword + "'"
      }

      // type
      if (srcObj.type !== 'all') {
        if (typeof sendingObj.filter === 'string') {
          sendingObj.filter += ' and type eq ' + srcObj.type.toUpperCase()
        } else {
          sendingObj.filter = 'type eq ' + srcObj.type.toUpperCase()
        }
      }

      // element registered
      var groupId = srcObj.groupId
      if (groupId !== 'all') {
        if (typeof sendingObj.filter === 'string') {
          sendingObj.filter += ' and subject.groupId exists ' + groupId
        } else {
          sendingObj.filter = 'subject.groupId exists ' + groupId
        }
      }

      return sendingObj
    }

    function getPosts(append) {
      var action
      var param

      if ($stateParams.sid) {
        action = 'getBySubscriber'
        param = $stateParams.sid
      } else if ($stateParams.eid) {
        action = 'getByElement'
        param = $stateParams.eid
      } else if ($stateParams.uid) {
        action = 'getByUser'
        param = $stateParams.uid
      } else {
        action = 'getAll'
      }

      $scope.loading = true

      if (!append && $scope.data.posts) {
        $scope.data.posts = []
        $scope.filters.page = 1
        $scope.filters.pageExhausted = false
        listFilterService.writeFilter($scope.filters)
      } else if (append) {
        $scope.filters.page++
      }

      postService[action](param, organiseSearchObj($scope.filters)).then(
        getSuccess,
        getError
      )

      function getSuccess(data) {
        $scope.loading = false
        $scope.data.posts.push.apply($scope.data.posts, data)
        if (data.length < $scope.filters.top) {
          $scope.filters.pageExhausted = true
        }
      }

      function getError() {
        $scope.loading = false
        glToast.action('Error retrieving posts.', 'Retry', getPosts, {
          target: '.main-content',
          timeout: 5000,
        })
      }
    }
  }
})()
