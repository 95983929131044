;(function() {
  'use strict'

  angular
    .module('core.interactions')
    .directive('interactions', interactionsDirective)

  /* @ngInject */
  function interactionsDirective() {
    controller.$inject = ["$scope"];
    return {
      restrict: 'E',
      templateUrl: 'interactions.template.html',
      controller: controller,
    }

    /* @ngInject */
    function controller($scope) {
      var moods = {
        '1': { class: 'gi gi-mood-6', label: 'Very Angry' },
        '2': { class: 'gi gi-mood-5', label: 'Angry' },
        '3': { class: 'gi gi-mood-4', label: 'Meh' },
        '4': { class: 'gi gi-mood-3', label: 'Neutral' },
        '5': { class: 'gi gi-mood-2', label: 'Happy' },
        '6': { class: 'gi gi-mood-1', label: 'Very Happy' },
      }

      // methods
      $scope.toggleInteraction = toggleInteraction
      $scope.interactionIcon = interactionIcon
      $scope.interactionMoodIcon = interactionMoodIcon
      $scope.interactionMoodLabel = interactionMoodLabel
      $scope.interactionHeaderText = interactionHeaderText
      $scope.commentCircleText = commentCircleText

      /**
       * toggle interactions selected property. Used to expand / collapse interaction
       * @param  {String} id interactions id
       */
      function toggleInteraction(id) {
        _.forEach($scope.interactionsData.groupedInteractions, function(
          interactionGroup
        ) {
          // update visible interactions
          _.forEach(interactionGroup, function(interaction) {
            interaction.selected =
              interaction.id === id && !interaction.selected ? true : false
          })
        })
      }

      /**
       * determine interaction icon class(s)
       * @param  {Object} interaction interaction to generate icon class(s) for
       */
      function interactionIcon(interaction) {
        var iconTypes = {
          feedback: 'gi-chat blue',
          idea: 'gi-idea blue',
          complaint: 'gi-complaint blue',
          praise: 'gi-praise blue',
          suggestion: 'gi-suggestion blue',
          request: 'gi-request blue',
          question: 'gi-question blue',
          'survey-response': 'gi-survey green',
        }

        return iconTypes[interaction.type] || 'feedback'
      }

      function interactionMoodIcon(interaction) {
        return moods[interaction.mood] ? moods[interaction.mood].class : null
      }

      function interactionMoodLabel(interaction) {
        return moods[interaction.mood].label || 'Poker face'
      }

      /**
       * generate interaction header text
       * @param  {Object} interaction interaction
       * @return {String}             interpolated interaction header title
       */
      function interactionHeaderText(interaction) {
        var titleHash = {
          feedback: ' has given feedback to ',
          idea: ' has given an idea for ',
          complaint: ' has given a complaint for ',
          praise: ' has given some praise for ',
          suggestion: ' has a suggestion for ',
          request: ' has a request for ',
          question: ' has a question for ',
        }

        var name = interaction.username
          ? '<strong>' + interaction.username + '</strong>'
          : 'A user'

        if (interaction.parentType === 'feedback') {
          return (
            name +
            titleHash[interaction.type] +
            '<strong>' +
            interaction.elementTitle +
            '</strong>'
          )
        } else if (interaction.parentType === 'survey-response') {
          return (
            name +
            ' has submitted a survey response to <strong>' +
            interaction.surveyTitle +
            '</strong>'
          )
        } else {
          return (
            name +
            ' has submitted some feedback to <strong>' +
            interaction.elementTitle +
            '</strong>'
          )
        }
      }

      /**
       * get first two letters of commentors username to use in comment icon
       * @param  {Object} comment comment to generate comment icon text for
       * @return {String}         two letter string for use in icon
       */
      function commentCircleText(comment) {
        if (comment.creator && !comment.creator.hack) {
          return (
            comment.creator.name[0].toUpperCase() +
            comment.creator.name[1].toLowerCase()
          )
        } else {
          // Fix for old interactions that do not have a creator key on comments
          // TOOD remove once migration complete
          comment.creator = {
            hack: true,
          }
          switch (comment.type) {
            case 'u':
              comment.creator.name = 'Glow user'
              return 'U'
            case 's':
              comment.creator.name = 'me'
              return 'S'
            case 'c':
              comment.creator.name = 'Glow user'
              return 'C'
            case 'a':
              comment.creator.name = 'Admin user'
              return 'A'
          }
        }
      }
    }
  }
})()
