;(function() {
  'use strict'

  Controller.$inject = ["$scope", "questionTemplatesPicker", "questionTemplateFactory"];
  angular
    .module('core.question-templates-picker')
    .controller('QuestionTemplatesPicker', Controller)

  /* @ngInject */
  function Controller(
    $scope,
    questionTemplatesPicker,
    questionTemplateFactory
  ) {
    // force picker to only show PUBLISHED templates
    var defaultPickerState = {
      filters: {
        state: {
          value: questionTemplateFactory.States.PUBLISHED,
          isDisabled: true,
        },
      },
    }

    $scope.onStateChange = onStateChange
    $scope.onSelect = onSelect

    // set initial state
    $scope.state = questionTemplatesPicker.getState() || defaultPickerState

    /* onStateChange
         * @desc remembers state for next time the picker is shown
         * @param {Object} state The template-list state
         */
    function onStateChange(state) {
      questionTemplatesPicker.setState(state)
    }

    /* onSelect
         * @desc fired when user clicks a template. Closes the picker and resolves with the selected template
         */
    function onSelect(template) {
      $scope.dialog.close(template)
    }
  }
})()
