;(function() {
  'use strict'

  SurveyService.$inject = ["$q", "$filter", "surveysResource", "glAuthService", "configService", "glUtils", "glToast", "questionsResource"];
  angular.module('core.services').service('surveyService', SurveyService)

  /* @ngInject */
  function SurveyService(
    $q,
    $filter,
    surveysResource,
    glAuthService,
    configService,
    glUtils,
    glToast,
    questionsResource
  ) {
    var nextTemplate

    var defaultTemplate = {
      questions: [],
      rewards: [],
    }

    return {
      create: create,
      get: get,
      getAll: getAll,
      getByElement: getByElement,
      getTemplate: getTemplate,
      publish: publish,
      pull: pull,
      saveImage: saveImage,
      saveListImage: saveListImage,
      saveQuestionImage: saveQuestionImage,
      setNextTemplate: setNextTemplate,
      update: update,
      getQRString: getQRString,
      getResponses: getResponses,
      exportAllResponsesByElement: exportAllResponsesByElement,
      exportAllResponsesByComparisonKey: exportAllResponsesByComparisonKey,
      exportSurveyTransactionsByDateRange: exportSurveyTransactionsByDateRange,
      getNotes: getNotes,
      saveNotes: saveNotes,
      getDefinition: getDefinition,
      lockQuestion: lockQuestion,
      unlockQuestion: unlockQuestion,
    }

    /**
     * @name create
     * @description create a new survey
     * @param {{}} surveyData survey object
     * @return {Promise} promise will resolve on creation success
     */
    function create(surveyData) {
      var deferred = $q.defer()

      // DEPRECATED
      surveysResource
        .create(surveyData)
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name get
     * @description get survey by ID
     * @param {String} surveyId id of survey to get
     * @return {Promise} promise to resolve a survey resource
     */
    function get(surveyId) {
      var deferred = $q.defer()

      surveysResource
        .get({ id: surveyId })
        .success(function(x, survey) {
          deferred.resolve(survey)
        })
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name getAll
     * @description get list of survey resources
     * @return {Promise} promise to resolve survey resources
     */
    function getAll(options) {
      var deferred = $q.defer()

      options = options || {}

      surveysResource
        .get(options)
        .success(function(x, surveys) {
          deferred.resolve(surveys)
        })
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name getByElement
     * @description get surveys by element ID
     * @param {String} elementId element ID
     * @return {Promise} promise to resolve an array of survey resource
     */
    function getByElement(elementId, options) {
      var deferred = $q.defer()

      options = options || {}
      options.elementId = elementId

      surveysResource
        .getByElement(options)
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * Returns the default or overidden template
     * Overidden templates are reset immediately after
     */
    function getTemplate() {
      var template = nextTemplate || defaultTemplate
      nextTemplate = undefined
      return _.cloneDeep(template)
    }

    /**
     * @name publish
     * @desciption puplish a draft survey
     * @param {String} surveyId ID of survey to publish
     * @return {Promise} promise will resolve on publish success
     */
    function publish(surveyId) {
      var deferred = $q.defer()

      surveysResource
        .publish({ id: surveyId })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name pull
     * @desciption pull a published survey
     * @param {String} surveyId ID of survey to pull
     * @return {Promise} promise will resolve on pull success
     */
    function pull(surveyId) {
      var deferred = $q.defer()

      surveysResource
        .pull({ id: surveyId })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name saveImage
     * @description save image to survey
     * @param {String} surveyId survey ID
     * @param {String} image image data to be added
     * @return {Promise} promise will resolve on save success
     */
    function saveImage(surveyId, image) {
      var deferred = $q.defer()
      var data = {
        id: surveyId,
        imageData: image,
      }

      surveysResource
        .saveImage(data)
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name saveListImage
     * @description save image to survey
     * @param {String} surveyId survey ID
     * @param {String} image image data to be added
     * @return {Promise} promise will resolve on save success
     */
    function saveListImage(surveyId, image) {
      var deferred = $q.defer()
      var data = {
        id: surveyId,
        imageData: image,
      }

      surveysResource
        .saveListImage(data)
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name saveQuestionImage
     * @description save image to question
     * @param {String} surveyId survey ID
     * @param {String} questionId question ID
     * @param {String} image image data to be added
     * @return {Promise} promise will resolve on save success
     */
    function saveQuestionImage(surveyId, questionId, image) {
      var deferred = $q.defer()
      var data = {
        id: surveyId,
        questionId: questionId,
        imageData: image,
      }

      surveysResource
        .saveQuestionImage(data)
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * Used for survey duplication.
     * Overrides the template returned by 'getTemplate()', then restores the default.
     */
    function setNextTemplate(template) {
      // Strip illegal data from template
      template.id = undefined
      template.createdAt = undefined
      template.expired = undefined
      template.state = undefined
      _.each(template.questions, function(question) {
        if (question.type === 'placesnearme') {
          question.choices = []
        }
      })
      nextTemplate = template
    }

    /**
     * @name update
     * @description update survey data
     * @param {{}} surveyData survey object
     * @return {Promise} promise will resolve on update success
     */
    function update(surveyData) {
      var deferred = $q.defer()

      surveysResource
        .update(surveyData)
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name getQRString
     * @description get string to generate QR code for survey
     * @param  {Object} surveyId survey ID to create code from
     * @return {String}         value to encode into QR code
     */
    function getQRString(surveyId) {
      var domainUrl = configService.getUserPortalUrl()
      return domainUrl + '/action/!S' + surveyId
    }

    /**
     * @name getResponses
     * @description get survey responses
     * @param  {String} surveyId survey ID
     * @return {Promise}         promise to return survey responses
     */
    function getResponses(surveyId) {
      var deferred = $q.defer()

      surveysResource
        .getSurveyResponseReport({ surveyId: surveyId })
        .success(function(responses) {
          deferred.resolve(responses)
        })
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name exportAllResponsesByElement
     * @description export survey responses in CSV format for all surveys attached to an element
     * @param  {String} elementId element ID
     */
    function exportAllResponsesByElement(elementId) {
      var token = glAuthService.getToken()
      var baseUrl = configService.getApiUrl()

      var url = glUtils.addParams(baseUrl + '/survey-responses', {
        type: 'csv',
        elementId: elementId,
        api_token: token,
      })

      window.location.href = url

      glToast.show('Starting download...', {
        target: '.main-content',
      })
    }

    /**
     * @name exportAllResponsesByComparisonKey
     * @description export survey responses in CSV format for all surveys matching the comparison key
     * @param  {String} comparisonKey comparison key
     */
    function exportAllResponsesByComparisonKey(comparisonKey) {
      var token = glAuthService.getToken()
      var baseUrl = configService.getApiUrl()

      var url = glUtils.addParams(baseUrl + '/survey-responses', {
        sensitive: 1,
        type: 'csv',
        surveyComparisonKey: comparisonKey,
        api_token: token,
      })

      window.location.href = url

      glToast.show('Starting download...', {
        target: '.main-content',
      })
    }

    function exportSurveyTransactionsByDateRange(startAt, endAt) {
      var token = glAuthService.getToken()
      var baseUrl = configService.getApiUrl()

      var url = glUtils.addParams(baseUrl + '/survey-transactions', {
        type: 'csv',
        startAt: moment(startAt)
          .startOf('day')
          .toISOString(),
        endAt: moment(endAt)
          .endOf('day')
          .toISOString(),
        api_token: token,
      })

      window.location.href = url

      glToast.show('Starting download...', {
        target: '.main-content',
      })
    }

    /**
     * @name getNotes
     * @description get notes for a survey
     * @param  {String} surveyId survey ID
     * @return {Promise}
     */
    function getNotes(surveyId) {
      var deferred = $q.defer()

      surveysResource
        .getNotes({ id: surveyId })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name saveNotes
     * @description save notes for a survey
     * @param  {String} surveyId survey ID
     * @param   {String} text notes string to save
     * @return {Promise}
     */
    function saveNotes(surveyId, text) {
      text = text || ''
      var deferred = $q.defer()

      surveysResource
        .saveNote({ surveyId: surveyId, text: text })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name getDefinition
     * @description get the definition of the survey
     *
     * NOTE: the arrays below should contain all survey fields, and exclusions are to be commented
     * out. Please leave them in so it's obvious which keys are included/excluded, and add any that you
     * see missing.
     */
    function getDefinition(inputSurvey) {
      var clone = _.cloneDeep(inputSurvey)
      var survey = _.pick(clone, [
        // 'id',
        // 'startAt',
        // 'endAt',
        'followup',
        'imageUrl',
        'listImageUrl',
        'notify',
        'presentationType',
        'showLandingPage',
        'promote',
        'pullSurveyResponseLimit',
        'reportingDimensions',
        'rewards',
        'submitRoute',
        'title',
        'reference',
        'visibility',
      ])
      survey.questions = []
      _.each(clone.questions, function(question) {
        question = _.pick(question, [
          // 'id',
          'choices',
          'imageUrl',
          'isCompulsory',
          'isHidden',
          'isMultiSelect',
          'nonCompulsoryText',
          // 'numInitChoices',
          'order',
          'placesSearchTerms',
          'promptMessage',
          'showSearchField',
          'subscriberIdFilter',
          'title',
          'type',
        ])
        survey.questions.push(question)
      })
      return survey
    }

    /**
     * @name lockQuestion
     * @description locks a question so that it cannot be edited by a subscriber
     * @param {String} questionId
     * @return {Promise} promise will resolve on lock success
     */
    function lockQuestion(questionId) {
      var deferred = $q.defer()

      questionsResource
        .lock({ id: questionId })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name unlockQuestion
     * @description unlocks a question so that it can be edited by a subscriber
     * @param {String} questionId
     * @return {Promise} promise will resolve on unlock success
     */
    function unlockQuestion(questionId) {
      var deferred = $q.defer()

      questionsResource
        .unlock({ id: questionId })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }
  }
})()
