;(function() {
  'use strict'

  /**
   * @name components.textField
   * @description text filed directives to incorporate material design principles
   */

  glInputDirective.$inject = ["$compile", "$injector", "$timeout"];
  glInputErrorDirective.$inject = ["$animate"];
  angular
    .module('core.input', [])
    .directive('glInput', glInputDirective)
    .directive('glInputError', glInputErrorDirective)

  /**
   * @name glInput
   * @restrict E
   * @description Use the <gl-input> directive to allow material design styling on input element
   * set gl-multiline='true' for textarea
   *
   * @example
   *  gl-input(name='first' label='First Name' ng-minlength='4' ng-maxlength='10'
   *           ng-model='$parent.user.name' hint='your hint here' required)
   *      gl-input-error(when='required') Name is required.
   *      gl-input-error(when='minlength') Too short.
   *      gl-input-error(when='maxlength') Too long.
   *
   *
   * @ngInject
   */
  function glInputDirective($compile, $injector, $timeout) {
    controller.$inject = ["$scope"];
    var PRIORITY = 49
    var copyableAttributes = {}

    return {
      restrict: 'E',
      priority: PRIORITY,
      compile: compile,
      controller: controller,
      transclude: true,
      scope: {
        fid: '@?glFid',
        label: '@?',
        model: '=',
        hint: '@?',
      },
      template: function(tElem, tAttrs) {
        if (angular.isDefined(tAttrs.glMultiline)) {
          return (
            '<label for="{{fid}}">{{label}}</label>' +
            '<textarea id="{{fid}}" ng-model="model" type="{{inputType}}"></textarea>' +
            '<span class="gl-input-bar" ng-class="{ \'gl-input-error\': hasError }"></span>' +
            '<span class="gl-input-hint" ng-if="hint" ng-show="!hasError">{{hint}}</span>' +
            '<span ng-transclude></span>'
          )
        } else {
          return (
            '<label for="{{fid}}">{{label}}</label>' +
            '<input id="{{fid}}" ng-model="model" type="{{inputType}}"></input>' +
            '<span class="gl-input-bar" ng-class="{ \'gl-input-error\': hasError }"></span>' +
            '<span class="gl-input-hint" ng-if="hint" ng-show="!hasError">{{hint}}</span>' +
            '<span ng-transclude></span>'
          )
        }
      },
    }

    function controller($scope) {
      var messages = ($scope.messages = [])
      this.registerError = function(type, errorScope) {
        messages.push({
          type: type,
          scope: errorScope,
        })
      }

      this.renderErrors = function(type) {
        var found = false
        angular.forEach(messages, function(message) {
          if (!found && message.type === type) {
            found = true
            message.scope.show()
          } else {
            message.scope.hide()
          }
        })
      }
    }

    function compile(element, attr) {
      var input = angular.isDefined(attr.glMultiline)
        ? angular.element(element.find('textarea'))
        : angular.element(element.find('input'))

      // associate label and input - generate id if one is not provided
      if (angular.isUndefined(attr.glFid)) {
        attr.glFid = _.uniqueId()
      }

      // Copy relevant attributes down to the input element: attrs that either aren't a directive,
      // or are a directive that has already run (a directive with higher priority)
      // For example, ng-if would NOT be copied down to the input because it has a higher priority
      angular.forEach(element[0].attributes, function(attr) {
        // Cache the test of whether this attribute should be copied, so it only has to
        // be run once.
        if (!copyableAttributes.hasOwnProperty(attr.name)) {
          copyableAttributes[attr.name] = shouldCopy(attr.name)
        }

        if (copyableAttributes[attr.name]) {
          input[0].setAttribute(attr.name, attr.value)
        }
      })

      function shouldCopy(attrName) {
        // excluded directives, like ng-show which has a priority of 0 but we do not want to copy to input
        var excludeDirs = ['ng-show', 'label', 'gl-fid', 'model']
        var injectName = attr.$normalize(attrName) + 'Directive'
        var directive = $injector.has(injectName)
          ? $injector.get(injectName)
          : false

        if (directive) {
          // if it is an excluded directive do not copy
          if (_.indexOf(excludeDirs, attrName) !== -1) {
            return false
          }
          // only copy if directives priority is less than PRIORITY
          return directive[0].priority < PRIORITY
        } else {
          // otherwise it is a standard attribute (type, label, etc) so we copy by default
          if (_.indexOf(excludeDirs, attrName) !== -1) {
            return false
          }

          return true
        }
      }

      return postLink
    }

    function postLink(scope, element, attr, ctrl) {
      var input = angular.element(
        element.find(angular.isDefined(attr.glMultiline) ? 'textarea' : 'input')
      )
      var ngModelCtrl = input.data('$ngModelController')

      // default to text
      scope.inputType = attr.type || element.parent().attr('type') || 'text'
      scope.fid = attr.glFid
      scope.showHint = scope.hint ? true : false

      element.removeAttr('ng-model')

      // when the input focuses, add the focused class to the input group
      input.on('focus', onFocus)
      input.on('blur', onBlur)
      input.on('blur', onModelChange)

      scope.$watch(
        function() {
          return ngModelCtrl.$viewValue
        },
        function isEmptyWatch(value) {
          element.toggleClass('gl-input-has-value', isNotEmpty(value))
        }
      )

      if (scope.messages.length > 0) {
        scope.$watch(
          function() {
            return ngModelCtrl.$error
          },
          onModelChange,
          true
        )
      }

      function onModelChange() {
        if (ngModelCtrl.$touched) {
          $timeout(function() {
            ctrl.renderErrors(Object.keys(ngModelCtrl.$error)[0])
            scope.hasError = !_.isEmpty(ngModelCtrl.$error)
          })
        }
      }

      function onFocus() {
        element.addClass('gl-input-focused')
      }

      function onBlur() {
        element.removeClass('gl-input-focused')
      }

      function isNotEmpty(value) {
        value = angular.isUndefined(value) ? element.val() : value
        return (
          angular.isDefined(value) &&
          value !== null &&
          value.toString().trim() !== ''
        )
      }
    }
  }

  /* @ngInclude */
  function glInputErrorDirective($animate) {
    return {
      restrict: 'EA',
      priority: 50,
      transclude: 'element',
      scope: {
        when: '@',
        message: '@',
      },
      require: '^glInput',
      link: function(scope, element, attrs, inputCtrl, transcludeFn) {
        var el

        inputCtrl.registerError(scope.when, scope)

        scope.show = function() {
          if (!el) {
            transcludeFn(scope, function(clone) {
              $animate.enter(clone, null, element)
              el = clone
            })
          }
        }

        scope.hide = function() {
          if (el) {
            $animate.leave(el)
            el = null
          }
        }
      },
    }
  }
})()
