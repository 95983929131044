;(function() {
  'use strict'

  Controller.$inject = ["subscriberService", "currencyService"];
  angular.module('core.addCredit').component('addCredit', {
    controller: Controller,
    templateUrl: 'add-credit.html',
    bindings: {
      subscriber: '<',
      onCreditAdded: '&',
    },
  })

  /* @ngInject */
  function Controller(subscriberService, currencyService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.canAdd = canAdd
    ctrl.add = add

    function onInit() {
      ctrl.credit = ctrl.subscriber.billing.credit.clone()
      ctrl.currency = currencyService.getByCode(
        ctrl.subscriber.billing.currency
      )
      ctrl.amount = 0
      ctrl.complementaryAmount = 0
    }

    function canAdd() {
      if (!_.isNumber(ctrl.amount) || !_.isNumber(ctrl.complementaryAmount)) {
        return false
      }
      if (ctrl.amount <= 0 || ctrl.complementaryAmount < 0) {
        return false
      }
      if (ctrl.complementaryAmount > ctrl.amount) {
        return false
      }
      return true
    }

    function add() {
      if (ctrl.loading || !ctrl.canAdd()) return
      ctrl.error = null
      ctrl.loading = true
      subscriberService
        .addCredit(ctrl.subscriber.id, ctrl.amount, ctrl.complementaryAmount)
        .then(function() {
          ctrl.credit.amount += ctrl.amount
          ctrl.onCreditAdded({ $credit: ctrl.amount })
        })
        .catch(function(err) {
          ctrl.error = err.error
        })
        .finally(function() {
          ctrl.loading = false
        })
    }
  }
})()
