;(function() {
  'use strict'

  interactionService.$inject = ["$q", "interactionsResource"];
  angular
    .module('core.services')
    .factory('interactionService', interactionService)

  /* @ngInject */
  function interactionService($q, interactionsResource) {
    return {
      getByElement: getByElement,
      getBySubscriber: getBySubscriber,
    }

    /**
     * PRIVATE - transform interaction properties - add element name and format date
     * @param { Array } interactions array of interaction resources
     * @return { array } array of interaction resources with elementName appended
     */
    function transformInteractions(interactions) {
      var feedbackTypes = [
        'feedback',
        'idea',
        'complaint',
        'praise',
        'suggestion',
        'request',
        'question',
      ]
      var moodValues = ['1', '2', '3', '4', '5', '6']

      var isFeedbackType = _.memoize(function(type) {
        return feedbackTypes.indexOf(type) !== -1
      })

      _.forEach(interactions, function(interaction) {
        // set parentType
        if (isFeedbackType(interaction.type)) {
          interaction.parentType = 'feedback'
        } else {
          interaction.parentType = interaction.type
        }

        // check valid mood
        if (moodValues.indexOf(interaction.mood) === -1) {
          interaction.mood = null
        }

        // ensure updatedAt exists
        interaction.updatedAt = interaction.updatedAt
          ? interaction.updatedAt
          : interaction.createdAt

        // format incident date
        if (interaction.incidentDate) {
          interaction.incidentDate = moment(
            interaction.incidentDate.date
          ).format('D MMMM')
        }
      })

      return interactions
    }

    /**
     * @name getByElement
     * @description get interactions by element ID
     * @param {Sting} elementId element ID
     * @return {Promise} promise to return an array of interaction resources
     */
    function getByElement(elementId) {
      var deferred = $q.defer()

      interactionsResource
        .getByElement({ id: elementId })
        .success(function(resources) {
          deferred.resolve(transformInteractions(resources))
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name getBySubscriber
     * @description get interactions by subscriber ID
     * @param {Sting} subscriberId subscriber ID
     * @return {Promise} promise to return an array of interaction resources
     */
    function getBySubscriber(subscriberId) {
      var deferred = $q.defer()

      interactionsResource
        .getBySubscriber({ id: subscriberId })
        .success(function(resources) {
          deferred.resolve(transformInteractions(resources))
        })
        .error(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }
  }
})()
