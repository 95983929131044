;(function() {
  'use strict'

  UsersController.$inject = ["$scope", "$stateParams", "breadcrumbsService", "usersService", "glToast", "listFilterService", "$state"];
  angular.module('app.users').controller('UsersController', UsersController)

  /* @ngInject */
  function UsersController(
    $scope,
    $stateParams,
    breadcrumbsService,
    usersService,
    glToast,
    listFilterService,
    $state
  ) {
    $scope.loading = false

    $scope.data = {
      subscriberId: $stateParams.sid,
      users: [],
    }

    // filters
    var uriFilters = listFilterService.initFilter($stateParams)
    $scope.filters = _.assign(
      {
        role: 'all',
        top: 50,
        page: 1,
        pageExhausted: false,
        orderby: 'createdAt',
        order: 'desc',
        keyword: '',
      },
      uriFilters
    )

    $scope.getUsers = getUsers
    $scope.newUser = newUser
    $scope.editUser = editUser

    activate()

    /**
     * initialise
     */
    function activate() {
      getUsers()
      var parentState = $stateParams.sid ? 'app.subscriber' : 'home'
      breadcrumbsService.addBreadcrumb('Users', parentState)
    }

    /**
     * Button to create new user
     */
    function newUser() {
      if ($stateParams.sid) {
        $state.go('app.user', { id: 'new', sid: $stateParams.sid })
      } else {
        $state.go('app.user', { id: 'new' })
      }
    }

    /**
     * Form link to edit existing
     */
    function editUser(userId) {
      if ($stateParams.sid) {
        $state.go('app.user', { id: userId, sid: $stateParams.sid })
      } else {
        $state.go('app.user', { id: userId })
      }
    }

    /**
     * Build search object from filters
     * @param  {Object} srcObj filter object to build upon
     * @return {Object}
     */
    function organiseSearchObj(srcObj) {
      var sendingObj = {}
      var filters = []

      if ($stateParams.sid) {
        sendingObj.subscriberId = $stateParams.sid
      }

      // top, page, orderby
      sendingObj.top = srcObj.top
      sendingObj.skip = srcObj.top * (srcObj.page - 1)
      sendingObj.orderby = srcObj.orderby + ' ' + srcObj.order

      // Role filter
      if (srcObj.role !== 'all') {
        filters.push('userRole.roles eq ' + srcObj.role)
      }

      // Keyword filter
      var keyword = srcObj.keyword
      if (keyword) {
        filters.push(
          'email regex /' + keyword + "/i or id eq '" + keyword + "'"
        )
      }

      // Join filters if they exist
      if (filters.length > 0) {
        sendingObj.filter = filters.join(' and ')
      }

      return sendingObj
    }

    /**
     * Get users from server
     * @param  {Boolean} append if true then append to existing users data set
     */
    function getUsers(append) {
      $scope.loading = true
      if (!append && $scope.data.users) {
        $scope.data.users = []
        $scope.filters.page = 1
        $scope.filters.pageExhausted = false
        listFilterService.writeFilter($scope.filters)
      } else if (append) {
        $scope.filters.page++
      }

      var action = $stateParams.sid ? 'getBySubscriber' : 'getAll'

      usersService[action](organiseSearchObj($scope.filters)).then(
        getSuccess,
        getError
      )

      function getSuccess(data) {
        $scope.loading = false
        $scope.data.users.push.apply($scope.data.users, data)
        if (data.length < $scope.filters.top) {
          $scope.filters.pageExhausted = true
        }
      }

      function getError() {
        $scope.loading = false
        glToast.action('Error retrieving users.', 'Retry', getUsers, {
          target: '.main-content',
          timeout: 5000,
        })
      }
    }
  }
})()
