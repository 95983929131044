;(function() {
  'use strict'

  CouponsController.$inject = ["$scope", "$stateParams", "breadcrumbsService", "glDialog", "couponService", "glToast"];
  angular
    .module('app.coupons')
    .controller('CouponsController', CouponsController)

  /* @ngInject */
  function CouponsController(
    $scope,
    $stateParams,
    breadcrumbsService,
    glDialog,
    couponService,
    glToast
  ) {
    $scope.loading = false

    $scope.data = {
      subscriberId: $stateParams.sid,
      coupons: [],
    }

    activate()

    /**
     * initialise
     */
    function activate() {
      getCoupons()
      var parentState = $stateParams.sid ? 'app.subscriber' : 'home'
      breadcrumbsService.addBreadcrumb('Coupons', parentState)
    }

    /**
     * Get coupons from server
     */
    function getCoupons() {
      $scope.loading = true

      if (!$stateParams.sid) {
        $scope.loading = false
        return glDialog.alert(
          '',
          'Element or Subscriber ID required, GET all not supported yet.',
          {
            parent: '.main-content',
          }
        )
      }

      couponService.getBySubscriber($stateParams.sid).then(getSuccess, getError)

      function getSuccess(data) {
        $scope.loading = false
        $scope.data.coupons.push.apply($scope.data.coupons, data)
      }

      function getError() {
        $scope.loading = false
        glToast.action('Error retrieving coupons.', 'Retry', getCoupons, {
          target: '.main-content',
          timeout: 5000,
        })
      }
    }
  }
})()
