;(function() {
  'use strict'

  imagesService.$inject = ["imagesResource", "$q"];
  angular.module('core.services').factory('imagesService', imagesService)

  /* @ngInject */
  function imagesService(imagesResource, $q) {
    return {
      create: create,
    }

    function create(data) {
      var deferred = $q.defer()

      imagesResource
        .create(data)
        .success(function(response) {
          // double check here, not in controllers...

          if (response.imageUrl) {
            deferred.resolve(response)
          } else {
            deferred.reject()
          }
        })
        .error(deferred.reject)

      return deferred.promise
    }
  }
})()
