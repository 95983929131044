;(function() {
  'use strict'

  Controller.$inject = ["glToast", "contentGroupsService"];
  angular.module('core.contentGroupsList').component('contentGroupsList', {
    controller: Controller,
    templateUrl: 'content-groups-list.html',
    bindings: {
      hiddenIds: '<',
      onLoaded: '&',
      onSelect: '&',
    },
  })

  /* @ngInject */
  function Controller(glToast, contentGroupsService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.fetch = fetch

    function onInit() {
      fetch()
    }

    function fetch() {
      ctrl.isLoading = true
      return contentGroupsService
        .search({ query: ctrl.query })
        .then(function(contentGroups) {
          _.remove(contentGroups, function(contentGroup) {
            return _.includes(ctrl.hiddenIds, contentGroup.id)
          })
          ctrl.contentGroups = contentGroups
          ctrl.isLoading = false
        })
        .catch(function() {
          glToast.show('Error fetching Content Groups')
        })
    }
  }
})()
