;(function() {
  'use strict'

  linkService.$inject = ["$q", "linksResource"];
  angular.module('core.services').factory('linkService', linkService)

  /* @ngInject */
  function linkService($q, linksResource) {
    return {
      get: get,
      create: create,
      update: update,
    }

    /**
     * @name get
     * @param {Object} options options object
     * @description get links
     * @returns {promise}
     */
    function get(options) {
      return $q(function(resolve, reject) {
        linksResource
          .get(options)
          .success(resolve)
          .error(reject)
      })
    }

    /**
     * @name create
     * @desc Create a new short link to point to a target URL
     * @param {string} longUrl - the full redirect url
     * @param {string} fragment - an optional fragment (eg glo.link/<fragment>)
     * @returns {promise}
     */
    function create(longUrl, fragment) {
      return $q(function(resolve, reject) {
        linksResource
          .create({
            longUrl: longUrl,
            fragmentRequest: fragment,
          })
          .success(resolve)
          .error(reject)
      })
    }

    /**
     * @name update
     * @desc Update the longUrl of an existing shortUrl
     * @param {string} shortUrl - the shortUrl to update (eg glo.link/foo)
     * @param {string} longUrl - the new longUrl to redirect to
     * @returns {promise}
     */
    function update(shortUrl, longUrl) {
      return $q(function(resolve, reject) {
        linksResource
          .modifyByShortUrl({
            shortUrl: encodeURIComponent(shortUrl),
            longUrl: longUrl,
          })
          .success(resolve)
          .error(reject)
      })
    }
  }
})()
